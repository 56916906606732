import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import filters from "./filters";
import http from "./http/axios";
import Notifications from "vue-notification";
import VueCroppie from "vue-croppie";
import "croppie/croppie.css";
import "./assets/style.css";

Object.keys(filters).forEach((k) => Vue.filter(k, filters[k]));
Vue.use(Notifications);
Vue.use(VueCroppie);
Vue.config.productionTip = false;
Vue.prototype.baseURL = process.env.VUE_APP_ROOT_API;
Vue.prototype.ssrURL = process.env.VUE_APP_SSR;
Vue.prototype.$http = http;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
