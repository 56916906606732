import http from '@/http/axios';
import { judgeError, queryFilter } from './Common';
import { error, success } from '@/utils/notification';

export async function getDealerFlowSampleList(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/DealerFlowSample/List${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  }
  return res;
}

export async function getDealerFlowSample(dealerId, id) {
  const res = await http.get(`/api/DealerFlowSample/${dealerId}/${id}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  }
  return res;
}

export async function createDealerFlowSample(data) {
  const res = await http.post(`/api/DealerFlowSample/${data.dealerId}`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  } else {
    success();
  }
  return res;
}

export async function updateDealerFlowSample(data) {
  const res = await http.put(`/api/DealerFlowSample/${data.dealerId}/${data.id}`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  } else {
    success();
  }
  return res;
}

export async function disableDealerFlowSample(dealerId, id) {
  const res = await http.post(`/api/DealerFlowSample/${dealerId}/${id}/Disable`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  } else {
    success();
  }
  return res;
}

export async function enableDealerFlowSample(dealerId, id) {
  const res = await http.post(`/api/DealerFlowSample/${dealerId}/${id}/Enable`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  } else {
    success();
  }
  return res;
}

export async function createDetailDealerFlowSample(dealerId, id, data) {
  const res = await http.post(`/api/DealerFlowSample/${dealerId}/${id}/Details`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  } else {
    success();
  }
  return res;
}

export async function getDetailDealerFlowSample(dealerId, id) {
  const res = await http.get(`/api/DealerFlowSample/${dealerId}/${id}/Details`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  }
  return res;
}

export async function dealerFlowSampleImport(data) {
  const res = await http.post(`/api/DealerFlowSample/${data.dealerId}/Import/${data.id}?newName=${data.name}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  } else {
    success();
  }
  return res;
}
