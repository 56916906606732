<template>
  <v-app>
    <div id="EObituaryTemplate1" class="">
      <div
        style="
          position: fixed;
          width: 100%;
          height: 100%;
          left: 0;
          background-color: white;
          z-index: 50;
        "
        v-if="loading"
      >
        <Loading />
      </div>
      <v-card class="d-flex justify-center" v-else-if="data !== undefined" style="padding: 10px 0">
        <div
          class="bgImage"
          style="position: relative"
          :style="`width:${width}px;min-height:${height}px`"
        >
          <div class="main">
            <v-btn
              fab
              absolute
              style="right: 5px; top: 5px; width: 40px; height: 40px"
              color="primary"
              @click="switchMusic()"
              id="test"
            >
              <v-icon v-if="isMusic">mdi-music</v-icon>
              <v-icon v-else>mdi-music-off</v-icon>
            </v-btn>
            <div style="position: absolute; width: 100%; height: 100%; overflow-x: hidden">
              <div class="d-flex align-end" style="height: 7%; position: relative">
                <div
                  style="width: 90%; height: 20%; background-color: #fff; border-radius: 0"
                  class="slideAnimation"
                ></div>
                <img
                  src="../../assets/template1/bamboo.png"
                  style="
                    position: absolute;
                    right: -3%;
                    width: 40%;
                    top: 0%;
                    transform: rotate(90deg);
                    z-index: 1;
                  "
                />
              </div>
              <div
                style="
                  margin: 4% 0;
                  width: 100%;
                  height: 22%;
                  display: flex;
                  justify-content: center;
                "
              >
                <img :src="`${baseUrl}${data.deceasedPicUrl}`" style="height: 100%" />
              </div>
              <div
                style="font-size: 2em; width: 100%; letter-spacing: 5px"
                class="text-center white--text fontAnimation"
              >
                <div style="padding-bottom: 1%">{{ data.deceasedName }}</div>
                <div style="padding-top: 1%">追思告別儀式</div>
              </div>
              <div
                class="d-flex justify-end align-center"
                style="height: 18%; position: relative; margin-top: -13%"
              >
                <img
                  src="../../assets/template1/pineAndCypress.png"
                  style="
                    position: absolute;
                    width: 40%;
                    object-fit: cover;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 1;
                  "
                />
                <img
                  src="../../assets/template1/plumBossom.png"
                  style="position: absolute; width: 40%; right: 2%; bottom: 0%; z-index: 1"
                />
                <div
                  style="width: 85%; height: 9%; background-color: #fff; border-radius: 0"
                  class="slideAnimationLeft"
                ></div>
              </div>
              <div
                style="font-size: 2em; width: 100%; height: 7%"
                class="text-center white--text fontAnimation"
              >
                <span style="letter-spacing: 5px">日期</span>
                {{ data.ceremonyTime | dateTimeFilter }}
              </div>
              <div
                class="text-center buttonAnimation d-flex flex-column"
                style="width: 100%; height: 35%"
              >
                <div
                  style="margin-bottom: 5%"
                  class="d-flex flex-column align-center"
                  v-if="data.isShowContent"
                >
                  <v-btn
                    depressed
                    color="white"
                    style="width: 60%; letter-spacing: 3px; font-size: 2em"
                    @click="openContext"
                  >
                    邀請內文
                  </v-btn>
                  <div
                    v-if="isContext"
                    class="text-center white pa-2"
                    style="width: 60%; font-size: 2em"
                  >
                    {{ data.content }}
                    <div class="text-left mt-3">
                      <div>日期：{{ splitDate.date }}</div>
                      <div>時間：{{ splitDate.time }}</div>
                      <div>地點：{{ data.ceremonyPlace }}</div>
                      <div>電話：{{ data.contactTel }}</div>
                    </div>
                  </div>
                </div>
                <div style="margin-bottom: 5%" v-if="data.isShowReply">
                  <v-btn
                    depressed
                    color="white"
                    style="width: 60%; letter-spacing: 3px; font-size: 2em"
                    @click="goGoogleForm()"
                  >
                    參加回函
                  </v-btn>
                </div>
                <div :class="isMap ? 'mb-2' : ''" v-show="data.isShowMap" style="margin-bottom: 5%">
                  <v-btn
                    depressed
                    color="white"
                    style="width: 60%; letter-spacing: 3px; font-size: 2em"
                    @click="openMap()"
                  >
                    儀式地圖
                  </v-btn>
                  <iframe
                    v-if="isMap"
                    width="60%"
                    height="300"
                    class="pa-2 white"
                    frameborder="0"
                    :src="`https://www.google.com/maps?q=${data.ceremonyAddress}&hl=en&output=embed`"
                  ></iframe>
                </div>
                <div
                  class="d-flex flex-column align-center"
                  v-if="data.isShowSponsorFlower"
                  style="margin-bottom: 5%"
                >
                  <v-btn
                    depressed
                    color="white"
                    style="width: 60%; letter-spacing: 3px; font-size: 2em"
                    @click="openSponsorFlower"
                  >
                    致贈花卉
                  </v-btn>
                  <div
                    v-if="isOpenSponsorFlower"
                    class="text-center white pa-2"
                    style="width: 60%; font-size: 2em"
                  >
                    {{ data.sponsorFlowerInfo }}
                  </div>
                </div>
                <div
                  class="d-flex flex-column align-center"
                  v-if="data.isShowSponsorFlower"
                  style="margin-bottom: 5%; font-size: 2em"
                >
                  業者聯絡方式: {{ data.dealerContactInfo }}
                </div>
              </div>
            </div>
          </div>
          <img
            src="../../assets/template1/door.png"
            style="position: absolute; left: 0; width: 50%; bottom: 0; height: 40%"
            class="leftToRightAnimation"
            v-if="!hidden"
          />
          <img
            src="../../assets/template1/door.png"
            style="position: absolute; right: 0; width: 50%; bottom: 0; height: 40%"
            class="rightToLeftAnimation"
            v-if="!hidden"
          />
        </div>
        <audio id="audio">
          <source :src="baseUrl + data.musicPath" type="audio/mpeg" />
        </audio>
      </v-card>
      <div v-else style="font-size: 50px" class="d-flex justify-center">
        <div>此訃聞不存在</div>
      </div>
    </div>
  </v-app>
</template>

<script>
import Loading from "@/components/Loading.vue";
export default {
  name: "Template1",
  props: ["data", "baseUrl"],
  components: {
    Loading,
  },
  data() {
    return {
      height: 0,
      width: 0,
      hidden: false,
      loading: false,
      isMap: false,
      isContext: false,
      splitDate: {},
      isOpenSponsorFlower: false,
      isMusic: false,
      hasFirst: true,
    };
  },
  methods: {
    switchMusic() {
      if (this.hasFirst) {
        this.autoPlayFunction();
      }
      this.isMusic = !this.isMusic;
      const audio = document.getElementById("audio");
      audio.muted = !this.isMusic;
    },
    autoPlayFunction() {
      const audio = document.getElementById("audio");
      audio.play();
      this.hasFirst = false;
    },
    splitDateFunction() {
      let revise = this.data.ceremonyTime.toString().split("");
      revise.splice(4, 0, "/");
      revise.splice(7, 0, "/");
      revise.splice(12, 0, ":");
      let date = revise.splice(0, 10);
      let time = revise.splice(0, 8);
      this.splitDate.date = date.toString().replaceAll(",", "");
      this.splitDate.time = time.toString().replaceAll(",", "");
    },
    goGoogleForm() {
      window.open(this.data.replyUrl, "_blank");
    },
    openContext() {
      this.isContext = !this.isContext;
    },
    openSponsorFlower() {
      if (this.data.sponsorFlowerType === 0) {
        this.isOpenSponsorFlower = !this.isOpenSponsorFlower;
      } else {
        window.open(this.data.sponsorFlowerUrl, "_blank");
      }
    },
    openMap() {
      this.isMap = !this.isMap;
    },
    getHeight() {
      this.height = window.innerHeight - 20;
      this.width = ((this.height / 16) * 9).toFixed(0);
    },
    setDoorHidden() {
      setTimeout(() => {
        this.hidden = true;
      }, 6000);
    },
  },
  created() {
    window.addEventListener(
      "resize",
      (event) => {
        this.getHeight();
      },
      true
    );
    this.getHeight();
    this.setDoorHidden();
  },
};
</script>
<style scoped>
#EObituaryTemplate1 {
  font-size: 10px;
}
.bgImage {
  border-radius: 0 !important;
  background-repeat: repeat-y;
  background-size: contain;
  background-image: url("../../assets/template1/background.png");
}

.bgImage2 {
  border-radius: 0 !important;
  background-size: cover;
}
.bgColor2 {
  background-image: linear-gradient(to bottom, rgb(107, 103, 101), rgb(172, 131, 83));
}
.bg2 {
  background-image: url("../../assets/template2/BG.jpg");
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes buttonFade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes topToBottom {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes bottomToTop {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-100%);
  }
}

@keyframes lengthen {
  0% {
    width: 0%;
  }

  100% {
    width: 90%;
  }
}

@keyframes lengthLeft {
  0% {
    width: 0%;
  }

  100% {
    width: 80%;
  }
}

@keyframes fontFadein {
  40% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rightToLeft {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes leftToRight {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.main {
  animation: fadein 3s ease;
}

.slideAnimation {
  animation: lengthen 3s ease;
}

.slideAnimationLeft {
  animation: lengthLeft 3s ease;
}

.fontAnimation {
  animation: fontFadein 6s ease;
}

.rightToLeftAnimation {
  animation: rightToLeft 3s ease;
  animation-delay: 3s;
}

.leftToRightAnimation {
  animation: leftToRight 3s ease;
  animation-delay: 3s;
}

.buttonAnimation {
  animation: buttonFade 7s ease;
}

.topToBottomAnimation {
  animation: topToBottom 2s ease;
}

.bottomToTopAnimation {
  animation: bottomToTop 2s ease;
}
</style>
