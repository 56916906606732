<template>
  <div id="DateTimePicker">
    <v-menu :close-on-content-click="false" v-model="isMenu" max-width="290px" min-width="290px">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :rules="rules"
          v-bind="attrs"
          v-on="on"
          :label="label"
          hide-details
          readonly
          :value="value"
        ></v-text-field>
      </template>
      <v-card>
        <v-row no-gutters>
          <v-col>
            <v-tabs v-model="selectdTab" fixed-tabs>
              <v-tab> 日期 </v-tab>
              <v-tab> 時間 </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
        <v-date-picker
          v-if="selectdTab === 0"
          locale="zh-cn"
          v-model="date"
          @change="saveDate"
        ></v-date-picker>
        <v-time-picker
          v-if="selectdTab === 1"
          format="24hr"
          :use-seconds="hasSecond"
          v-model="time"
          locale="zh-cn"
        ></v-time-picker>
        <v-row no-gutters>
          <v-col>
            <v-btn style="width: 100%; border-radius: 0" class="red white--text" @click="cancel"
              >取消</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              style="width: 100%; border-radius: 0"
              class="primary white--text"
              :disabled="date === null || time === null"
              @click="confirm"
              >確定</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "DateTimePicker",
  props: ["label", "value", "keyValue", "hasSecond"],
  data() {
    return {
      rules: [(value) => !!value || "必填"],
      isMenu: false,
      selectdTab: 0,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      time: null,
    };
  },
  methods: {
    saveDate() {
      this.selectdTab = 1;
    },
    cancel() {
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.time = null;
      this.selectdTab = 0;
      this.isMenu = false;
    },
    confirm() {
      this.$emit("confirmDateTime", `${this.date} ${this.time}`, this.keyValue);
      this.selectdTab = 0;
      this.isMenu = false;
    },
  },
};
</script>
