<template>
  <div id="CheckGroup">
    <v-card class="pa-2 text-center">
      <v-card-title> 編輯內容 </v-card-title>
      <v-card-text>
        <div class="justify-center">
          <div class="px-2 d-flex align-end">
            <v-text-field
              append-icon="mdi-magnify"
              label="搜尋"
              class="mr-2"
              hide-details
              dense
              v-model="search"
              @click:append="searchFeature"
              @keypress.enter="searchFeature"
            ></v-text-field>
            <div v-if="isOnContractPage" class="d-flex align-center">
              <v-select
                :items="importDatas"
                item-disabled="disabled"
                item-text="name"
                item-value="id"
                class="mr-1 pa-0"
                hide-details
                :label="importDatas.length > 0 ? '選擇範本' : '目前沒有範本可引入'"
                v-model="selectedId"
                no-data-text="目前沒有範本可引入"
              >
              </v-select>
              <v-btn
                color="primary"
                class="ml-2"
                @click="clickImportBtn()"
                :disabled="selectedId < 0"
              >
                引入細項
              </v-btn>
            </div>
          </div>
          <div
            class="px-2 pt-2 text-left"
            style="height: 60vh; overflow-y: auto; overflow-x: hidden"
            v-if="!$store.state.loading"
          >
            <v-row style="border-bottom: 1px solid #d2d2d2" class="mb-0 px-2 py-2" no-gutters>
              <v-col v-for="(item, index) in thead" :key="index">
                {{ item }}
              </v-col>
            </v-row>

            <div class="text-right">已選取了 {{ selectedList.length }} 筆</div>

            <v-row
              v-for="(item, index) in selectedListCopy"
              :key="'selected' + index"
              no-gutters
              class="py-1"
            >
              <v-col class="d-flex align-center">
                <div class="d-flex align-center" v-if="isOnContractPage">
                  <div
                    @click="cancelCheck(item, index)"
                    style="
                      border: 1px solid #999;
                      width: 18px;
                      height: 18px;
                      border-radius: 3px;
                      cursor: pointer;
                      font-size: 18px;
                      text-align: center;
                      line-height: 18px;
                    "
                    class="mr-2 primary white--text"
                  >
                    ✓
                  </div>
                  <label>{{ item.name }}</label>
                  <!-- <v-checkbox
                    @change="cancelCheck(item)"
                    class="ma-0 py-1"
                    color="indigo"
                    :label="item.name"
                    :value="item"
                    v-model="selectedListCopy"
                    hide-details
                  ></v-checkbox> -->
                </div>
                <div v-else class="d-flex align-center">
                  <div
                    @click="cancelCheck(item, index)"
                    style="
                      border: 1px solid #999;
                      width: 18px;
                      height: 18px;
                      border-radius: 3px;
                      cursor: pointer;
                      font-size: 18px;
                      text-align: center;
                      line-height: 18px;
                    "
                    class="mr-2 primary white--text"
                  >
                    ✓
                  </div>
                  <!-- <v-checkbox
                    @change="cancelCheck(item)"
                    class="ma-0 py-1"
                    color="indigo"
                    hide-details
                    :value="item"
                    v-model="selectedListCopy"
                  ></v-checkbox> -->
                  <input
                    type="text"
                    class="px-2 py-1"
                    style="border: 1px solid #d2d2d2; border-radius: 5px; height: 30px"
                    placeholder="名稱"
                    v-model="item.name"
                  />
                </div>
              </v-col>
              <v-col v-if="haveQuanity || haveDealerContractItem" class="d-flex align-center">
                {{ item.unit }}
              </v-col>
              <v-col v-if="haveQuanity || haveDealerContractItem">
                <input
                  type="number"
                  class="px-2 py-1"
                  style="border: 1px solid #d2d2d2; border-radius: 5px; height: 30px"
                  placeholder="數量"
                  v-model="item.quantity"
                  @change="changeSelectedList(item, index)"
                />
              </v-col>
              <v-col v-if="haveFlow || haveDealerContractItem" class="d-flex">
                <input
                  v-if="haveDealerContractItem"
                  type="number"
                  class="ml-1 px-2 py-1"
                  style="border: 1px solid #d2d2d2; border-radius: 5px; height: 30px"
                  placeholder="單位金額"
                  v-model="item.unitAmount"
                  @change="changeSelectedList(item, index)"
                />
                <v-btn
                  v-if="haveFlow"
                  small
                  color="primary"
                  class="mr-1"
                  @click="openDialog('participants', index, item, 'selectedList')"
                >
                  <v-icon left> mdi-account-arrow-right </v-icon>
                  編輯參與者
                </v-btn>
                <v-btn
                  v-if="haveFlow"
                  class="mr-1"
                  small
                  color="warning"
                  @click="openDialog('prepared', index, item, 'selectedList')"
                >
                  <v-icon left> mdi-clipboard-list </v-icon>
                  編輯準備事項
                </v-btn>
                <v-btn
                  v-if="haveFlow && isOnContractPage"
                  small
                  color="indigo"
                  class="mr-1 white--text"
                  @click="openDialog('place', index, item, 'selectedList')"
                >
                  <v-icon left> mdi-map-marker </v-icon>
                  編輯地點
                </v-btn>
                <v-btn
                  v-if="haveFlow"
                  small
                  color="success"
                  class="mr-1"
                  @click="openDialog('memo', index, item, 'selectedList')"
                >
                  <v-icon left> mdi-note-edit </v-icon>
                  編輯備註
                </v-btn>
                <v-btn
                  v-if="haveDate && isOnContractPage"
                  small
                  class="white--text mr-1"
                  color="red"
                  @click="openDialog('date', index, item, 'selectedList')"
                >
                  <v-icon left> mdi-clock </v-icon>
                  編輯時間
                </v-btn>
                <v-btn
                  v-if="haveNotify && isOnContractPage"
                  small
                  class="white--text"
                  color="info"
                  @click="openDialog('notify', index, item, 'selectedList')"
                >
                  <v-icon left> mdi-bell </v-icon>
                  通知時間
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                v-if="
                  item.participants ||
                  item.prepared ||
                  item.memoSample ||
                  item.dateTime ||
                  item.notifyTime
                "
              >
                <div v-if="item.participants" class="text-left">
                  參與者：
                  <v-chip
                    v-for="(value, valueIndex) in item.participants"
                    :key="valueIndex"
                    class="ma-2 white--text"
                    color="indigo"
                    small
                  >
                    {{ value }}
                  </v-chip>
                </div>
                <div v-if="item.prepared" class="text-left">
                  準備事項：
                  <v-chip
                    v-for="(value, valueIndex) in item.prepared"
                    :key="valueIndex"
                    class="ma-2 white--text"
                    color="red"
                    small
                  >
                    {{ value }}
                  </v-chip>
                </div>
                <div v-if="item.place" class="text-left">地點：{{ item.place }}</div>
                <div v-if="item.memoSample" class="text-left">備註：{{ item.memoSample }}</div>
                <div v-if="item.dateTime" class="text-left">
                  時間：{{ item.dateTime | dateTimeFilter }}
                </div>
                <div v-if="item.notifyTime && item.notifyContent" class="text-left">
                  <div>通知時間：{{ item.notifyTime | dateTimeFilter }}</div>
                  <div>通知內容：{{ item.notifyContent }}</div>
                </div>
              </v-col>
            </v-row>

            <v-row v-for="(item, index) in listDataCopy" :key="index" no-gutters class="py-1">
              <v-col class="d-flex align-center">
                <div class="d-flex align-center" v-if="isOnContractPage">
                  <div
                    @click="confirmCheck(index)"
                    style="
                      border: 1px solid #999;
                      width: 18px;
                      height: 18px;
                      border-radius: 3px;
                      cursor: pointer;
                    "
                    class="mr-2"
                  ></div>
                  <!-- <v-checkbox
                    @click="confirmCheck(index)"
                    class="ma-0 py-1"
                    color="indigo"
                    :label="item.name"
                    :value="item"
                    v-model="selectedOnListArr"
                    hide-details
                  ></v-checkbox> -->
                  <label>{{ item.name }}</label>
                </div>
                <div v-else class="d-flex align-center">
                  <div
                    @click="confirmCheck(index)"
                    style="
                      border: 1px solid #999;
                      width: 18px;
                      height: 18px;
                      border-radius: 3px;
                      cursor: pointer;
                    "
                    class="mr-2"
                  ></div>
                  <!-- <v-checkbox
                    @click="confirmCheck(index)"
                    class="ma-0 py-1"
                    color="indigo"
                    hide-details
                    :value="item"
                    v-model="selectedOnListArr"
                  ></v-checkbox> -->
                  <input
                    type="text"
                    class="px-2 py-1"
                    style="border: 1px solid #d2d2d2; border-radius: 5px; height: 30px"
                    placeholder="名稱"
                    v-model="item.name"
                  />
                </div>
              </v-col>
              <v-col v-if="haveQuanity || haveDealerContractItem" class="d-flex align-center">
                {{ item.unit }}
              </v-col>
              <v-col v-if="haveQuanity || haveDealerContractItem">
                <input
                  type="number"
                  class="px-2 py-1"
                  style="border: 1px solid #d2d2d2; border-radius: 5px; height: 30px"
                  placeholder="數量"
                  v-model="item.quantity"
                />
              </v-col>
              <v-col v-if="haveFlow || haveDealerContractItem" class="d-flex">
                <input
                  v-if="haveDealerContractItem"
                  type="number"
                  class="ml-1 px-2 py-1"
                  style="border: 1px solid #d2d2d2; border-radius: 5px; height: 30px"
                  placeholder="單位金額"
                  v-model="item.unitAmount"
                  @change="changelistDataCopy(item, index)"
                />
                <v-btn
                  v-if="haveFlow"
                  small
                  color="primary"
                  class="mr-1"
                  @click="openDialog('participants', index, item, 'listData')"
                >
                  <v-icon left> mdi-account-arrow-right </v-icon>
                  編輯參與者
                </v-btn>
                <v-btn
                  v-if="haveFlow"
                  class="mr-1"
                  small
                  color="warning"
                  @click="openDialog('prepared', index, item, 'listData')"
                >
                  <v-icon left> mdi-clipboard-list </v-icon>
                  編輯準備事項
                </v-btn>
                <v-btn
                  v-if="haveFlow && isOnContractPage"
                  small
                  color="indigo"
                  class="mr-1 white--text"
                  @click="openDialog('place', index, item, 'listData')"
                >
                  <v-icon left> mdi-map-marker </v-icon>
                  編輯地點
                </v-btn>
                <v-btn
                  v-if="haveFlow"
                  small
                  color="success"
                  class="mr-1"
                  @click="openDialog('memo', index, item, 'listData')"
                >
                  <v-icon left> mdi-note-edit </v-icon>
                  編輯備註
                </v-btn>
                <v-btn
                  v-if="haveDate && isOnContractPage"
                  small
                  class="white--text mr-1"
                  color="red"
                  @click="openDialog('date', index, item, 'listData')"
                >
                  <v-icon left> mdi-clock </v-icon>
                  編輯時間
                </v-btn>
                <v-btn
                  v-if="haveNotify && isOnContractPage"
                  small
                  class="white--text"
                  color="info"
                  @click="openDialog('notify', index, item, 'listData')"
                >
                  <v-icon left> mdi-bell </v-icon>
                  通知時間
                </v-btn>
              </v-col>
              <v-col cols="12">
                <div v-if="item.participants" class="text-left">
                  參與者：
                  <v-chip
                    v-for="(value, valueIndex) in item.participants"
                    :key="valueIndex"
                    class="ma-2 white--text"
                    color="indigo"
                    small
                  >
                    {{ value }}
                  </v-chip>
                </div>
                <div v-if="item.prepared" class="text-left">
                  準備事項：
                  <v-chip
                    v-for="(value, valueIndex) in item.prepared"
                    :key="valueIndex"
                    class="ma-2 white--text"
                    color="red"
                    small
                  >
                    {{ value }}
                  </v-chip>
                </div>
                <div v-if="item.place" class="text-left">地點：{{ item.place }}</div>
                <div v-if="item.memoSample" class="text-left">備註：{{ item.memoSample }}</div>
                <div v-if="item.dateTime" class="text-left">
                  時間：{{ item.dateTime | dateTimeFilter }}
                </div>
                <div v-if="item.notifyTime && item.notifyContent" class="text-left">
                  <div>通知時間：{{ item.notifyTime | dateTimeFilter }}</div>
                  <div>通知內容：{{ item.notifyContent }}</div>
                </div>
              </v-col>
            </v-row>
            <v-row
              v-if="
                $router.currentRoute.params.page === 'ContractManagement' ||
                $router.currentRoute.params.page === 'IndustryManagement'
              "
              no-gutters
              style="
                position: sticky;
                bottom: 0;
                background-color: #f2f2f2;
                border-radius: 10px;
                padding: 10px 10px;
              "
              class=""
            >
              <v-col>
                <v-row
                  v-show="
                    listDataCopy.length > 0 &&
                    $router.currentRoute.params.page === 'ContractManagement' &&
                    !haveFlow
                  "
                  class="pa-2"
                  style="font-size: 18px"
                  no-gutters
                >
                  <v-col>統計</v-col>
                  <v-col :cols="thead.length - 1"
                    ><span style="font-weight: 900; border-bottom: 1px solid #000" class="px-2">{{
                      calcTotalAmount()
                    }}</span
                    >元</v-col
                  >
                </v-row>
                <v-row v-if="haveFlow">
                  <v-col cols="12" class="primary--text" style="padding: 0 10px"
                    ><h2 style="font-weight: 900">新增項目</h2>
                  </v-col>
                  <v-col>
                    <v-text-field label="名稱" hide-details dense v-model="addName"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-btn color="primary" :disabled="addName === ''" @click="addNewItem">
                      <v-icon>mdi-plus</v-icon>
                      新增
                    </v-btn>
                  </v-col>
                  <v-col v-if="isOnContractPage">
                    <v-btn
                      color="green"
                      class="white--text float-right"
                      @click="addHundryDay"
                      :disabled="disabledHundryButton"
                      >新增百日</v-btn
                    >
                  </v-col>
                </v-row>
                <v-row v-if="haveDealerContractItem">
                  <v-col cols="12" class="primary--text" style="padding: 0 10px"
                    ><h2 style="font-weight: 900">新增項目</h2>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="項目名稱"
                      hide-details
                      dense
                      v-model="addData.name"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="單位"
                      hide-details
                      dense
                      v-model="addData.unit"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="數量"
                      hide-details
                      dense
                      type="number"
                      v-model="addData.quantity"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="單位金額"
                      hide-details
                      dense
                      type="number"
                      v-model="addData.unitAmount"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-btn color="primary" :disabled="notEmpty" @click="addNewItem">
                      <v-icon>mdi-plus</v-icon>
                      新增
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="clickCloseDetailBtn()"> 取消 </v-btn>
        <v-btn color="green darken-1" text @click="sentToList()"> 確認 </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="dialog"
      width="90%"
      max-width="1200"
      scrollable
      @click:outside="clickCloseBtn"
      @keydown.esc="clickCloseBtn"
    >
      <v-card class="py-4 px-4">
        <v-card-text>
          <v-card class="pa-2">
            <div
              class="d-flex align-center mb-4"
              v-if="
                modeDialog !== 'memo' &&
                modeDialog !== 'date' &&
                modeDialog !== 'notify' &&
                modeDialog !== 'place'
              "
            >
              <v-text-field
                append-icon="mdi-magnify"
                label="名稱"
                hide-details
                dense
                v-model="dialogSearch"
                @click:append="dialogModeJudge"
                @keypress.enter="dialogModeJudge"
              ></v-text-field>
            </div>
            <div v-else-if="modeDialog === 'place'">
              <v-textarea
                class="input px-2 py-1"
                hide-details
                label="地點"
                style="width: 100%"
                v-model="place"
              >
              </v-textarea>
            </div>
            <div v-else-if="modeDialog === 'memo'">
              <v-textarea
                class="input px-2 py-1"
                hide-details
                label="備註"
                style="width: 100%"
                v-model="memoSample"
              >
              </v-textarea>
            </div>
            <div v-else-if="modeDialog === 'date'" style="max-width: 300px">
              <DateTimePicker
                :label="'日期時間'"
                :value="date"
                @confirmDateTime="confirmDateTime"
              />
            </div>
            <div v-else-if="modeDialog === 'notify'" style="max-width: 300px">
              <DateTimePicker
                :label="'通知日期時間'"
                :hasSecond="true"
                :value="notify.notifyTime"
                @confirmDateTime="confirmDateTime"
              />
              <div>
                <v-textarea
                  class="my-2"
                  style="width: 100%"
                  hide-details
                  label="通知內文"
                  v-model="notify.notifyContent"
                >
                </v-textarea>
              </div>
            </div>
            <div class="text-right" v-if="data.length > 0">
              已選擇{{ selectedDialogData.length }}項
            </div>
            <div
              v-for="(item, index) in data"
              :key="index"
              class="d-flex align-center justify-space-between my-2"
            >
              <div class="d-flex align-center">
                <div
                  v-if="selectedDialogData.indexOf(item.name) > -1"
                  @click="selectedDialogData.splice(selectedDialogData.indexOf(item.name), 1)"
                  style="
                    border: 1px solid #999;
                    width: 18px;
                    height: 18px;
                    border-radius: 3px;
                    cursor: pointer;
                    font-size: 18px;
                    text-align: center;
                    line-height: 18px;
                  "
                  class="mr-2 primary white--text"
                >
                  ✓
                </div>
                <div
                  v-else
                  @click="selectedDialogData.push(item.name)"
                  style="
                    border: 1px solid #999;
                    width: 18px;
                    height: 18px;
                    border-radius: 3px;
                    cursor: pointer;
                  "
                  class="mr-2"
                ></div>
                <label>{{ item.name }}</label>
              </div>
              <!-- <v-checkbox
                class="ma-0 py-1"
                :label="item.name"
                color="indigo"
                :value="item.name"
                hide-details
                v-model="selectedDialogData"
              ></v-checkbox> -->
            </div>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="clickCloseBtn()"> 取消 </v-btn>
          <v-btn color="green darken-1" text @click="clickConfirmBtn()"> 確認 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { userError } from "@/utils/notification";
import { getPersonnel } from "@/apis/Personnel";
import { getFlowMaterials } from "@/apis/FlowMaterials";

import { getDealerPersonnel } from "@/apis/DealerPersonnel";
import { getDealerFlowMaterials } from "@/apis/DealerFlowMaterials";
import {
  getDealerContractSamplesList,
  getDealerContractSamplesSingle,
} from "@/apis/DealerContractSamples";
import { getDealerFlowSampleList, getDealerFlowSample } from "@/apis/DealerFlowSample";
import { getHundredDay } from "@/apis/Notification";
import DateTimePicker from "./DateTimePicker.vue";

export default {
  name: "CheckGroup",
  props: [
    "listData",
    "refresh",
    "sentListData",
    "mode",
    "haveQuanity",
    "haveFlow",
    "haveDealerContractItem",
    "haveDate",
    "haveNotify",
    "thead",
  ],
  data: () => ({
    selectedTarget: "",
    selectedListCopy: [],
    disabledHundryButton: false,
    place: "",
    date: null,
    listDataCopy: [],
    selectedList: [],
    search: "",
    memoSample: "",
    participants: "",
    prepared: "",
    dialog: false,
    selectedIndex: -1,
    selected: -1,
    modeDialog: "",
    data: [],
    selectedDialogData: [],
    addName: "",
    addData: {},
    isOnContractPage: false,
    importDatas: [],
    selectedId: -1,
    notify: {},
    sort: 1,
    dialogSearch: "",
  }),
  methods: {
    async addHundryDay() {
      this.disabledHundryButton = true;
      const day = localStorage.getItem("day");
      const res = await getHundredDay(day);
      if (res.status === 200) {
        this.addName = "百日";
        const data = {
          name: this.addName,
          checked: true,
          dealerId: localStorage.getItem("userId"),
          dateTime: res.data.result.date.toString() + "000000",
        };
        this.selectedList.push(data);
        this.selectedListCopy.push(data);
      }
      this.addName = "";
    },
    calcTotalAmount() {
      if (this.haveDealerContractItem) {
        let total = 0;
        this.selectedList.forEach((item) => {
          total += Number(item.quantity) * Number(item.unitAmount);
        });
        return total;
      }
    },
    confirmDateTime(dateTime) {
      if (this.modeDialog === "date") {
        this.date = dateTime;
      } else {
        this.notify.notifyTime = dateTime;
      }
      this.$forceUpdate();
    },
    // judge repeat value
    judgeRepeatValue() {
      let boolean = true;
      if (this.haveFlow) {
        const array = [...this.listDataCopy, ...this.selectedListCopy];
        array.forEach((item) => {
          if (item.name === this.addName) {
            boolean = false;
          }
        });
      }
      if (this.haveDealerContractItem) {
        const array = [...this.listDataCopy, ...this.selectedListCopy];
        array.forEach((item) => {
          if (item.name + item.unit === this.addData.name + this.addData.unit) {
            boolean = false;
          }
        });
      }
      return boolean;
    },
    // add new Item
    async addNewItem() {
      if (this.haveFlow) {
        if (this.addName !== "") {
          if (this.judgeRepeatValue()) {
            const data = {
              name: this.addName,
              dealerId: localStorage.getItem("userId"),
            };

            // this.listDataCopy.splice(this.sort - 1, 0, data);
            // this.sort += 1;
            this.selectedList.push(data);
            this.selectedListCopy.push(data);
          } else {
            userError("重複錯誤", `已有 ${this.addName} 的流程了`);
          }
        }
        if (Object.keys(this.addData).length > 0) {
          let data = {
            name: this.addData.flowItemName,
            dealerId: localStorage.getItem("userId"),
          };

          if (this.addData.participants) {
            data.participants = this.addData.participants.split(",");
          }

          if (this.addData.prepared) {
            data.prepared = this.addData.prepared.split(",");
          }

          if (this.addData.memo) {
            data.memoSample = this.addData.memo;
          }

          this.selectedList.push(data);
          this.selectedListCopy.push(data);
          this.addData = {};
        }
        this.addName = "";
      } else if (this.haveDealerContractItem) {
        if (this.judgeRepeatValue()) {
          const data = {
            name: this.addData.name,
            dealerId: localStorage.getItem("userId"),
            unit: this.addData.unit,
            unitAmount: this.addData.unitAmount,
            quantity: this.addData.quantity,
          };

          this.selectedList.push(data);
          this.selectedListCopy.push(data);
        } else {
          userError(
            "重複錯誤",
            `已有 名稱為：${this.addData.name} 單位為：${this.addData.unit} 的內容了`
          );
        }
        this.addData = {};
      }
    },
    // judge have checked for dialog item
    judgeHaveChecked() {
      let reviseData = {};
      if (this.selectedTarget === "selectedList") {
        reviseData = this.selectedListCopy[this.selectedIndex];
      } else {
        reviseData = this.listDataCopy[this.selectedIndex];
      }

      if (this.modeDialog === "participants" && reviseData.participants) {
        this.selectedDialogData = reviseData.participants;
      } else if (this.modeDialog === "prepared" && reviseData.prepared) {
        this.selectedDialogData = reviseData.prepared;
      }
    },
    clickCloseDetailBtn() {
      this.addData = {};
      this.addName = "";
      this.$emit("clickCloseDetailBtn");
    },
    refreshSelectedListValue() {
      this.selectedList.forEach((item, index) => {
        if (this.listDataCopy[this.selectedIndex].name === item.name) {
          this.selectedList[index] = this.listDataCopy[this.selectedIndex];
        }
      });
      this.$forceUpdate();
    },
    // confirm button
    clickConfirmBtn() {
      let reviseData = {};
      let selectedListsIndex = -1;
      if (this.selectedTarget === "selectedList") {
        reviseData = this.selectedListCopy[this.selectedIndex];
        this.selectedList.forEach((item, index) => {
          if ((item.id && reviseData.id && (item.id == reviseData.id)) || JSON.stringify(item) === JSON.stringify(reviseData)) {
            selectedListsIndex = index;
          }
        });
      } else {
        reviseData = this.listDataCopy[this.selectedIndex];
      }

      if (this.modeDialog === "participants" && this.selectedDialogData.length > 0) {
        reviseData.participants = this.selectedDialogData.toString().split(",");
        if (selectedListsIndex > -1) {
          this.selectedList[selectedListsIndex].participants = reviseData.participants;
        }
      } else if (this.modeDialog === "prepared" && this.selectedDialogData.length > 0) {
        reviseData.prepared = this.selectedDialogData.toString().split(",");
        if (selectedListsIndex > -1) {
          this.selectedList[selectedListsIndex].prepared = reviseData.prepared;
        }
      } else if (this.modeDialog === "memo") {
        reviseData.memoSample = this.memoSample;
        if (selectedListsIndex > -1) {
          this.selectedList[selectedListsIndex].memoSample = reviseData.memoSample;
        }
      } else if (this.modeDialog === "place") {
        reviseData.place = this.place;
        if (selectedListsIndex > -1) {
          this.selectedList[selectedListsIndex].place = reviseData.place;
        }
      }
      if (this.modeDialog === "participants" && this.selectedDialogData.length === 0) {
        reviseData.participants = "";
        if (selectedListsIndex > -1) {
          this.selectedList[selectedListsIndex].participants = reviseData.participants;
        }
      } else if (this.modeDialog === "prepared" && this.selectedDialogData.length === 0) {
        reviseData.prepared = "";
        if (selectedListsIndex > -1) {
          this.selectedList[selectedListsIndex].prepared = reviseData.prepared;
        }
      } else if (this.modeDialog === "date") {
        reviseData.dateTime =
          this.date.toString().replaceAll("-", "").replaceAll(":", "").replaceAll(" ", "") + "00";
        if (selectedListsIndex > -1) {
          this.selectedList[selectedListsIndex].dateTime = reviseData.dateTime;
        }
      } else if (this.modeDialog === "notify") {
        reviseData.notifyTime = this.notify.notifyTime
          .toString()
          .replaceAll("-", "")
          .replaceAll(":", "")
          .replaceAll(" ", "");
        reviseData.notifyContent = this.notify.notifyContent;
        if (selectedListsIndex > -1) {
          this.selectedList[selectedListsIndex].notifyTime = reviseData.notifyTime;
          this.selectedList[selectedListsIndex].notifyContent = reviseData.notifyContent;
        }
      }

      this.dialog = false;
      this.selectedDialogData = [];
      this.selectedTarget = "";
    },
    // close dialog
    clickCloseBtn() {
      this.selected = -1;
      this.modeDialog = "";
      this.memoSample = "";
      this.selectedIndex = -1;
      this.selectedDialogData = [];
      this.dialog = false;
    },
    // get Materials
    async getMaterials() {
      let res = {};
      let query = {};
      if (localStorage.getItem("user") === "Dealers") {
        query.dealerId = localStorage.getItem("userId");
        if (this.dialogSearch !== "") {
          query.name = this.dialogSearch;
        }
        res = await getDealerFlowMaterials(query);
      } else {
        if (this.dialogSearch !== "") {
          query.name = this.dialogSearch;
        }
        res = await getFlowMaterials(query);
      }
      this.data = res.data.result;
      if (this.selectedDialogData.length === 0) {
        this.judgeHaveChecked();
      }
    },
    // get Personnel
    async getPersonnel() {
      let res = {};
      let query = {};
      if (localStorage.getItem("user") === "Dealers") {
        query.dealerId = localStorage.getItem("userId");
        if (this.dialogSearch !== "") {
          query.name = this.dialogSearch;
        }
        res = await getDealerPersonnel(query);
      } else {
        if (this.dialogSearch !== "") {
          query.name = this.dialogSearch;
        }
        res = await getPersonnel(query);
      }
      this.data = res.data.result;
      if (this.selectedDialogData.length === 0) {
        this.judgeHaveChecked();
      }
    },
    dialogModeJudge(datas) {
      if (this.modeDialog === "participants") {
        this.getPersonnel();
      } else if (this.modeDialog === "prepared") {
        this.getMaterials();
      } else if (this.modeDialog === "place") {
        if (!datas.place) {
          this.place = "";
        } else {
          this.place = datas.place;
        }
      } else if (this.modeDialog === "memo") {
        if (!datas.memoSample) {
          this.memoSample = "";
        } else {
          this.memoSample = datas.memoSample;
        }
      } else if (this.modeDialog === "notify") {
        this.notify = {};
        if (datas.notifyTime && datas.notifyContent) {
          this.notify = {
            notifyTime: this.dateTimeConvert(datas.notifyTime),
            notifyContent: datas.notifyContent,
          };
        }
      } else if (this.modeDialog === "date") {
        this.date = null;
        if (datas.dateTime !== undefined && datas.dateTime !== "" && Number(datas.dateTime) !== 0) {
          this.date = this.dateTimeConvert(datas.dateTime);
        } else {
          const date = new Date();
          this.date = `${date.getFullYear()}-${
            date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1).toString()
          }-${
            date.getDate() >= 10 ? date.getDate() : "0" + date.getDate().toString()
          } ${date.getHours()}:${date.getMinutes()}`;
        }
      }
    },
    openDialog(mode, index, datas, target) {
      this.dialogSearch = "";
      this.data = [];
      this.selectedIndex = index;
      this.selectedTarget = target;
      this.modeDialog = mode;
      this.dialogModeJudge(datas);
      this.dialog = true;
    },
    dateTimeConvert(date) {
      const splitDate = date.toString().split("");
      const year = splitDate.splice(0, 4).toString().replaceAll(",", "");
      const month = splitDate.splice(0, 2).toString().replaceAll(",", "");
      const day = splitDate.splice(0, 2).toString().replaceAll(",", "");
      const hour = splitDate.splice(0, 2).toString().replaceAll(",", "");
      const minute = splitDate.splice(0, 2).toString().replaceAll(",", "");
      return `${year}-${month}-${day} ${hour}:${minute}`;
    },
    changeSelectedList(item, index) {
      this.selectedList[index].quantity = item.quantity;
      this.selectedList[index].unitAmount = item.unitAmount;
      this.calcTotalAmount();
      this.$forceUpdate();
    },
    changelistDataCopy(item, index) {
      this.listDataCopy[index].quantity = item.quantity;
      this.listDataCopy[index].unitAmount = item.unitAmount;
      this.$forceUpdate();
    },
    confirmCheck(index) {
      if (this.haveDealerContractItem) {
        if (!this.listDataCopy[index].quantity) {
          this.listDataCopy[index].quantity = 1;
        }

        if (!this.listDataCopy[index].unitAmount) {
          this.listDataCopy[index].unitAmount = 1;
        }
      }

      if (this.haveQuanity) {
        if (!this.listDataCopy[index].quantity) {
          this.listDataCopy[index].quantity = 1;
        }
      }

      const moveData = this.listDataCopy.splice(index, 1);
      this.selectedList.push(...moveData);
      this.selectedListCopy.push(...moveData);
    },
    cancelCheck(data, index) {
      this.selectedList.forEach((item, valIndex) => {
        if (JSON.stringify(item) === JSON.stringify(data)) {
          this.selectedList.splice(valIndex, 1);
        }
      });

      if (this.haveDealerContractItem) {
        delete data.quantity;
        delete data.unitAmount;
      }

      if (this.haveQuanity) {
        delete data.quantity;
      }

      this.selectedListCopy.splice(index, 1);
      this.listDataCopy.push(data);
    },
    sentToList() {
      debugger;
      this.selectedList.forEach((val, index) => {
        let item = null;
        if (typeof val === "string") {
          item = JSON.parse(val);
        } else {
          item = val;
        }
        this.selectedList[index] = item;
        if (item.participants) {
          this.selectedList[index].participants = item.participants.toString();
        } else {
          this.selectedList[index].participants = "";
        }
        if (item.prepared) {
          this.selectedList[index].prepared = item.prepared.toString();
        } else {
          this.selectedList[index].prepared = "";
        }
        if (item.memoSample) {
          this.selectedList[index].memo = item.memoSample;
        }
        if (item.dateTime) {
          this.selectedList[index].dateTime = item.dateTime;
        }
        if (item.notifyTime && item.notifyContent) {
          this.selectedList[index].notifyTime = item.notifyTime;
          this.selectedList[index].notifyContent = item.notifyContent;
        }
        if (item.place) {
          this.selectedList[index].place = item.place;
        }
      });
      this.$emit("sentConfirm", this.selectedList);
      this.memoSample = "";
      this.selectedList = [];
    },
    // sent search name request
    searchFeature() {
      this.$emit("searchFeature", this.search, this.selectedList);
      this.$forceUpdate();
    },
    checkedSelect() {
      this.selectedList = [];
      setTimeout(() => {
        const removeIndexArr = [];
        this.listDataCopy.forEach((item, index) => {
          if (item.checked && this.haveFlow) {
            let data = item;
            if (typeof data.participants === "string" && item.participants !== "")
              data.participants = item.participants.split(",");
            if (typeof data.prepared === "string" && item.prepared !== "")
              data.prepared = item.prepared.split(",");
            this.selectedList.push(JSON.stringify(data));
          } else if (item.checked && this.haveQuanity) {
            this.selectedList.push(JSON.stringify(item));
          } else if (item.checked && this.haveDealerContractItem) {
            removeIndexArr.push(index);
            this.selectedList.push(item);
          }
        });
        removeIndexArr.reverse().forEach((index) => {
          this.listDataCopy.splice(index, 1);
        });
      }, 1000);
      this.$forceUpdate();
    },
    async getImportDatas() {
      const index = this.haveFlow ? 1 : 0;
      const functionArr = [getDealerContractSamplesList, getDealerFlowSampleList];
      const res = await functionArr[index]({
        pageSize: 100,
        dealerId: localStorage.getItem("userId"),
      });
      this.importDatas = res.data.result.results;
      this.importDatas.forEach((item, index) => {
        this.importDatas[index].disabled = false;
      });
      if (this.importDatas.length > 0) {
        this.selectedId = this.importDatas[0].id;
      }
    },
    deleteImportDatas() {
      this.importDatas.forEach((item, index) => {
        if (item.id === this.selectedId) {
          this.importDatas[index].disabled = true;
        }
      });

      this.selectedId = -1;
    },
    async clickImportBtn() {
      const index = this.haveFlow ? 1 : 0;
      const functionArr = [getDealerContractSamplesSingle, getDealerFlowSample];
      const res = await functionArr[index](localStorage.getItem("userId"), this.selectedId);
      const detail = res.data.result.details;
      if (index === 0) {
        detail.forEach((item) => {
          let boolean = true;
          this.listDataCopy.forEach((value, valIndex) => {
            if (`${item.contractItemName}${item.unit}` === `${value.name}${value.unit}`) {
              this.listDataCopy[valIndex].quantity = item.quantity;
              this.listDataCopy[valIndex].unitAmount = item.unitAmount;
              this.selectedListCopy.push(value);
              this.selectedList.push(value);
              this.listDataCopy.splice(valIndex, 1);
              boolean = false;
            }
          });
          if (boolean) {
            this.addData = item;
            this.addData.name = item.contractItemName;
            this.addNewItem();
          }
        });
      } else {
        detail.forEach((item) => {
          let boolean = true;
          this.listDataCopy.forEach((value, valIndex) => {
            if (item.flowItemName === value.name) {
              this.selectedListCopy.push(value);
              this.selectedList.push(value);
              this.listDataCopy.splice(valIndex, 1);
              boolean = false;
            }
          });
          if (boolean) {
            this.addData = item;
            this.addNewItem();
          }
        });
      }
      this.deleteImportDatas();
    },
    // add hundray day is exist?
    async hundryDayJudge() {
      this.disabledHundryButton = false;
      const day = localStorage.getItem("day");
      const res = await getHundredDay(day);
      if (res.status === 200) {
        const reg = "百日" + res.data.result.date.toString();
        this.listDataCopy.forEach((item) => {
          if (item.name && item.dateTime) {
            const value =
              item.name +
              item.dateTime.toString().split("").splice(0, 8).toString().replaceAll(",", "");
            if (reg === value) {
              this.disabledHundryButton = true;
            }
          }
        });
      }
    },
  },
  async mounted() {
    this.$store.commit("setLoading", true);
    this.isOnContractPage = this.$router.currentRoute.params.page === "ContractManagement";
    await this.getImportDatas();
    if (this.isOnContractPage) {
      await this.hundryDayJudge();
    }
    setTimeout(() => {
      this.$store.commit("setLoading", false);
    }, 2000);
  },
  watch: {
    listData() {
      const data = cloneDeep(this.listData);
      const confirmArr = [];
      const cancelArr = [];
      data.sort((a, b) => {
        if (a.sort === undefined) return 0;
        if (this.sort < a.sort) {
          this.sort = a.sort;
        }
        if (this.sort < b.sort) {
          this.sort = b.sort;
        }
        return a.sort > b.sort ? 1 : -1;
      });

      data.forEach((item) => {
        let value = cloneDeep(item);
        delete value.checked;
        delete value.createTime;
        delete value.createUser;

        // have flow
        if (value.participants && typeof value.participants !== "object") {
          value.participants = value.participants.split(",");
        }

        if (value.prepared && typeof value.prepared !== "object") {
          value.prepared = value.prepared.split(",");
        }

        if (item.checked) {
          confirmArr.push(value);
        } else {
          cancelArr.push(value);
        }
      });

      if (this.selectedList.length === 0) {
        this.selectedList = confirmArr;
      }

      this.selectedListCopy = cloneDeep(confirmArr);
      this.listDataCopy = cancelArr;
      this.$forceUpdate();
    },
    // when close dialog, need to clear data
    async refresh(value) {
      if (value) {
        this.$store.commit("setLoading", true);
        this.selectedList = [];
        this.isOnContractPage = this.$router.currentRoute.params.page === "ContractManagement";
        this.listDataCopy = this.listData;
        await this.checkedSelect();
        await this.getImportDatas();
        if (this.isOnContractPage) {
          await this.hundryDayJudge();
        }
      } else {
        this.search = "";
      }
      setTimeout(() => {
        this.$store.commit("setLoading", false);
      }, 2000);
    },
  },
  components: { DateTimePicker },
  computed: {
    notEmpty() {
      return (
        this.addName === "" &&
        (!this.addData.name ||
          this.addData.name.trim() === "" ||
          !this.addData.unit ||
          this.addData.name.trim() === "" ||
          this.addData.unitAmount === undefined ||
          this.addData.unitAmount <= 0 ||
          this.addData.quantity === undefined ||
          this.addData.quantity <= 0)
      );
    },
  },
};
</script>
<style>
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-button {
  background: transparent;
  border-radius: 8px;
}

::-webkit-scrollbar-track-piece {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid slategrey;
}

::-webkit-scrollbar-track {
  box-shadow: transparent;
}
</style>
