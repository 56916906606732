<template>
  <div id="DealerContractSamples">
    <v-card>
      <v-card-title>
        {{ action === "update" ? "修改" : "創建" }}合約範本
      </v-card-title>

      <v-card-text>
        <v-row no-gutters v-show="action !== 'update'">
          <v-col>
            <v-tabs v-model="selectedTabIndex">
              <v-tab
                v-for="(tab, tabIndex) in tabList"
                :key="tabIndex"
              >
                {{ tab }}
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row v-if="selectedTabIndex === 0">
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <div class="d-flex mb-2">
              <v-text-field
                clearable
                label="範本名稱"
                hide-details
                v-model="data.name"
              ></v-text-field>
            </div>
            <v-textarea
              class="input"
              style="width: 100%"
              hide-details
              label="備註"
              v-model="data.memo"
            ></v-textarea>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <v-row v-if="selectedTabIndex === 1">
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <div class="d-flex mb-2">
              <v-text-field
                clearable
                label="範本名稱"
                hide-details
                v-model="newName"
              ></v-text-field>
            </div>
            <div>
              <v-select
                :items="sampleData"
                item-text="name"
                item-value="id"
                class="mr-1"
                hide-details
                label="範本"
                v-model="selectId"
              ></v-select>
            </div>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="clickCloseBtn()">
          取消
        </v-btn>
        <v-btn
          :disabled="disable"
          color="green darken-1"
          text
          @click="clickConfirmBtn()"
        >
          確認
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { getContractSamplesList } from '@/apis/ContractSamples';
export default {
  name: "DealerContractSamples",
  props: ["action", "data", "dealerData"],
  data: () => ({
    sampleData: [],
    selectedTabIndex: 0,
    tabList: ['自訂範本', '引入範本'],
    newName: '',
    selectId: -1,
    rules: {
      required: (value) => !!value || "必填",
    },
  }),
  methods: {
    clickCloseBtn() {
      this.selectId = -1;
      this.newName = '';
      this.selectedTabIndex = 0;
      this.$emit("clickCloseBtn");
    },
    clickConfirmBtn() {
      if (this.selectedTabIndex === 0) {
        this.data.isEnable = true;
        this.$emit("clickConfirmBtn", this.data);
      } else {
        const data = {
          id: this.selectId,
          name: this.newName,
        };
        this.$emit('clickImportBtn', data);
        this.selectId = -1;
        this.newName = '';
        this.selectedTabIndex = 0;
      }
    },
    async getSampleData() {
      const res = await getContractSamplesList({ pageSize: 100 });
      if (res.status === 200) {
        this.sampleData = res.data.result.results;
      }
    },
  },
  computed: {
    disable() {
      return !this.data.name && (!this.newName || this.selectId < 0);
    },
  },
  watch: {
    selectedTabIndex(value) {
      if (value === 1) {
        this.getSampleData();
      }
    }
  }
};
</script>
