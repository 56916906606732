import http from "@/http/axios";
import { judgeError, queryFilter } from "./Common";
import { error, success } from "@/utils/notification";

export async function getCustomerContractList(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/DealerContractualRelationships/ContractList${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function getCustomersList(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/DealerContractualRelationships/CustomerList${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function getRelationshipCustomers(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/DealerContractualRelationships/GetRelationshipCustomers${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function createContractualRelatonship(contractId, body) {
  const res = await http.post(`/api/DealerContractualRelationships/${contractId}/Create`, body);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }

  return res;
}

export async function deleteContractualRelatonship(contractId, body) {
  const res = await http.post(`/api/DealerContractualRelationships/${contractId}/Delete`, body);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }

  return res;
}

export async function getContractualRelatonship(contractId) {
  const res = await http.get(`/api/DealerContractualRelationships/Get?contractId=${contractId}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else if (res.status != 200 && res.status != 404) {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }

  return res;
}
