<template>
  <v-card class="pa-5 text-center" id="Transfer">
    <div
      class="justify-center"
      :class="haveQuanity || haveDealerContractItem || haveFlow ? '' : 'd-flex'"
    >
      <div
        :style="
          haveQuanity || haveDealerContractItem || haveFlow
            ? 'width: 100%'
            : 'width: 40%'
        "
      >
        <v-card class="py-2" style="height: 40vh; overflow-y: auto">
          <div class="px-2 d-flex align-center">
            <v-text-field
              append-icon="mdi-magnify"
              label="名稱"
              hide-details
              dense
              v-model="search"
              @keypress.enter="searchFeature"
              @click:append="searchFeature"
            ></v-text-field>
          </div>
          <div class="w-100 my-2" style="border: 1px solid #d2d2d2"></div>
          <div class="px-2">
            <div v-for="(item, index) in listDataCopy" :key="index" v-show="listDataCopy.length !== 0">
              <div class="d-flex align-center justify-space-between py-1">
                <v-checkbox
                  @change="judgeMode('leftToRight')"
                  :disabled="mode === 'rightToLeft'"
                  class="ma-0 py-1"
                  :label="item.revise_name"
                  color="indigo"
                  :value="item"
                  hide-details
                  v-model="selectedList"
                ></v-checkbox>
                <div class="d-flex">
                  <v-btn
                    v-if="haveFlow"
                    small
                    color="primary"
                    class="mr-1"
                    @click="openDialog('participants', index)"
                  >
                    <v-icon left> mdi-account-arrow-right </v-icon>
                    新增參與者</v-btn
                  >
                  <v-btn
                    v-if="haveFlow"
                    class="mr-1"
                    small
                    color="warning"
                    @click="openDialog('prepared', index)"
                  >
                    <v-icon left> mdi-clipboard-list </v-icon>
                    新增準備事項</v-btn
                  >
                  <v-btn
                    v-if="haveFlow"
                    small
                    color="success"
                    @click="openDialog('memo', index)"
                  >
                    <v-icon left> mdi-note-edit </v-icon>
                    新增備註</v-btn
                  >
                  <input
                    v-if="haveQuanity || haveDealerContractItem"
                    type="number"
                    class="px-2 py-1"
                    style="border: 1px solid #d2d2d2; border-radius: 5px"
                    placeholder="數量"
                    v-model="listDataCopy[index].quantity"
                  />
                  <input
                    v-if="haveDealerContractItem"
                    type="number"
                    class="ml-1 px-2 py-1"
                    style="border: 1px solid #d2d2d2; border-radius: 5px"
                    placeholder="單位金額"
                    v-model="listDataCopy[index].unitAmount"
                  />
                </div>
              </div>
              <div v-if="item.participants" class="text-left">
                參與者：
                <v-chip
                  v-for="(value, valueIndex) in JSON.parse(item.participants)"
                  :key="valueIndex"
                  class="ma-2 white--text"
                  color="indigo"
                  small
                >
                  {{ value }}
                </v-chip>
              </div>
              <div v-if="item.prepared" class="text-left">
                準備事項：
                <v-chip
                  v-for="(value, valueIndex) in JSON.parse(item.prepared)"
                  :key="valueIndex"
                  class="ma-2 white--text"
                  color="red"
                  small
                >
                  {{ value }}
                </v-chip>
              </div>
              <div v-if="item.memoSample" class="text-left">
                備註：{{ item.memoSample }}
              </div>
            </div>
            <div v-show="listDataCopy.length === 0" class="py-2">
              無資料
            </div>
          </div>
        </v-card>
      </div>
      <div
        :style="
          haveQuanity || haveDealerContractItem || haveFlow
            ? 'width: 100%'
            : 'width: 20%'
        "
        :class="haveQuanity || haveDealerContractItem || haveFlow ? '' : 'mx-4'"
        class="d-flex justify-center align-center my-2"
      >
        <v-btn
          class="mr-1"
          color="indigo"
          @click="cancelSentToList"
          :disabled="mode !== 'rightToLeft'"
        >
          <v-icon class="white--text">
            {{
              haveQuanity || haveDealerContractItem || haveFlow
                ? "mdi-arrow-up-bold"
                : "mdi-arrow-left-bold"
            }}
          </v-icon>
        </v-btn>
        <v-btn
          class="ml-1"
          color="indigo"
          @click="sentToList"
          :disabled="mode !== 'leftToRight'"
        >
          <v-icon class="white--text">
            {{
              haveQuanity || haveDealerContractItem || haveFlow
                ? "mdi-arrow-down-bold"
                : "mdi-arrow-right-bold"
            }}
          </v-icon>
        </v-btn>
      </div>
      <div
        :style="
          haveQuanity || haveDealerContractItem || haveFlow
            ? 'width: 100%'
            : 'width: 40%'
        "
      >
        <v-card class="py-2" style="height: 40vh; overflow-y: auto">
          <div class="px-2">
            <div v-for="(item, index) in sentListData" :key="index">
              <div class="d-flex align-center justify-space-between py-1">
                <v-checkbox
                  @change="judgeMode('rightToLeft')"
                  class="ma-0 py-1"
                  :disabled="item.hasValue"
                  :label="item.revise_name"
                  color="indigo"
                  :value="item"
                  hide-details
                  v-model="selectedList"
                ></v-checkbox>
                <div class="d-flex">
                  <v-btn
                    v-if="haveFlow"
                    small
                    color="primary"
                    class="mr-1"
                    @click="openDialog('participants', index, true)"
                  >
                    <v-icon left> mdi-account-arrow-right </v-icon>
                    新增參與者</v-btn
                  >
                  <v-btn
                    v-if="haveFlow"
                    class="mr-1"
                    small
                    color="warning"
                    @click="openDialog('prepared', index, true)"
                  >
                    <v-icon left> mdi-clipboard-list </v-icon>
                    新增準備事項</v-btn
                  >
                  <v-btn
                    v-if="haveFlow"
                    small
                    color="success"
                    @click="openDialog('memo', index, true)"
                  >
                    <v-icon left> mdi-note-edit </v-icon>
                    新增備註</v-btn
                  >
                  <input
                    v-if="haveQuanity || haveDealerContractItem"
                    type="number"
                    class="px-2 py-1"
                    style="border: 1px solid #d2d2d2; border-radius: 5px"
                    placeholder="數量"
                    v-model="item.quantity"
                  />
                  <input
                    v-if="haveDealerContractItem"
                    type="number"
                    class="ml-1 px-2 py-1"
                    style="border: 1px solid #d2d2d2; border-radius: 5px"
                    placeholder="單位金額"
                    v-model="item.unitAmount"
                  />
                </div>
              </div>
              <div v-if="item.participants" class="text-left">
                參與者：
                <v-chip
                  v-for="(value, valueIndex) in JSON.parse(item.participants)"
                  :key="valueIndex"
                  class="ma-2 white--text"
                  color="indigo"
                  small
                >
                  {{ value }}
                </v-chip>
              </div>
              <div v-if="item.prepared" class="text-left">
                準備事項：
                <v-chip
                  v-for="(value, valueIndex) in JSON.parse(item.prepared)"
                  :key="valueIndex"
                  class="ma-2 white--text"
                  color="red"
                  small
                >
                  {{ value }}
                </v-chip>
              </div>
              <div v-if="item.memoSample" class="text-left">
                備註：{{ item.memoSample }}
              </div>
            </div>
            <div v-if="sentListData.length <= 0">無資料</div>
          </div>
        </v-card>
      </div>
    </div>
    <v-dialog v-model="dialog" width="90%" max-width="1200"
      @click:outside="clickCloseBtn"
      @keydown.esc="clickCloseBtn">
      <v-card class="py-4 px-4">
        <v-card-text>
          <v-card class="pa-2">
            <div class="d-flex align-center mb-4" v-if="modeDialog !== 'memo'">
              <v-text-field
                append-icon="mdi-magnify"
                label="名稱"
                hide-details
                dense
                v-model="search"
                @click:append="searchFeature"
              ></v-text-field>
            </div>
            <div v-else>
              <v-textarea
                class="input px-2 py-1"
                hide-details
                label="備註"
                style="width: 100%"
                v-model="memoSample"
              ></v-textarea>
            </div>
            <div
              v-for="(item, index) in data"
              :key="index"
              class="d-flex align-center justify-space-between"
            >
              <v-checkbox
                class="ma-0 py-1"
                :label="item.name"
                color="indigo"
                :value="item.name"
                hide-details
                v-model="selectedDialogData"
              ></v-checkbox>
            </div>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="clickCloseBtn()">
            取消
          </v-btn>
          <v-btn color="green darken-1" text @click="clickConfirmBtn()">
            確認
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { getPersonnel } from "@/apis/Personnel";
import { getFlowMaterials } from "@/apis/FlowMaterials";
export default {
  name: "Transfer",
  props: [
    "listData",
    "refresh",
    "sentListData",
    "mode",
    "haveQuanity",
    "haveFlow",
    "haveDealerContractItem",
  ],
  data: () => ({
    listDataCopy: [],
    selectedList: [],
    search: "",
    memoSample: "",
    participants: "",
    prepared: "",
    dialog: false,
    selectedIndex: -1,
    selected: -1,
    modeDialog: "",
    data: [],
    selectedDialogData: [],
  }),
  methods: {
    // confirm button
    clickConfirmBtn() {
      if (this.selected === -1) {
        if (this.modeDialog === "participants") {
          this.listDataCopy[this.selectedIndex].participants = JSON.stringify(
            this.selectedDialogData
          );
        } else if (this.modeDialog === "prepared") {
          this.listDataCopy[this.selectedIndex].prepared = JSON.stringify(
            this.selectedDialogData
          );
        } else if (this.modeDialog === "memo") {
          this.listDataCopy[this.selectedIndex].memoSample = this.memoSample;
        }
      } else if (this.selected === 0) {
        if (this.modeDialog === "participants") {
          this.sentListData[this.selectedIndex].participants = JSON.stringify(
            this.selectedDialogData
          );
        } else if (this.modeDialog === "prepared") {
          this.sentListData[this.selectedIndex].prepared = JSON.stringify(
            this.selectedDialogData
          );
        } else if (this.modeDialog === "memo") {
          this.sentListData[this.selectedIndex].memoSample = this.memoSample;
        }
      }
      this.clickCloseBtn();
    },
    // close dialog
    clickCloseBtn() {
      this.selected = -1;
      this.modeDialog = "";
      this.memoSample = "";
      this.selectedIndex = -1;
      this.selectedDialogData = [];
      this.dialog = false;
    },
    // get Materials
    async getMaterials() {
      const res = await getFlowMaterials({});
      this.data = res.data.result;
    },
    // get Personnel
    async getPersonnel() {
      const res = await getPersonnel({});
      this.data = res.data.result;
    },
    openDialog(mode, index, boolean) {
      if (boolean) {
        this.selected = 0;
      }
      this.data = [];
      this.selectedIndex = index;
      this.modeDialog = mode;
      if (mode === "participants") {
        this.getPersonnel();
      } else if (mode === "prepared") {
        this.getMaterials();
      } else if (mode === "memo") {
        this.memoSample = "";
      }
      this.dialog = true;
    },
    judgeMode(value) {
      this.$emit("judgeMode", value, this.selectedList);
    },
    sentToList() {
      this.$emit("sentToList", this.selectedList, true);
      this.memoSample = "";
      this.selectedList = [];
    },
    cancelSentToList() {
      this.$emit("cancelSentToList", this.selectedList);
      this.selectedList = [];
    },
    // sent search name request
    searchFeature() {
      this.$emit("searchFeature", this.search);
    },
  },
  created() {
    this.listDataCopy = this.listData;
  },
  watch: {
    listData() {
      this.selectedList = [];
      this.listDataCopy = this.listData;
    },
    // when close dialog, need to clear data
    refresh(value) {
      if (!value) {
        this.search = '';
        this.selectedList = [];
        this.listDataCopy = this.listData;
      }
    },
  },
};
</script>
