<template>
  <div id="EObituaryManagement">
    <v-card class="ma-4">
      <div class="d-flex justify-space-between pa-3 w-100">
        <div class="d-flex">
          <v-tabs v-model="selectedTabIndex">
            <v-tab
              v-for="(tab, tabIndex) in tabList"
              :key="tabIndex"
              v-show="tab.role === 'All' || role === tab.role"
            >
              {{ tab.name }}
            </v-tab>
          </v-tabs>
        </div>
      </div>
    </v-card>
    <v-card class="ma-4">
      <TablePage
        :targetName="'eObituaryTable'"
        :selectedTabIndex="selectedTabIndex"
        :selectData="selectItem"
        :thead="thead"
        :keyGroup="keyGroup"
        :data="data"
        @search="search"
        @openDialog="openDialog"
        @clickUpdateBtn="clickUpdateBtn"
        @changeisEnable="changeisEnable"
      />
      <v-pagination
        v-if="totalPage > 0"
        v-model="page"
        :length="totalPage"
        :total-visible="7"
        circle
        @input="changePage"
      ></v-pagination>
      <v-dialog
        v-model="dialog"
        width="90%"
        max-width="1200"
        @click:outside="clickCloseBtn"
        @keydown.esc="clickCloseBtn"
      >
        <component
          :is="this.createBtn.value"
          :action="action"
          :data="selectedData"
          :eObituaryMusics="eObituaryMusics"
          @clickCloseBtn="clickCloseBtn"
          @clickConfirmBtn="clickConfirmBtn"
        ></component>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import {
  getEObituaryMusicsList,
  getEObituaryTemplatesList,
  addEObituaryMusics,
  updateEObituaryMusics,
  createEObituaryTemplatesByDealerId,
  updateEObituaryTemplates,
  enableEObituaryMusics,
  disableEObituaryMusics,
  enableEObituaryTemplates,
  disableEObituaryTemplates,
  getEObituaryMusics,
} from "@/apis/EObituary";
import Templates from "@/components/eObituaryManagement/Templates.vue";
import MusicPage from "@/components/eObituaryManagement/MusicPage.vue";
import TablePage from "@/components/TablePage.vue";

export default {
  name: "EObituaryManagement",
  components: {
    TablePage,
    Templates,
    MusicPage,
  },
  data() {
    return {
      createUpdateFunctionArray: [
        [createEObituaryTemplatesByDealerId, updateEObituaryTemplates],
        [addEObituaryMusics, updateEObituaryMusics],
      ],
      isEnabledFunctionArray: [
        [enableEObituaryTemplates, disableEObituaryTemplates],
        [enableEObituaryMusics, disableEObituaryMusics],
      ],
      getListFunctionArray: [getEObituaryTemplatesList, getEObituaryMusicsList],
      params: {},
      role: "",
      eObituaryMusics: [],
      page: 1,
      totalPage: 0,
      action: "",
      dialog: false,
      selectedTabIndex: 0,
      selectItem: [],
      tabList: [
        {
          name: "模組",
          value: "Templates",
          role: "All",
        },
        {
          name: "音樂",
          value: "MusicPage",
          role: "Operators",
        },
      ],
      selectedData: {},
      buttonGroup: [],
      thead: [],
      data: [],
      keyGroup: [],
      createBtn: {
        name: "",
        value: "",
      },
    };
  },
  methods: {
    async changeisEnable(id, boolean) {
      await this.isEnabledFunctionArray[this.selectedTabIndex][Number(!boolean)](id);
      await this.getListFunction(this.getListFunctionArray[this.selectedTabIndex]);
    },
    async search(enableBoolean, nowSelectItem, data) {
      this.params = {};
      if (data) {
        this.params[nowSelectItem] = data;
      }
      this.getListFunction(this.getListFunctionArray[this.selectedTabIndex]);
    },
    clickUpdateBtn(index) {
      this.openDialog("update", index);
    },
    async clickConfirmBtn(data) {
      const action = this.action === "create" ? 0 : 1;
      const res = await this.createUpdateFunctionArray[this.selectedTabIndex][action](data);
      if (res.status === 200) {
        this.clickCloseBtn();
        this.getListFunction(this.getListFunctionArray[this.selectedTabIndex]);
      }
    },
    clickCloseBtn() {
      this.dialog = false;
      this.action = "";
      this.selectedData = {};
    },
    openDialog(action, index) {
      this.action = action;
      this.selectedData.isEnable = true;
      this.dialog = true;
      if (typeof index === "number") {
        this.selectedData = this.data[index];
      }
    },
    async changePage() {
      this.params.page = this.page;
      this.getListFunction(this.getListFunctionArray[this.selectedTabIndex]);
    },
    async getListFunction(doCallFunction) {
      this.$store.commit("setLoading", true);
      const res = await doCallFunction(this.params);
      if (res.status === 200) {
        this.data = res.data.result.results;
        this.totalPage = res.data.result.pageCount;
        this.page = res.data.result.currentPage;
      }
      this.$store.commit("setLoading", false);
    },
    async changeTab() {
      this.createBtn = this.tabList[this.selectedTabIndex];
      this.params = {};
      switch (this.selectedTabIndex) {
        case 0:
          this.thead = [
            "序號",
            "名稱",
            "示意圖名稱",
            "電子訃聞音樂序號",
            "備註",
            "建立時間",
            "創建人員",
            "更新時間",
            "更新人員",
            "操作",
          ];
          this.keyGroup = [
            "id",
            "name",
            "schematicDiagramName",
            "eObituaryMusicId",
            "memo",
            "createTime",
            "createUser",
            "updateTime",
            "updateUser",
          ];
          this.selectItem = [
            { text: "名稱", value: "name" },
            { text: "啟用", value: "isEnable" },
          ];
          this.getEObituaryMusics();
          break;
        case 1:
          this.thead = [
            "序號",
            "名稱",
            "路徑",
            "備註",
            "建立時間",
            "創建人員",
            "更新時間",
            "更新人員",
            "操作",
          ];
          this.keyGroup = [
            "id",
            "name",
            "path",
            "memo",
            "createTime",
            "createUser",
            "updateTime",
            "updateUser",
          ];
          this.selectItem = [
            { text: "名稱", value: "name" },
            { text: "啟用", value: "isEnable" },
          ];
          break;
        default:
          this.thead = [];
          this.keyGroup = [];
          this.data = [];
          break;
      }
      this.getListFunction(this.getListFunctionArray[this.selectedTabIndex]);
    },
    async getEObituaryMusics() {
      const res = await getEObituaryMusics({});
      this.eObituaryMusics = res.data.result;
    },
  },
  created() {
    this.role = localStorage.getItem("user");
    this.changeTab();
  },
  watch: {
    selectedTabIndex() {
      this.changeTab();
    },
  },
};
</script>
