<template>
  <div id="PrivacyAgreement">
    <v-app>
      <v-dialog persistent width="1000" v-model="dialog" hide-overlay>
        <v-card style="font-size: 18px" class="text-center px-5">
          <div style="font-size: 26px; font-weight: 900" class="py-4">免責聲明</div>
          <ul style="list-style-type: cjk-ideographic" class="text-left">
            <li>
              <p>
                本網站提供殯葬業務的服務，旨在慎終追遠。本公司力求提供完整且令人滿意的服務，歡迎使用本網站。然而，對於使用者自行瀏覽本網站而做出的錯誤判斷和行為，本網站概不負責。
              </p>
            </li>
            <li>
              <p>
                本網站提供喪葬禁忌和習俗的資訊，僅供參考。若使用者自行參考或不當引用，其後果與本網站無關。本網站不會承擔因網站內容錯誤或遺漏而導致的任何賠償責任。
              </p>
            </li>
            <li>
              <p>
                使用本網站不會造成系統故障或電腦病毒等損害。由於本網站是為大眾提供服務，因此不會侵犯版權或知識產權。對於使用者因與他人的觀點或風格不同而產生的利潤、商譽或其他無形損失，本網站概不負責，包括任何直接或間接、衍生性或懲罰性賠償。
              </p>
            </li>
            <li>
              <p>
                本網站保證不會將使用者的個人或公司資料提供給其他網站或公司。然而，在以下情況下，除外：法院或調查機關根據法律要求調查，或使用者違反本網站的使用條款導致本公司或他人受到損害時，為保護其他使用者或瀏覽者的權益而必要時，本網站可能會揭露違規使用者的信息。
              </p>
            </li>
            <li>
              本網站可以在不損及使用者權益的前提下隨時停止或變更網站的內容，而不需要提前通知。
            </li>
          </ul>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
export default {
  name: "PrivacyAgreement",
  components: {},
  data() {
    return {
      dialog: true,
    };
  },
  methods: {},
};
</script>
<style scoped lang="sass"></style>
