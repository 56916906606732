import http from "@/http/axios";
import { judgeError, queryFilter } from "./Common";
import { error, success } from "@/utils/notification";
import store from "../store";

export async function getSiteConfigs(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/SiteConfigs${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function createSiteConfigs(data) {
  const res = await http.post("/api/SiteConfigs", data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function updateSiteConfigs(id, data) {
  const res = await http.put(`/api/SiteConfigs/${id}`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function deleteSiteConfigs(id) {
  const res = await http.delete(`/api/SiteConfigs/${id}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}
