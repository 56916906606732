<template>
  <div id="OperatorManagement">
    <v-card class="ma-4">
      <TablePage
        :targetName="'operatorTable'"
        :selectData="selectItem"
        :thead="thead"
        :keyGroup="keyGroup"
        :data="data"
        @refresh="search"
        @search="search"
        @openDialog="openDialog"
        @clickUpdateBtn="openInformationDialog"
      />
      <v-pagination
        v-if="totalPage > 0"
        v-model="page"
        :length="totalPage"
        :total-visible="7"
        circle
        @input="changePage"
      ></v-pagination>
      <v-dialog
        v-model="informationDialog"
        width="90%"
        max-width="1200"
        @click:outside="closeInformationDialog"
        @keydown.esc="closeInformationDialog"
      >
        <v-card>
          <v-card-title>
            {{ dialogIndex == 0 ? "修改操作員" : "新增操作員" }}
          </v-card-title>
          <div class="pa-3">
            <OperatorDialog
              :refresh="informationDialog"
              :selectedData="selectedData"
              :mode="mode"
              @closeInformationDialog="closeInformationDialog"
              @modifyConfirmBtn="modifyConfirmBtn"
              @createConfirmBtn="createConfirmBtn"
            />
          </div>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import lodash from "lodash";
import OperatorDialog from "@/components/operatorManagement/OperatorDialog.vue";
import {
  getList,
  createOperators,
  updateOperators,
  updatePasswordOperators,
} from "@/apis/Operator";
import TablePage from "@/components/TablePage.vue";
export default {
  name: "OperatorManagement",
  components: {
    OperatorDialog,
    TablePage,
  },
  data() {
    return {
      params: {},
      page: 1,
      totalPage: 0,
      dialogIndex: 0,
      selectedData: {},
      selectItem: [
        { text: "ID", value: "id" },
        { text: "狀態", value: "status" },
      ],
      thead: ["編號", "名稱", "帳號", "狀態", "備註", "操作"],
      keyGroup: ["id", "name", "accountName", "status", "memo"],
      informationDialog: false,
      data: [],
      mode: "",
    };
  },
  methods: {
    // judge operator's status change to chinese
    filterJudge() {
      this.data.forEach((item, index) => {
        switch (item.status) {
          case 0:
            this.data[index].status = "等待審核";
            break;
          case 1:
            this.data[index].status = "正常";
            break;
          case 2:
            this.data[index].status = "等待";
            break;
          case 3:
            this.data[index].status = "鎖定";
            break;
          case 4:
            this.data[index].status = "禁用";
            break;
          case 5:
            this.data[index].status = "移除";
            break;
          default:
            this.data[index].status = "";
            break;
        }
      });
    },
    async search(enableBoolean, nowSelectItem, data) {
      this.params = {};
      if (data) {
        this.params[nowSelectItem] = data;
      }
      this.getOperator();
    },
    openDialog() {
      this.mode = "";
      this.dialogIndex = 1;
      this.informationDialog = true;
    },
    async modifyConfirmBtn() {
      this.$store.commit("setLoading", true);
      if (this.selectedData.oldPassword && this.selectedData.newPassword) {
        const data = {
          oldPassword: this.selectedData.oldPassword,
          newPassword: this.selectedData.newPassword,
        };
        await updatePasswordOperators(this.selectedData.id, data);
      }
      const data = {
        name: this.selectedData.name,
        accountName: this.selectedData.accountName,
        memo: this.selectedData.memo,
      };
      const res = await updateOperators(this.selectedData.id, data);
      if (res.status === 200) {
        this.getOperator();
        this.closeInformationDialog();
        this.selectedData = {};
        this.mode = "";
      }
      this.$store.commit("setLoading", false);
    },
    async createConfirmBtn() {
      this.$store.commit("setLoading", true);
      const data = {
        password: this.selectedData.password,
        name: this.selectedData.name,
        accountName: this.selectedData.accountName,
        status: this.selectedData.status,
        memo: this.selectedData.memo,
      };
      const res = await createOperators(data);
      if (res.status === 200) {
        this.getOperator();
        this.closeInformationDialog();
        this.selectedData = {};
      }
      this.$store.commit("setLoading", false);
    },
    openInformationDialog(index) {
      this.dialogIndex = 0;
      if (typeof index === "number") {
        this.selectedData = lodash.cloneDeep(this.data[index]);
        this.selectedData.password = "1";
      }
      this.mode = "modify";
      this.informationDialog = true;
    },
    closeInformationDialog() {
      this.selectedData = {};
      this.informationDialog = false;
    },
    async changePage() {
      this.params.page = this.page;
      this.getOperator();
    },
    async getOperator() {
      this.$store.commit("setLoading", true);
      const res = await getList(this.params);
      this.$store.commit("setLoading", false);
      if (res.status === 200) {
        this.data = res.data.result.results;
        this.filterJudge();
        this.totalPage = res.data.result.pageCount;
        this.page = res.data.result.currentPage;
      }
    },
  },
  created() {
    this.getOperator();
  },
};
</script>
