<template>
  <div id="ContractItem">
    <v-card>
      <v-card-title>
        {{ action === "update" ? "修改" : "創建" }}項目
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <div class="d-flex">
              <v-text-field
                class="mr-1"
                clearable
                label="項目名稱"
                hide-details
                v-model="data.name"
              ></v-text-field>
              <v-text-field
                clearable
                class="mr-1"
                label="單位"
                hide-details
                v-model="data.unit"
              ></v-text-field>
              <v-text-field
                clearable
                label="分類"
                hide-details
                v-model="data.category"
              ></v-text-field>
            </div>
            <v-textarea
              class="my-2"
              style="width: 100%"
              hide-details
              label="備註"
              v-model="data.memo"
            ></v-textarea>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="clickCloseBtn()">
          取消
        </v-btn>
        <v-btn
          :disabled="disable"
          color="green darken-1"
          text
          @click="clickConfirmBtn()"
        >
          確認
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ContractItem",
  props: ["action", "data", 'refresh'],
  data: () => ({
    rules: {
      required: (value) => !!value || "必填",
    },
  }),
  methods: {
    clickCloseBtn() {
      this.$emit("clickCloseBtn");
    },
    clickConfirmBtn() {
      this.$emit("clickConfirmBtn", this.data);
    },
  },
  computed: {
    disable() {
      return !this.data.name || !this.data.unit || !this.data.category;
    },
  },
  watch: {
    refresh(value) {
      if (this.action === 'create' && value) {
        this.data.category = '無';
      }
    }
  },
};
</script>
