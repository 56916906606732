<template>
  <div id="ContractSample">
    <v-card>
      <v-card-title>
        {{ action === "update" ? "修改" : "創建" }}範本
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-text-field
              clearable
              label="範本名稱"
              hide-details
              v-model="data.name"
            ></v-text-field>
            <v-textarea
              class="my-2"
              style="width: 100%"
              hide-details
              label="備註"
              v-model="data.memo"
            ></v-textarea>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="clickCloseBtn()">
          取消
        </v-btn>
        <v-btn
          :disabled="disable"
          color="green darken-1"
          text
          @click="clickConfirmBtn()"
        >
          確認
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ContractSample",
  props: ["action", "data"],
  data: () => ({
    rules: {
      required: (value) => !!value || "必填",
    },
  }),
  methods: {
    clickCloseBtn() {
      this.$emit("clickCloseBtn");
    },
    clickConfirmBtn() {
      this.$emit("clickConfirmBtn", this.data);
    },
  },
  computed: {
    disable() {
      return !this.data.name;
    },
  },
};
</script>
