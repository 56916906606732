import http from "@/http/axios";
import { judgeError, queryFilter } from "./Common";
import { error, success } from "@/utils/notification";

export async function getQAsList(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/QAs/List${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function createQAs(data) {
  const res = await http.post("/api/QAs", data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function disableQAs(id) {
  const res = await http.post(`/api/QAs/${id}/Disable`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function enableQAs(id) {
  const res = await http.post(`/api/QAs/${id}/Enable`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}
