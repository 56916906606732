import { errorString } from "@/utils/notification";

export function judgeError(res, isChangePassword) {
  if (!res) {
    return false;
  } else if (
    res.status === 401 &&
    res.data.error !== "bad_password" &&
    res.data.error !== "invalid_credentials" &&
    !isChangePassword
  ) {
    localStorage.clear();
    location.href = "/console/#/Login";
    return false;
  } else if ((res !== undefined && res.status === 200) || res.status === 204) {
    return true;
  } else if (res !== undefined && res.status === 403) {
    errorString("權限錯誤", "403", "請確定登入帳號的權限");
    return false;
  } else {
    return false;
  }
}

export function queryFilter(query) {
  if (!query) return;
  const arr = Object.keys(query);
  let result = "";
  arr.forEach((item) => {
    if (result === "") {
      result += "?";
    } else {
      result += "&";
    }
    result += `${item}=${query[item]}`;
  });
  return result;
}

export function filterError(error) {
  switch (error) {
    case "invalid_data":
      return "數據錯誤";
    case "captcha_required":
      return "需要驗證碼";
    case "invalid_captcha":
      return "驗證碼錯誤";
    case "invalid_credentials":
      return "認證錯誤";
    case "object_conflict":
      return "重複錯誤";
    case "service_not_ready":
      return "服務尚未準備好";
    default:
      return "錯誤";
  }
}

export function filterErrorMsg(errorMsg) {
  if (errorMsg === undefined) return "";
  const split = errorMsg.split(" ");
  let str = "";
  let i = 0;
  while (i < split.length) {
    if (split[i] === "can" && split[i + 1] === "not") {
      str += "不可以";
      i += 1;
    } else {
      str += switchErrorMsg(split[i]);
    }
    i += 1;
  }
  return str;
}

function switchErrorMsg(msg) {
  switch (msg) {
    case "on":
      return "在";
    case "target":
      return "目標";
    case "allowed":
      return "允許";
    case "ship":
      return "橋樑";
    case "keys":
      return "keys";
    case "relation":
      return "關係";
    case "invalid":
      return "無效";
    case "request":
      return "要求";
    case "data":
      return "數據";
    case "captcha":
      return "驗證碼";
    case "is":
      return "是";
    case "required":
      return "必須的";
    case "bad":
      return "錯誤的";
    case "username":
      return "帳號";
    case "or":
      return "或";
    case "password":
      return "密碼";
    case "name":
      return "名稱";
    case "account":
      return "帳號";
    case "already":
      return "已經";
    case "exists":
      return "存在";
    case "user":
      return "用戶的";
    case "can":
      return "可以";
    case "not":
      return "不";
    case "be":
      return "為";
    case "empty":
      return "空";
    case "obituary":
      return "訃聞";
    case "template":
      return "模板";
    case "music":
      return "音樂";
    case "need":
      return "需要";
    case "old":
      return "舊";
    case "wrong":
      return "錯誤的";
    case "mobile":
      return "手機";
    case "number":
      return "號碼";
    case "dealer":
      return "業者";
    case "contract":
      return "合約";
    case "sample":
      return "範本";
    case "total":
      return "總";
    case "amount":
      return "金額";
    case "customer":
      return "客戶";
    case "found":
      return "存在";
    case "gender":
      return "性別";
    case "burial":
      return "安葬";
    case "type":
      return "類別";
    case "flow":
      return "流程";
    case "item":
      return "項目";
    case "transaction":
      return "交易";
    case "error":
      return "錯誤";
    case "question":
      return "問題";
    case "sort":
      return "排序";
    default:
      return "";
  }
}
