<template>
  <div id="DealerFlow">
    <v-card>
      <v-card-title> {{ action === "update" ? "修改" : "創建" }}流程 </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-textarea
              class="my-2"
              style="width: 100%"
              hide-details
              label="備註"
              v-model="data.memo"
            ></v-textarea>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="clickCloseBtn()"> 取消 </v-btn>
        <v-btn color="green darken-1" text @click="clickConfirmBtn()"> 確認 </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DealerFlow",
  props: ["action", "data", "dealerData"],
  data: () => ({
    rules: {
      required: (value) => !!value || "必填",
    },
    dealerContractData: [],
  }),
  methods: {
    clickCloseBtn() {
      this.$emit("clickCloseBtn");
    },
    clickConfirmBtn() {
      this.data.isEnable = true;
      this.$emit("clickConfirmBtn", this.data);
    },
  },
};
</script>
