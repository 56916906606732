import http from "@/http/axios";
import { judgeError, queryFilter } from "./Common";
import { error } from "@/utils/notification";
import store from "../store";

export async function getSettings(query) {
  const queryString = queryFilter(query);
  const res = await http.get("/api/Settings");
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}
