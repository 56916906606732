<template>
  <div id="Login">
    <v-app>
      <v-dialog persistent width="500" v-model="dialog" hide-overlay id="dialog">
        <v-card style="height: 450px" class="d-flex justify-center align-center">
          <div class="d-flex flex-column text-center align-center py-5 px-3">
            <div class="login_text pb-5">黃思帶後台管理系統</div>
            <div class="d-flex my-2">
              <v-btn
                class="mr-2"
                :class="role === 'Operators' ? 'white--text' : ''"
                :color="role === 'Operators' ? 'blue' : '#fff'"
                depressed
                @click="role = 'Operators'"
                >操作員</v-btn
              >
              <v-btn
                :class="role === 'Dealers' ? 'white--text' : ''"
                :color="role === 'Dealers' ? 'blue' : '#fff'"
                depressed
                @click="role = 'Dealers'"
                >業者</v-btn
              >
            </div>
            <div>
              <v-text-field
                label="帳號"
                style="min-width: 250px"
                class="input_text v-label--active"
                id="account"
                value="das"
                v-model="account"
              ></v-text-field>
            </div>
            <div>
              <v-text-field
                label="密碼"
                id="password"
                :type="passwordShow ? 'text' : 'password'"
                :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="passwordShow = !passwordShow"
                style="min-width: 250px"
                class="input_text"
                v-model="password"
              ></v-text-field>
            </div>
            <div style="position: relative">
              <v-text-field
                label="驗證碼"
                style="min-width: 250px"
                class="input_text"
                v-model="code"
                v-on:keyup.enter="clickLogin"
              ></v-text-field>
              <img
                :src="captchaUrl"
                style="position: absolute; top: 6px; right: 5px; cursor: pointer"
                @click="refreshCaptcha()"
              />
            </div>
            <div>
              <v-btn color="primary" class="login_btn_text" @click="clickLogin()">登入</v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import Vue from "vue";
import login from "@/apis/Login";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      text: "",
      dialog: true,
      passwordShow: false,
      account: "",
      password: "",
      token: new Date().getTime(),
      code: "",
      role: "Operators",
      captchaUrl: "",
    };
  },
  methods: {
    refreshCaptcha() {
      this.code = "";
      this.token = new Date().getTime();
      this.captchaUrl = `${Vue.prototype.baseURL}Captcha?token=${this.token}`;
    },
    async clickLogin() {
      this.$store.commit("setLoading", true);
      this.text = "";
      const data = {
        accountName: this.account,
        password: this.password,
      };
      const res = await login(this.role, this.token, this.code, data);
      this.$store.commit("setLoading", false);
      if (res.status === 200) {
        localStorage.setItem("token", `Bearer ${res.data.result.token}`);
        localStorage.setItem("userId", res.data.result.userId);
        localStorage.setItem("user", this.role);
        setTimeout(() => {
          if (this.role === "Operators") {
            this.$router.push("OperatorManagement");
          } else {
            this.$router.push("CustomerManagement");
          }
        }, 500);
      } else {
        this.snackbar = true;
        this.snackbar_color = "red accent-2";
        if (res.data.errorMessage === "invalid request data") {
          this.text = "帳號密碼不得為空!";
        } else if (res.data.errorMessage === "bad captcha") {
          this.text = "驗證碼錯誤!";
        } else if (res.data.errorMessage === "captcha is required") {
          this.text = "驗證碼不得為空!";
        } else if (res.data.errorMessage === "invalid username or bad password") {
          this.text = "帳號或密碼錯誤!";
        } else {
          this.text = "登入失敗!";
        }
        this.refreshCaptcha();
      }
    },
  },
  created() {
    this.refreshCaptcha();
    this.$nextTick(() => {
      setTimeout(() => {
        if (document.querySelectorAll("input:-webkit-autofill").length > 0) {
          const accountLabel = document.querySelector("label[for=account]");
          accountLabel.classList.add("v-label--active");
          const passwordLabel = document.querySelector("label[for=password]");
          passwordLabel.classList.add("v-label--active");
        }
      }, 1000);
    });
  },
};
</script>
<style scoped lang="sass">
.login_text
  font-size: 1.5rem
  font-weight: 900
  text-shadow: 0 0 5px #aaa
.input_text
  font-size: .8rem
.login_btn_text
  font-size: 1rem !important
  font-weight: 900
</style>
