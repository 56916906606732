<template>
  <div class="vld-parent" id="LoadingPage">
    <loading color="#0ea5e9" :active="active" :is-full-page="fullPage" />
    <div style="position: fixed; left:0; top:0; z-index: 9999;width: 100%; height: 100%;"
      class="d-flex justify-center align-center">
      <v-btn style="margin-top: 120px" color="red" class="white--text"
        @click="cancelLoading()">取消</v-btn>
    </div>
  </div>
</template>

<script>
import http from '@/http/axios';
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: 'LoadingPage',
  data() {
    return {
      active: true,
      fullPage: true,
    };
  },
  components: {
    Loading,
  },
  methods: {
    async cancelLoading() {
      await http.cancel();
      this.$store.commit("setLoading", false);
    }
  },
};
</script>
