<template>
  <div id="PrivacyAgreement">
    <div class="main-wrap relative flex flex-col justify-center items-center space-y-6">
      <div class="wrap flex flex-col justify-center items-center space-y-6">
        <h1 class=" text-2xl font-bold mt-6">黃思帶 Bliss Support</h1>
        <div class="content flex justify-center items-center"><img src="images/240x240.png" alt=""></div>
        <div class="link flex items-center space-x-2 *:text-blue-600">
          <a href="https://apps.apple.com/hr/app/%E9%BB%83%E6%80%9D%E5%B8%B6/id1662198289">App Store</a>
          <div class="split inline-block w-[1px] h-[20px] border"></div>
          <a href="https://play.google.com/store/apps/details?id=com.yellowribbon.bliss&pcampaignid=web_share">Google
            Play</a>
        </div>
        <span>聯絡信箱：<a href="mailto:service@ywbliss.com">service@ywbliss.com</a></span>
        <span>手機號碼：0970-604323</span>
      </div>
    </div>
  </div>
</template>

<style>
.relative {
  position: relative;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.space-y-6> :not([hidden])~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem
      /* 24px */
      * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem
      /* 24px */
      * var(--tw-space-y-reverse));
}

.text-2xl {
  font-size: 1.5rem
    /* 24px */
  ;
  line-height: 2rem
    /* 32px */
  ;
}

.font-bold {
  font-weight: 700;
}

.mt-6 {
  margin-top: 1.5rem
    /* 24px */
  ;
}

.space-x-2> :not([hidden])~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem
      /* 8px */
      * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem
      /* 8px */
      * calc(1 - var(--tw-space-x-reverse)));
}

.\*\:text-blue-600>* {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.inline-block {
  display: inline-block;
}

.w-\[1px\] {
  width: 1px;
}

.h-\[20px\] {
  height: 20px;
}

.border {
  border-width: 1px;
}
</style>


<script>
export default {
  name: "Support",
  components: {},
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
  },
};
</script>
