import http from '@/http/axios';
import { judgeError } from './Common';
import { error } from '@/utils/notification';

export async function fileUpload(data) {
  const res = await http.post('/api/Files', data);
  if (!judgeError) {
    error(res.data.error, res.status, res.data.errorMessage);
  }
  return res;
}
