<template>
  <div id="OperatorManagement">
    <v-card class="ma-4">
      <TablePage
        :targetName="'advertiseTable'"
        :selectData="selectItem"
        :thead="thead"
        :keyGroup="keyGroup"
        :data="data"
        @refresh="search"
        @search="search"
        @openDialog="openDialog"
        @clickUpdateBtn="openInformationDialog"
        @changeisEnable="changeisEnable"
      />
      <v-pagination
        v-if="totalPage > 0"
        v-model="page"
        :length="totalPage"
        :total-visible="7"
        circle
        @input="changePage"
      ></v-pagination>
      <v-dialog
        v-model="informationDialog"
        width="90%"
        max-width="1200"
        @click:outside="closeInformationDialog"
        @keydown.esc="closeInformationDialog"
      >
        <v-card>
          <v-card-title>
            {{ dialogIndex == 0 ? "修改廣告" : "新增廣告" }}
          </v-card-title>
          <div class="pa-3">
            <AdvertiseDialog
              :informationDialog="informationDialog"
              :selectedData="selectedData"
              :mode="mode"
              @closeInformationDialog="closeInformationDialog"
              @modifyConfirmBtn="modifyConfirmBtn"
              @createConfirmBtn="createConfirmBtn"
            />
          </div>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import lodash from "lodash";
import AdvertiseDialog from "@/components/advertiseManagement/Dialog.vue";
import {
  getAdvertisesList,
  createAdvertises,
  updateAdvertises,
  disableAdvertises,
  enableAdvertises,
} from "@/apis/Advertises";
import TablePage from "@/components/TablePage.vue";
export default {
  name: "AdvertiseManagement",
  components: {
    AdvertiseDialog,
    TablePage,
  },
  data() {
    return {
      params: {},
      page: 1,
      totalPage: 0,
      dialogIndex: 0,
      selectedData: {},
      selectItem: [{ text: "啟用", value: "isEnable" }],
      thead: [
        "序號",
        "標題",
        "內文",
        "圖片",
        "連結",
        "開始時間",
        "結束時間",
        "建立時間",
        "創建人員",
        "操作",
      ],
      keyGroup: [
        "id",
        "title",
        "content",
        "picUrl",
        "url",
        "start",
        "end",
        "createTime",
        "createUser",
      ],
      informationDialog: false,
      data: [],
      mode: "",
    };
  },
  methods: {
    async search(enableBoolean, nowSelectItem, data) {
      this.params = {};
      if (data) {
        this.params[nowSelectItem] = data;
      }
      this.getAdvertise();
    },
    openDialog() {
      this.mode = "";
      this.dialogIndex = 1;
      this.informationDialog = true;
    },
    async changeisEnable(id, boolean) {
      if (boolean) {
        await enableAdvertises(id);
      } else {
        await disableAdvertises(id);
      }
      this.getAdvertise();
    },
    async modifyConfirmBtn() {
      this.$store.commit("setLoading", true);
      const res = await updateAdvertises(this.selectedData.id, this.selectedData);
      if (res.status === 200) {
        this.getAdvertise();
        this.closeInformationDialog();
        this.selectedData = {};
        this.mode = "";
      }
      this.$store.commit("setLoading", false);
    },
    async createConfirmBtn() {
      this.$store.commit("setLoading", true);
      this.selectedData.isEnable = true;
      const res = await createAdvertises(this.selectedData);
      if (res.status === 200) {
        this.getAdvertise();
        this.closeInformationDialog();
        this.selectedData = {};
      }
      this.$store.commit("setLoading", false);
    },
    openInformationDialog(index) {
      this.dialogIndex = 0;
      if (typeof index === "number") {
        this.selectedData = lodash.cloneDeep(this.data[index]);
        this.selectedData.password = "1";
      }
      this.mode = "modify";
      this.informationDialog = true;
    },
    closeInformationDialog() {
      this.selectedData = {};
      this.informationDialog = false;
    },
    async changePage() {
      this.params.page = this.page;
      this.getAdvertise();
    },
    async getAdvertise() {
      this.$store.commit("setLoading", true);
      const res = await getAdvertisesList(this.params);
      this.$store.commit("setLoading", false);
      if (res.status === 200) {
        this.data = res.data.result.results;
        this.totalPage = res.data.result.pageCount;
        this.page = res.data.result.currentPage;
      }
    },
  },
  created() {
    this.getAdvertise();
  },
};
</script>
