import http from "@/http/axios";
import axios from "axios";
import { judgeError, queryFilter } from "./Common";
import { error, success } from "@/utils/notification";

// Music
export async function getEObituaryMusicsList(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/EObituaryMusics/List${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function getEObituaryMusics(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/EObituaryMusics${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function addEObituaryMusics(data) {
  const res = await http.post("/api/EObituaryMusics", data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function updateEObituaryMusics(data) {
  const res = await http.put(`/api/EObituaryMusics/${data.id}`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function disableEObituaryMusics(id) {
  const res = await http.post(`/api/EObituaryMusics/${id}/Disable`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function enableEObituaryMusics(id) {
  const res = await http.post(`/api/EObituaryMusics/${id}/Enable`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

// Samples
export async function getEObituarySamplesList(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/EObituarySamples/List${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function getEObituarySamplesById(id) {
  const res = await http.get(`/api/EObituarySamples/${id}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function updateEObituarySamples(id, data) {
  const res = await http.put(`/api/EObituarySamples/${id}`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function getEObituarySamplesByDealerContractId(id) {
  const res = await http.get(`/api/EObituarySamples/${id}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function createEObituarySamples(id, data) {
  const res = await http.post(`/api/EObituarySamples/${id}`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function disableEObituarySamples(id) {
  const res = await http.post(`/api/EObituarySamples/${id}/Disable`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function enableEObituarySamples(id) {
  const res = await http.post(`/api/EObituarySamples/${id}/Enable`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

// Templates
export async function getEObituaryTemplatesList(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/EObituaryTemplates/List${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function getEObituaryTemplates() {
  const res = await http.get("/api/EObituaryTemplates/");
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function createEObituaryTemplatesByDealerId(data) {
  const res = await http.post(`/api/EObituaryTemplates?dealerId=${data.dealerId}`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function updateEObituaryTemplates(data) {
  const res = await http.put(`/api/EObituaryTemplates/${data.id}`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function disableEObituaryTemplates(id) {
  const res = await http.post(`/api/EObituaryTemplates/${id}/Disable`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function enableEObituaryTemplates(id) {
  const res = await http.post(`/api/EObituaryTemplates/${id}/Enable`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

// Eobituary main
export async function getEObituaryList(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/EObituaries/List${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function getEObituary(id) {
  const res = await http.get(`/api/EObituaries/${id}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function getEObituaryContract(dealerContractId) {
  const res = await http.get(`/api/EObituaries/${dealerContractId}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function addEObituary(dealerId, data) {
  const res = await http.post(`/api/EObituaries/${dealerId}`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function updateEObituary(id, data) {
  const res = await http.put(`/api/EObituaries/${id}`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function updateMediaEObituary(id, data) {
  const res = await http.put(`/api/EObituaries/${id}/Media`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function disableEObituary(id) {
  const res = await http.post(`/api/EObituaries/${id}/Disable`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function enableEObituary(id) {
  const res = await http.post(`/api/EObituaries/${id}/Enable`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function getEObituariesContract(id) {
  const res = await http.get(`/api/EObituaries/${id}/Contract`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}
