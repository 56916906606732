<template>
  <div id="OperatorDialog">
    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <div class="d-flex mb-2">
          <v-text-field
            clearable
            class="input px-2 py-1"
            label="標題"
            hide-details
            v-model="selectedData.title"
            maxlength="10"
          ></v-text-field>
        </div>
        <div class="d-flex mb-2 align-end">
          <div style="border: 1px solid #d2d2d2; border-radius: 5px">
            <label for="uploadFile">
              <div
                style="width: 367px; height: 100px; cursor: pointer"
                class="d-flex align-center text-center justify-center"
              >
                <div
                  v-if="picData.url === undefined"
                  class="primary--text"
                  style="font-size: 20px; font-weight: 600"
                >
                  <div class="d-flex align-center">
                    <v-icon class="primary--text">mdi-plus</v-icon>
                    新增圖片
                  </div>
                  <div class="red--text" style="font-size: 14px">圖片尺寸上限為:1100x300</div>
                </div>
                <img
                  :src="picData.url"
                  style="width: 100%; height: 100%; border-radius: 5px"
                  v-else
                />
                <input type="file" hidden id="uploadFile" @change="fileChange" />
              </div>
            </label>
          </div>
          <CroppiePhoto
            :isAdvertise="true"
            :isCroppie="isCroppie"
            :photoUrl="picData.url"
            @finishCropPhoto="finishCropPhoto"
            @cancelCrop="cancelCrop"
          />
          <!-- selectedData.picUrl -->
          <v-text-field
            clearable
            class="input px-2 py-1"
            label="連結"
            hide-details
            v-model="selectedData.url"
          ></v-text-field>
        </div>
        <div class="d-flex mb-2">
          <DateTimePicker
            class="mx-2 py-1"
            :hasSecond="true"
            :label="'開始時間'"
            :keyValue="'startTime'"
            :value="startTime"
            @confirmDateTime="confirmDateTime"
            style="width: 50%"
          />
          <DateTimePicker
            class="mx-2 py-1"
            :hasSecond="true"
            :label="'結束時間'"
            :keyValue="'endTime'"
            :value="endTime"
            @confirmDateTime="confirmDateTime"
            style="width: 50%"
          />
        </div>
        <v-textarea
          class="input px-2 py-1"
          hide-details
          label="內容"
          maxlength="15"
          style="width: 100%"
          v-model="selectedData.content"
        ></v-textarea>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
    <div class="d-flex justify-end pt-3">
      <v-btn color="error darken-1" text @click="closeInformationDialog()"> 取消 </v-btn>
      <v-btn color="green darken-1" text @click="modifyConfirmBtn()" :disabled="changeDisabled()">
        確認
      </v-btn>
    </div>
  </div>
</template>

<script>
import { userError } from "@/utils/notification";
import DateTimePicker from "../DateTimePicker.vue";
import { fileUpload } from "@/apis/FileUpload";
import CroppiePhoto from "../CroppiePhoto.vue";
export default {
  name: "OperatorDialog",
  components: {
    DateTimePicker,
    CroppiePhoto,
  },
  props: ["selectedData", "statusData", "mode", "informationDialog"],
  data: () => ({
    startTime: "",
    endTime: "",
    picData: {},
    isCroppie: false,
    rules: {
      required: (value) => !!value || "必填",
    },
    baseUrl: "",
  }),
  methods: {
    cancelCrop() {
      this.isCroppie = false;
      document.getElementById("uploadFile").value = "";
      this.picData = {};
    },
    async urltoFile(url, filename, mineType) {
      return await fetch(url)
        .then((res) => res.arrayBuffer())
        .then((buf) => new File([buf], filename, { type: mineType }));
    },
    async finishCropPhoto(url) {
      this.picData.url = url;
      const file = await this.urltoFile(url, this.picData.fileName, this.picData.mediaType);
      this.picData.data = file;
      this.isCroppie = false;
      this.$forceUpdate();
    },
    async uploadMedia() {
      let form = new FormData();
      form.append("file", this.picData.data);
      const res = await fileUpload(form);
      if (res.status === 200) {
        this.selectedData.picUrl = res.data.result.url;
      }
    },
    fileChange(event) {
      if (event.target.files && event.target.files[0]) {
        let image = new Image();
        const fileName = event.target.files[0].name;
        const reader = new FileReader();
        const type = event.target.files[0].type.split("/");
        reader.onload = async () => {
          if (reader !== null) {
            image.src = await reader.result;
            setTimeout(() => {
              if (event.target.files[0].size / 1000 >= 2000) {
                userError("圖片錯誤", "圖片大小不能超過2MB");
                document.getElementById("uploadFile").value = "";
              } else {
                this.picData = {
                  type: type[0],
                  mediaType: event.target.files[0].type,
                  url: reader.result,
                  fileName: fileName,
                  data: event.target.files[0],
                };
                this.isCroppie = true;
              }
            }, 500);
          }
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    },
    confirmDateTime(dateTime, type) {
      if (type === "startTime") {
        this.startTime = dateTime;
        this.selectedData.start = dateTime
          .replaceAll("-", "")
          .replaceAll(" ", "")
          .replaceAll(":", "");
      } else {
        this.endTime = dateTime;
        this.selectedData.end = dateTime
          .replaceAll("-", "")
          .replaceAll(" ", "")
          .replaceAll(":", "");
      }
      this.$forceUpdate();
    },
    closeInformationDialog() {
      this.$emit("closeInformationDialog");
    },
    async modifyConfirmBtn() {
      if (this.picData.data) {
        await this.uploadMedia();
      }
      if (this.mode === "modify") {
        this.$emit("modifyConfirmBtn");
      } else {
        if (!this.selectedData.content) {
          this.selectedData.content = "";
        }
        this.$emit("createConfirmBtn");
      }
      this.picData = {};
    },
    dateFilter(timestamp) {
      if (timestamp === undefined || timestamp <= 0) {
        return "";
      }
      const getTime = Math.floor(timestamp).toString().split("");
      const year = getTime.splice(0, 4).toString();
      const month = getTime.splice(0, 2).toString();
      const day = getTime.splice(0, 2).toString();
      const hour = getTime.splice(0, 2).toString();
      const minute = getTime.splice(0, 2).toString();
      const second = getTime.splice(0, 2).toString();
      return `${year}/${month}/${day} ${hour}:${minute}:${second}`.replaceAll(",", "");
    },
    changeDisabled() {
      return (
        !this.selectedData.title ||
        !this.selectedData.start ||
        !this.selectedData.end ||
        !this.picData.url ||
        !this.selectedData.url
      );
    },
  },
  mounted() {
    this.baseUrl = this.$store.state.StorageDomain;
    if (this.mode === "modify") {
      this.startTime = this.dateFilter(this.selectedData.start);
      this.endTime = this.dateFilter(this.selectedData.end);
      this.picData["url"] = this.baseUrl + this.selectedData.picUrl;
      this.$forceUpdate();
    }
  },
  watch: {
    informationDialog() {
      if (this.informationDialog && this.mode === "modify") {
        this.startTime = this.dateFilter(this.selectedData.start);
        this.endTime = this.dateFilter(this.selectedData.end);
        this.picData["url"] = this.baseUrl + this.selectedData.picUrl;
        this.$forceUpdate();
      } else {
        this.picData = {};
      }
      if (!this.informationDialog) {
        this.startTime = "";
        this.endTime = "";
      }
    },
  },
};
</script>
