<template>
  <div id="DealerCustomers">
    <v-card>
      <v-card-title> {{ action === "update" ? "修改" : "創建" }}客戶 </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <div class="d-flex">
              <v-text-field
                class="mr-1"
                clearable
                label="名稱"
                hide-details
                v-model="data.name"
              ></v-text-field>
              <v-text-field
                :disabled="action === 'update'"
                clearable
                class="mr-1"
                label="手機號碼 (請確保手機號碼正確!!)"
                hide-details
                color="red"
                v-model="data.mobileNumber"
              ></v-text-field>
              <v-select
                v-if="action === 'update'"
                :items="statusData"
                item-text="name"
                item-value="value"
                hide-details
                label="狀態"
                v-model="data.status"
                @change="changeStatus()"
              ></v-select>
            </div>
            <v-textarea
              class="input my-2"
              hide-details
              label="備註"
              style="width: 100%"
              v-model="data.memo"
            ></v-textarea>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <div class="d-flex justify-end pt-3">
          <v-btn color="error darken-1" text @click="clickCloseBtn()"> 取消 </v-btn>
          <v-btn :disabled="disable" color="green darken-1" text @click="clickConfirmBtn()">
            確認
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DealerCustomers",
  props: ["data", "mode", "action"],
  data: () => ({
    rules: {
      required: (value) => !!value || "必填",
    },
    statusData: [
      {
        name: "等待審核",
        value: 0,
      },
      {
        name: "正常",
        value: 1,
      },
      {
        name: "停用",
        value: 2,
      },
      {
        name: "禁用",
        value: 3,
      },
    ],
  }),
  methods: {
    changeStatus() {
      this.data.isChangeStatus = true;
    },
    clickCloseBtn() {
      this.$emit("clickCloseBtn");
    },
    clickConfirmBtn() {
      this.$emit("clickConfirmBtn", this.data);
    },
  },
  computed: {
    disable() {
      return !this.data.name || !this.data.mobileNumber;
    },
  },
};
</script>
