<template>
  <div id="ContactInfo">
    <div class="d-flex justify-end">
      <v-btn
        color="primary"
        large
        class="create_industryBtn"
        @click="addContactInfo()"
      >
        <v-icon class="mr-2">mdi-plus</v-icon>
        新增聯絡方式
      </v-btn>
    </div>
    <v-simple-table>
      <!-- 要可以排序 -->
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-left"
              v-for="(th, thIndex) in theadData"
              :key="thIndex"
            >
              {{ th }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in contactInfos" :key="index" v-show="contactInfos.length > 0">
            <td>{{ item.id }}</td>
            <td>{{ item.type | contactType }}</td>
            <td>{{ item.content }}</td>
            <td>
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="openUpdate(item)" icon color="primary" v-bind="attrs"
                    v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>編輯</span>
              </v-tooltip>
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="error" @click="removeItem(item)" v-bind="attrs"
                    v-on="on">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>刪除</span>
              </v-tooltip>
            </td>
          </tr>
          <tr v-show="contactInfos.length <= 0" class="text-center">
            <td colspan="4">無資料</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="d-flex justify-end pt-3">
      <v-btn color="error darken-1" text @click="closeDialog()">
        取消
      </v-btn>
    </div>
    <v-dialog v-model="dialog" width="90%" max-width="1200"
      @click:outside="closeContractDialog"
      @keydown.esc="closeContractDialog">
      <v-card>
        <v-card-title> 新增聯絡方式 </v-card-title>
        <div class="pa-3">
          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="10">
              <div class="d-flex mb-2">
                <v-select
                  :items="contactTypes"
                  class="mr-1"
                  item-text="name"
                  item-value="value"
                  label="類型"
                  hide-details
                  v-model="data.type"
                ></v-select>
                <v-text-field
                  clearable
                  label="內容"
                  hide-details
                  v-model="data.content"
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="1"></v-col>
          </v-row>
          <div class="d-flex justify-end pt-3">
            <v-btn color="error darken-1" text @click="closeContractDialog()">
              取消
            </v-btn>
            <v-btn color="green darken-1" text @click="addContactAction()"
              :disabled="disable">
              確認
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { getContactInfo, deleteDealersContactInfos } from "@/apis/Dealers";
export default {
  name: "ContactInfo",
  props: ["selectedData", "contactInfos"],
  data: () => ({
    theadData: ["序號", "類別", "內容", "操作"],
    contactTypes: [
      {
        name: "手機",
        value: 0,
      },
      {
        name: "市話",
        value: 1,
      },
      {
        name: "傳真",
        value: 2,
      },
      {
        name: "地址",
        value: 3,
      },
    ],
    dialog: false,
    data: {
      type: 0,
    },
  }),
  methods: {
    async removeItem(data) {
      await deleteDealersContactInfos(data.id);
      this.getDealerContactInfo();
    },
    openUpdate(data) {
      this.data = _.cloneDeep(data);
      this.dialog = true;
    },
    closeDialog() {
      this.$emit("closeContractDialog");
    },
    async getDealerContactInfo() {
      this.$emit("getDealerContactInfo");
    },
    closeContractDialog() {
      this.data = {};
      this.dialog = false;
    },
    addContactInfo() {
      this.dialog = true;
    },
    async addContactAction() {
      if (this.data.id === undefined) {
        const data = {
          dealerId: this.selectedData.id,
          type: Number(this.data.type),
          content: this.data.content,
        };
        await this.$emit("addContactAction", data);
      } else {
        this.data.type = Number(this.data.type);
        await this.$emit("updateContactAction", this.data);
      }
      this.data = {
        type: 0,
      };
      this.dialog = false;
      setTimeout(() => {
        this.getDealerContactInfo();
      }, 500);
    },
  },
  created() {},
  computed: {
    disable() {
      return !this.data.content || this.data.type === undefined;
    },
  }
};
</script>
