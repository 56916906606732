<template>
  <div id="IndustryManagement">
    <v-card class="ma-4">
      <div class="d-flex pa-3">
        <v-tabs v-model="selectedTabIndex">
          <v-tab v-for="(tab, tabIndex) in tabList" :key="tabIndex" v-show="tab.value !== ''">
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </div>
    </v-card>
    <v-card class="ma-4">
      <TablePage
        :targetName="targetName"
        :selectedTabIndex="selectedTabIndex"
        :selectedButtonIndex="selectedButtonIndex"
        :buttonGroup="buttonGroup"
        :selectData="selectItem"
        :thead="thead"
        :keyGroup="keyGroup"
        :data="data"
        :haveDetail="haveDetail"
        @clickUpdateBtn="clickUpdateBtn"
        @changeisEnable="changeisEnable"
        @search="search"
        @openDialog="openDialog"
        @changeButtonGroup="changeButtonGroup"
        @clickDetail="clickDetail"
      />
      <v-pagination
        v-if="totalPage > 0"
        v-model="page"
        :length="totalPage"
        :total-visible="7"
        circle
        @input="changePage"
      ></v-pagination>
      <v-dialog
        v-model="dialog"
        width="90%"
        max-width="1200"
        scrollable
        @click:outside="clickCloseBtn"
        @keydown.esc="clickCloseBtn"
      >
        <component
          :is="this.createBtn.value"
          :action="action"
          :refresh="dialog"
          :hasData="allData"
          :data="selectedData"
          @clickCloseBtn="clickCloseBtn"
          @clickConfirmBtn="clickConfirmBtn"
          @clickImportBtn="clickImportBtn"
        ></component>
      </v-dialog>
    </v-card>
    <v-dialog
      v-model="contractDetailDialog"
      width="90%"
      max-width="1200"
      scrollable
      @click:outside="closeDetailDialog"
      @keydown.esc="closeDetailDialog"
    >
      <v-card>
        <v-card-title> {{ detailTitle }} </v-card-title>
        <v-card-text class="px-3">
          <Detail
            :detailThead="detailThead"
            :refresh="contractDetailDialog"
            :detailData="detailData"
            :title="detailTitle"
            :selectedTabIndex="selectedTabIndex"
            :thead="detailThead"
            :selectedData="selectedData"
            :data="detailData"
            :detailKey="detailKey"
            :contractSampleData="contractSampleData"
            @createDetail="createDetail"
            @clickDetail="clickDetail"
            @closeDetailDialog="closeDetailDialog"
            @closeContractDialog="closeContractDialog"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TablePage from "@/components/TablePage.vue";
import ContractSamples from "@/components/industryManagement/DealerContractSamples.vue";
import ContractItem from "@/components/industryManagement/DealerContractItem.vue";
import Detail from "@/components/Detail.vue";
import DealerFlowSample from "@/components/industryManagement/DealerFlowSample";
import DealerFlowItems from "@/components/industryManagement/DealerFlowItems";
import DealerFlowMaterials from "@/components/industryManagement/DealerFlowMaterials";
import DealerPersonnel from "@/components/industryManagement/DealerPersonnel.vue";
import {
  getDealerContractItemsList,
  createDealerContractItems,
  updateDealerContractItems,
  enableDealerContractItems,
  disableDealerContractItems,
} from "@/apis/DealerContractItems";
import {
  getDealerContractSamplesList,
  createDealerContractSamples,
  updateDealerContractSamples,
  disableDealerContractSamples,
  enableDealerContractSamples,
  getDealerContractSamplesDetail,
  createDealerContractSamplesDetail,
  dealerContractSamplesImport,
} from "@/apis/DealerContractSamples";
import {
  getDealerFlowSampleList,
  createDealerFlowSample,
  updateDealerFlowSample,
  disableDealerFlowSample,
  enableDealerFlowSample,
  createDetailDealerFlowSample,
  getDetailDealerFlowSample,
  dealerFlowSampleImport,
} from "@/apis/DealerFlowSample";
import {
  getDealerFlowItemsList,
  createDealerFlowItems,
  updateDealerFlowItems,
  disableDealerFlowItems,
  enableDealerFlowItems,
} from "@/apis/DealerFlowItems";
import {
  getDealerFlowMaterialsList,
  createDealerFlowMaterials,
  updateDealerFlowMaterials,
  disableDealerFlowMaterials,
  enableDealerFlowMaterials,
} from "@/apis/DealerFlowMaterials";
import {
  getDealerPersonnelList,
  createDealerPersonnel,
  updateDealerPersonnel,
  enableDealerPersonnel,
  disableDealerPersonnel,
} from "@/apis/DealerPersonnel";

export default {
  name: "IndustryManagement",
  components: {
    TablePage,
    ContractSamples,
    ContractItem,
    Detail,
    DealerFlowSample,
    DealerFlowItems,
    DealerFlowMaterials,
    DealerPersonnel,
  },
  data() {
    return {
      targetName: "",
      isEnabledFunctionArray: [
        [
          [enableDealerContractSamples, disableDealerContractSamples],
          [enableDealerContractItems, disableDealerContractItems],
        ],
        [
          [enableDealerFlowSample, disableDealerFlowSample],
          [enableDealerFlowItems, disableDealerFlowItems],
          [enableDealerFlowMaterials, disableDealerFlowMaterials],
        ],
        [[enableDealerPersonnel, disableDealerPersonnel]],
      ],
      createUpdateFunctionArray: [
        [
          [createDealerContractSamples, updateDealerContractSamples],
          [createDealerContractItems, updateDealerContractItems],
        ],
        [
          [createDealerFlowSample, updateDealerFlowSample],
          [createDealerFlowItems, updateDealerFlowItems],
          [createDealerFlowMaterials, updateDealerFlowMaterials],
        ],
        [[createDealerPersonnel, updateDealerPersonnel]],
      ],
      importFunctionArray: [dealerContractSamplesImport, dealerFlowSampleImport],
      getListFunctionArray: [
        [getDealerContractSamplesList, getDealerContractItemsList],
        [getDealerFlowSampleList, getDealerFlowItemsList, getDealerFlowMaterialsList],
        [getDealerPersonnelList],
      ],
      createDetailFunctionArray: [
        createDealerContractSamplesDetail,
        createDetailDealerFlowSample,
        new Function(),
      ],
      allData: [],
      params: {},
      contractSampleData: [],
      detailTitle: "",
      detailKey: [],
      detailThead: [],
      detailData: [],
      contractDetailDialog: false,
      createBtn: {
        name: "",
        value: "",
      },
      thead: [],
      keyGroup: [],
      selectItem: [],
      buttonGroup: [],
      haveDetail: false,
      selectedData: {},
      informationDialog: false,
      data: [],
      contractDialog: false,
      tabList: [
        {
          name: "合約",
          value: "Contract",
        },
        {
          name: "流程",
          value: "DealerFlow",
        },
        {
          name: "角色",
          value: "DealerPersonnel",
        },
      ],
      selectedButtonIndex: 0,
      selectedTabIndex: 0,
      dialog: false,
      action: "",
      totalPage: 0,
      page: 0,
      dealerId: 0,
    };
  },
  methods: {
    closeContractDialog() {
      this.contractDialog = false;
    },
    openContractDialog(data) {
      this.selectedData = data;
      this.contractDialog = true;
    },
    closeDetailDialog() {
      this.selectedData = {};
      this.detailData = [];
      this.contractDetailDialog = false;
    },
    async createDetail(data) {
      this.$store.commit("setLoading", true);
      await this.createDetailFunctionArray[this.selectedTabIndex](
        this.selectedData.dealerId,
        this.selectedData.id,
        data
      );
      this.$store.commit("setLoading", false);
      this.clickDetail({
        id: this.selectedData.id,
        dealerId: this.selectedData.dealerId,
      });
    },
    async clickImportBtn(data) {
      let datas = data;
      datas.dealerId = this.dealerId;
      const res = await this.importFunctionArray[this.selectedTabIndex](datas);
      if (res.status === 200) {
        this.clickCloseBtn();
        this.callGetFunction(
          this.getListFunctionArray[this.selectedTabIndex][this.selectedButtonIndex]
        );
      }
    },
    clickDetail(data) {
      switch (this.selectedTabIndex) {
        case 0:
          this.clickContractDetail(data);
          break;
        case 1:
          this.clickFlowDetail(data);
          break;
        default:
          break;
      }
    },
    async clickFlowDetail(data) {
      this.$store.commit("setLoading", true);
      if (this.selectedButtonIndex === 0) {
        this.detailThead = ["名稱", "參與者", "準備", "備註"];
        this.detailKey = ["flowItemName", "participants", "prepared", "memo"];
        this.detailTitle = "流程範本細項";
        this.selectedData = data;
        const res = await getDetailDealerFlowSample(this.dealerId, data.id);
        if (res.status === 200) {
          this.detailData = res.data.result;
          this.contractDetailDialog = true;
        }
      } else {
        this.detailThead = [];
        this.detailKey = [];
        this.detailTitle = "";
        this.selectedData = {};
        this.detailData = [];
        this.contractDetailDialog = false;
      }
      this.$store.commit("setLoading", false);
    },
    async clickContractDetail(data) {
      if (this.selectedButtonIndex === 0) {
        this.detailThead = ["合約項目名稱", "單位", "數量", "金額"];
        this.detailKey = ["contractItemName", "unit", "quantity", "unitAmount"];
        this.detailTitle = "合約範本細項";
        this.selectedData = data;
        const res = await getDealerContractSamplesDetail(this.dealerId, data.id);
        if (res.status === 200) {
          this.detailData = res.data.result;
          this.contractDetailDialog = true;
        }
      } else {
        this.detailThead = [];
        this.detailKey = [];
        this.detailTitle = "";
        this.selectedData = {};
        this.detailData = [];
        this.contractDetailDialog = false;
      }
    },
    async changePage() {
      this.params.page = this.page;
      this.callGetFunction(
        this.getListFunctionArray[this.selectedTabIndex][this.selectedButtonIndex]
      );
    },
    async haveSelectedTab(reserve) {
      this.createBtn = this.tabList[this.selectedTabIndex];
      this.buttonGroup = [];
      this.selectedButtonIndex = 0;
      this.params = { delaerId: this.dealerId };
      if (this.selectedTabIndex === 2) {
        this.targetName = "dealerCharacterTable";
        this.haveDetail = false;
        this.selectItem = [
          { text: "名稱", value: "name" },
          { text: "開啟", value: "isEnable" },
        ];
        this.thead = [
          "編號",
          "業者編號",
          "名稱",
          "開啟",
          "是否為範本",
          "備註",
          "建立時間",
          "創建人員",
          "更新時間",
          "更新人員",
          "操作",
        ];
        this.keyGroup = [
          "id",
          "dealerId",
          "name",
          "isEnable",
          "isFromSample",
          "memo",
          "createTime",
          "createUser",
          "updateTime",
          "updateUser",
        ];
        this.callGetFunction(
          this.getListFunctionArray[this.selectedTabIndex][this.selectedButtonIndex]
        );
      } else {
        this.haveButtonGroup(reserve);
      }
    },
    async clickConfirmBtn(data) {
      if (this.action === "create" && data.length === undefined) {
        data.isEnable = true;
        data.dealerId = this.dealerId;
      }
      const action = this.action === "create" ? 0 : 1;
      if (data.length === undefined) {
        await this.createUpdateFunctionArray[this.selectedTabIndex][this.selectedButtonIndex][
          action
        ](data);
      } else {
        for (const index in data) {
          data[index].dealerId = this.dealerId;
          await this.createUpdateFunctionArray[this.selectedTabIndex][this.selectedButtonIndex][
            action
          ](data[index]);
        }
      }
      this.clickCloseBtn();
      this.callGetFunction(
        this.getListFunctionArray[this.selectedTabIndex][this.selectedButtonIndex]
      );
    },
    clickCloseBtn() {
      this.dialog = false;
      this.action = "";
      this.selectedData = {};
    },
    async search(enableBoolean, nowSelectItem, data) {
      this.params = { delaerId: this.dealerId };
      if (data) {
        this.params[nowSelectItem] = data;
      }
      this.callGetFunction(
        this.getListFunctionArray[this.selectedTabIndex][this.selectedButtonIndex]
      );
    },
    clickUpdateBtn(index) {
      this.openDialog("update", index);
    },
    openDialog(action, index) {
      this.action = action;
      this.dialog = true;
      if (typeof index === "number") {
        this.selectedData = this.data[index];
      } else {
        this.selectedData = {};
      }
      if ((this.selectedTabIndex === 2 || this.selectedButtonIndex !== 0) && action !== "update") {
        this.getAllDetailData();
      }
    },
    async getAllDetailData() {
      const res = await this.getListFunctionArray[this.selectedTabIndex][this.selectedButtonIndex]({
        pageSize: 100,
        delaerId: this.dealerId,
        isFromSample: true,
      });
      this.allData = res.data.result.results;
    },
    async changeisEnable(id, boolean) {
      await this.isEnabledFunctionArray[this.selectedTabIndex][this.selectedButtonIndex][
        Number(!boolean)
      ](this.dealerId, id);
      this.callGetFunction(
        this.getListFunctionArray[this.selectedTabIndex][this.selectedButtonIndex]
      );
    },
    // judge tab is have button
    haveButtonGroup(reserve) {
      if (!reserve) this.selectedButtonIndex = 0;
      const AllButtonGroup = [
        [
          {
            name: "範本",
            value: "ContractSamples",
          },
          {
            name: "項目",
            value: "ContractItem",
          },
        ],
        [
          {
            name: "範本",
            value: "DealerFlowSample",
          },
          {
            name: "項目",
            value: "DealerFlowItems",
          },
          {
            name: "物品",
            value: "DealerFlowMaterials",
          },
        ],
        [],
      ];
      this.buttonGroup = AllButtonGroup[this.selectedTabIndex];
      if (this.buttonGroup.length > 0) {
        this.changeButtonGroup(this.selectedButtonIndex);
      }
    },
    // when change button, get corresponding thead and data.
    async changeButtonGroup(index) {
      this.params = { dealerId: this.dealerId };
      this.selectedButtonIndex = index;
      this.createBtn = this.buttonGroup[index];
      if (this.selectedTabIndex === 0) {
        switch (this.selectedButtonIndex) {
          case 0: {
            this.targetName = "dealerContractSampleTable";
            this.haveDetail = true;
            this.selectItem = [
              { text: "Id", value: "id" },
              { text: "名稱", value: "name" },
              { text: "開啟", value: "isEnable" },
            ];
            this.thead = [
              "編號",
              "業者編號",
              "合約範本名稱",
              "備註",
              "建立時間",
              "創建人員",
              "更新時間",
              "更新人員",
              "操作",
            ];
            this.keyGroup = [
              "id",
              "dealerId",
              "name",
              "memo",
              "createTime",
              "createUser",
              "updateTime",
              "updateUser",
            ];
            break;
          }
          case 1: {
            this.targetName = "dealerContractItemTable";
            this.haveDetail = false;
            this.selectItem = [
              { text: "名稱", value: "name" },
              { text: "分類", value: "category" },
              { text: "開啟", value: "isEnable" },
              { text: "來自範本", value: "isFromSample" },
            ];
            this.thead = [
              "編號",
              "業者編號",
              "合約項目名稱",
              "分類",
              "單位",
              "備註",
              "建立時間",
              "創建人員",
              "更新時間",
              "更新人員",
              "操作",
            ];
            this.keyGroup = [
              "id",
              "dealerId",
              "name",
              "category",
              "unit",
              "memo",
              "createTime",
              "createUser",
              "updateTime",
              "updateUser",
            ];
            break;
          }
          default:
            this.thead = [];
            this.keyGroup = [];
            this.data = [];
            break;
        }
      } else if (this.selectedTabIndex === 1) {
        switch (this.selectedButtonIndex) {
          case 0: {
            this.targetName = "dealerFlowSampleTable";
            this.haveDetail = true;
            this.selectItem = [
              { text: "名稱", value: "name" },
              { text: "開啟", value: "isEnable" },
            ];
            this.thead = [
              "編號",
              "業者編號",
              "名稱",
              "開啟",
              "備註",
              "建立時間",
              "創建人員",
              "更新時間",
              "更新人員",
              "操作",
            ];
            this.keyGroup = [
              "id",
              "dealerId",
              "name",
              "isEnable",
              "memo",
              "createTime",
              "createUser",
              "updateTime",
              "updateUser",
            ];
            break;
          }
          case 1: {
            this.targetName = "dealerItemSampleTable";
            this.haveDetail = false;
            this.selectItem = [
              { text: "名稱", value: "name" },
              { text: "開啟", value: "isEnable" },
            ];
            this.thead = [
              "編號",
              "業者編號",
              "名稱",
              "開啟",
              "是否為範本",
              "備註",
              "建立時間",
              "創建人員",
              "更新時間",
              "更新人員",
              "操作",
            ];
            this.keyGroup = [
              "id",
              "dealerId",
              "name",
              "isEnable",
              "isFromSample",
              "memo",
              "createTime",
              "createUser",
              "updateTime",
              "updateUser",
            ];
            break;
          }
          case 2: {
            this.targetName = "dealerFlowMaterialTable";
            this.haveDetail = false;
            this.selectItem = [
              { text: "名稱", value: "name" },
              { text: "開啟", value: "isEnable" },
              { text: "分類", value: "category" },
            ];
            this.thead = [
              "編號",
              "業者編號",
              "名稱",
              "分類",
              "開啟",
              "是否為範本",
              "備註",
              "建立時間",
              "創建人員",
              "更新時間",
              "更新人員",
              "操作",
            ];
            this.keyGroup = [
              "id",
              "dealerId",
              "name",
              "category",
              "isEnable",
              "isFromSample",
              "memo",
              "createTime",
              "createUser",
              "updateTime",
              "updateUser",
            ];
            break;
          }
          default:
            this.thead = [];
            this.keyGroup = [];
            this.data = [];
            break;
        }
      }
      this.callGetFunction(
        this.getListFunctionArray[this.selectedTabIndex][this.selectedButtonIndex]
      );
    },
    async callGetFunction(doFunction) {
      this.$store.commit("setLoading", true);
      const res = await doFunction(this.params);
      if (res.status === 200) {
        this.totalPage = res.data.result.pageCount;
        this.page = res.data.result.currentPage;
        this.data = res.data.result.results;
      }
      this.$store.commit("setLoading", false);
    },
  },
  async created() {
    this.dealerId = localStorage.getItem("userId");
    this.params = { dealerId: this.dealerId };
    this.haveSelectedTab();
  },
  watch: {
    selectedTabIndex() {
      this.haveSelectedTab();
    },
  },
};
</script>
