<template>
  <div>
    <notifications group="foo" />
    <router-view v-if="currentRouter !== 'Template' && currentRouter !== 'PrivacyAgreement'" />
    <!-- <v-app v-else-if="currentRouter === 'Template'">
      <EObituaryTemplate />
    </v-app> -->
    <v-app v-else>
      <PrivacyAgreement />
    </v-app>
  </div>
</template>

<script>
// import EObituaryTemplate from "./views/EObituaryTemplate.vue";
import PrivacyAgreement from "./views/PrivacyAgreement.vue";
export default {
  name: "App",
  components: {
    // EObituaryTemplate,
    PrivacyAgreement,
  },
  data: () => ({
    currentRouter: "",
  }),
  methods: {
    judgeCurrentRouter() {
      this.currentRouter = this.$router.currentRoute.params.page;
    },
  },
  created() {
    this.judgeCurrentRouter();
  },
};
</script>
<style lang="sass">
@font-face
  font-family: "customFont"
  src: local("customFont"), url("./assets/fonts/font.ttf"), format('truetype')

#EObituaryTemplate1,
#EObituaryTemplate2
  font-family: 'customFont'

#app
  font-family: 'customFont'
  font-size: 62.5%
  .v-card, .search
    border-radius: .5rem !important
    box-shadow: 0px 0px 8px #0000001A !important
  .v-tab
    font-size: 1rem !important
  #Login
    .input
      border-radius: .5rem !important
      box-shadow: 0px 0px 8px #0000001a !important
      width: 50%
  @media screen and (min-width:1264px)
    th,
    .text_rwd
      font-size: 1rem !important
    td
      font-size: .8rem !important
  @media screen and (min-width: 960px) and (max-width: 1263px)
    th,
    .text_rwd
      font-size: .8rem !important
    td
      font-size: .7rem !important
  @media screen and (max-width: 959px)
    table
      // width: 1000px
      white-space: nowrap
      max-width: 1000px !important
  .tab_rwd
    overflow-x: auto !important
</style>
