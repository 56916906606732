<template>
  <div id="QAManagement">
    <v-card class="ma-4">
      <TablePage
        :targetName="'qaTable'"
        :selectData="selectItem"
        :thead="thead"
        :keyGroup="keyGroup"
        :data="data"
        :isQaManagement="true"
        @refresh="search"
        @search="search"
        @openDialog="openDialog"
        @clickUpdateBtn="openInformationDialog"
        @changeisEnable="changeisEnable"
        @clickDetail="clickDetail"
      />
      <v-pagination
        v-if="totalPage > 0"
        v-model="page"
        :length="totalPage"
        :total-visible="7"
        circle
        @input="changePage"
      ></v-pagination>
      <v-dialog
        v-model="informationDialog"
        width="90%"
        max-width="1200"
        @click:outside="closeInformationDialog"
        @keydown.esc="closeInformationDialog"
      >
        <v-card>
          <v-card-title>
            {{ dialogIndex == 0 ? "修改QA" : "新增QA" }}
          </v-card-title>
          <div class="pa-3">
            <QADialog
              :selectedData="selectedData"
              :mode="mode"
              @closeInformationDialog="closeInformationDialog"
              @modifyConfirmBtn="modifyConfirmBtn"
              @createConfirmBtn="createConfirmBtn"
            />
          </div>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="isDetail"
        width="90%"
        max-width="1200"
        scrollable
        @click:outside="closeDetailDialog"
        @keydown.esc="closeDetailDialog"
      >
        <v-card>
          <v-card-title> 排序 </v-card-title>
          <v-card-text class="px-3">
            <Detail
              :thead="thead"
              :isOnlySort="true"
              :refresh="isDetail"
              :detailData="detailData"
              detailThead="thead"
              :detailKey="keyGroup"
              @closeDetailDialog="closeDetailDialog"
              @createDetail="createDetail"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import lodash from "lodash";
import QADialog from "@/components/qaManagement/Dialog.vue";
import { getQAsList, createQAs, updateQAs, disableQAs, enableQAs } from "@/apis/QAs";
import TablePage from "@/components/TablePage.vue";
import Detail from "../components/Detail.vue";

export default {
  name: "QAManagement",
  components: {
    QADialog,
    TablePage,
    Detail,
  },
  data() {
    return {
      params: {},
      page: 1,
      totalPage: 0,
      dialogIndex: 0,
      selectedData: {},
      selectItem: [
        { text: "排序", value: "isOrderByAsc" },
        { text: "啟用", value: "isEnable" },
      ],
      thead: ["序號", "問題", "答案", "排序", "建立時間", "創建人員", "操作"],
      keyGroup: ["id", "question", "answer", "sort", "createTime", "createUser"],
      informationDialog: false,
      data: [],
      mode: "",
      isDetail: false,
      detailData: [],
    };
  },
  methods: {
    async createDetail(data) {
      this.$store.commit("setLoading", true);
      const getAllData = await this.getAllData();
      getAllData.forEach((item, index) => {
        data.forEach((value) => {
          if (value.id === item.id) {
            getAllData[index].sort = value.sort;
          }
        });
      });
      const datas = getAllData;
      const res = await createQAs(datas);
      if (res.status === 200) {
        this.getQAs();
        this.closeDetailDialog();
      }
      this.$store.commit("setLoading", false);
    },
    closeDetailDialog() {
      this.isDetail = false;
    },
    async clickDetail() {
      const res = await getQAsList({ pageSize: 100, isEnable: true, isOrderByAsc: true });
      this.detailData = res.data.result.results;
      this.isDetail = true;
    },
    async search(enableBoolean, nowSelectItem, data) {
      this.params = {};
      if (data) {
        this.params[nowSelectItem] = data;
      }
      this.getQAs();
    },
    openDialog() {
      this.mode = "";
      this.dialogIndex = 1;
      this.informationDialog = true;
    },
    async changeisEnable(id, boolean) {
      if (boolean) {
        await enableQAs(id);
      } else {
        await disableQAs(id);
      }
      this.getQAs();
    },
    async modifyConfirmBtn() {
      this.$store.commit("setLoading", true);
      const getAllData = await this.getAllData();
      getAllData.forEach((item, index) => {
        if (item.id === this.selectedData.id) {
          getAllData[index] = this.selectedData;
        }
      });
      const datas = getAllData;
      const res = await createQAs(datas);
      if (res.status === 200) {
        this.getQAs();
        this.closeInformationDialog();
        this.selectedData = {};
      }
      this.$store.commit("setLoading", false);
    },
    async getAllData() {
      const getOpenList = await getQAsList({ pageSize: 100 });
      const list = getOpenList.data.result.results;
      return list;
    },
    async createConfirmBtn() {
      this.$store.commit("setLoading", true);
      this.selectedData.isEnable = true;
      const getAllData = await this.getAllData();
      const datas = [...getAllData, this.selectedData];
      const res = await createQAs(datas);
      if (res.status === 200) {
        this.getQAs();
        this.closeInformationDialog();
        this.selectedData = {};
      }
      this.$store.commit("setLoading", false);
    },
    openInformationDialog(index) {
      this.dialogIndex = 0;
      if (typeof index === "number") {
        this.selectedData = lodash.cloneDeep(this.data[index]);
      }
      this.mode = "modify";
      this.informationDialog = true;
    },
    closeInformationDialog() {
      this.selectedData = {};
      this.informationDialog = false;
    },
    async changePage() {
      this.params.page = this.page;
      this.getQAs();
    },
    async getQAs() {
      if (this.params["isEnable"] === undefined) {
        this.params["isEnable"] = true;
      }
      this.$store.commit("setLoading", true);
      const res = await getQAsList(this.params);
      this.$store.commit("setLoading", false);
      if (res.status === 200) {
        this.data = res.data.result.results;
        this.totalPage = res.data.result.pageCount;
        this.page = res.data.result.currentPage;
      }
    },
  },
  created() {
    this.getQAs();
  },
};
</script>
