<template>
  <div id="DealerManagement">
    <!--
      <v-card class="ma-4">
      <div class="d-flex pa-3">
        <v-tabs v-model="selectedTabIndex">
          <v-tab
            v-for="(tab, tabIndex) in tabList"
            :key="tabIndex"
            v-show="tab.value !== ''"
          >
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </div>
    </v-card>
    -->
    <v-card class="ma-4">
      <TablePage
        :targetName="'dealerTable'"
        :selectData="selectItem"
        :thead="thead"
        :loginDealer="true"
        :keyGroup="keyGroup"
        :data="data"
        :haveDetail="haveDetail"
        :haveContact="haveContact"
        @clickUpdateBtn="clickUpdateBtn"
        @search="search"
        @openDialog="openDialog"
        @clickDetail="clickDetail"
        @clickChangeDealer="clickChangeDealer"
      />
      <v-pagination
        v-if="totalPage > 0"
        v-model="page"
        :length="totalPage"
        :total-visible="7"
        circle
        @input="changePage"
      ></v-pagination>
      <v-dialog
        v-model="dialog"
        width="90%"
        max-width="1200"
        scrollable
        @click:outside="clickCloseBtn"
        @keydown.esc="clickCloseBtn"
      >
        <component
          :is="this.createBtn.value"
          :action="action"
          :refresh="dialog"
          :hasData="data"
          :data="selectedData"
          @clickCloseBtn="clickCloseBtn"
          @clickConfirmBtn="clickConfirmBtn"
        ></component>
      </v-dialog>
    </v-card>
    <v-dialog
      v-model="contractDialog"
      width="90%"
      max-width="1200"
      scrollable
      @click:outside="closeContractDialog"
      @keydown.esc="closeContractDialog"
    >
      <v-card>
        <v-card-title> 聯絡方式 </v-card-title>
        <div class="pa-3">
          <ContactInfo
            :selectedData="selectedData"
            :contactInfos="contactInfos"
            @getDealerContactInfo="getDealerContactInfo"
            @closeContractDialog="closeContractDialog"
            @addContactAction="addContactAction"
            @updateContactAction="updateContactAction"
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ModifyPage from "@/components/dealerManagement/ModifyPage.vue";
import ContactInfo from "@/components/dealerManagement/ContactInfo.vue";
import TablePage from "@/components/TablePage.vue";
import {
  getList,
  createDealers,
  updateDealers,
  creatDealersContactInfos,
  updateDealersContactInfos,
  getContactInfo,
  updatePasswordDealers,
  updateDealerStatus,
} from "@/apis/Dealers";
import { forceLoginDealer } from "@/apis/Operator";

export default {
  name: "DealerManagement",
  components: {
    ModifyPage,
    ContactInfo,
    TablePage,
  },
  data() {
    return {
      params: {},
      haveContact: false,
      createBtn: {
        name: "",
        value: "",
      },
      thead: [],
      keyGroup: [],
      selectItem: [],
      haveDetail: false,
      selectedData: {},
      informationDialog: false,
      data: [],
      contractDialog: false,
      tabList: [
        {
          name: "業者",
          value: "ModifyPage",
        },
      ],
      selectedTabIndex: 0,
      dialog: false,
      action: "",
      totalPage: 0,
      page: 0,
      contactInfos: [],
      dealerId: 0,
    };
  },
  methods: {
    async clickChangeDealer(data) {
      const res = await forceLoginDealer(data.id);
      if (res.status === 200) {
        localStorage.clear();
        localStorage.setItem("user", "Dealers");
        localStorage.setItem("userId", res.data.result.userId);
        localStorage.setItem("token", `Bearer ${res.data.result.token}`);
        this.$router.push("CustomerManagement");
        this.$emit("reset");
      }
    },
    clickDetail(data) {
      this.selectedData = data;
      this.getDealerContactInfo();
      this.contractDialog = true;
    },
    async getDealerContactInfo() {
      const res = await getContactInfo(this.selectedData.id);
      this.contactInfos = res.data.result;
    },
    async changePage() {
      this.params.page = this.page;
      this.getDealersList();
    },
    async haveSelectedTab() {
      this.params = {};
      this.createBtn = this.tabList[this.selectedTabIndex];
      this.selectItem = [
        { text: "編號", value: "id" },
        { text: "狀態", value: "status" },
      ];
      this.thead = ["編號", "帳號", "名稱", "統一編號", "狀態", "備註", "操作"];
      this.keyGroup = ["id", "accountName", "name", "uniformNumbers", "status_revise", "memo"];
      this.getDealersList();
      this.haveDetail = false;
      this.haveContact = true;
    },
    async clickConfirmBtn(data) {
      const functionArr = [createDealers, updateDealers];
      const index = this.action === "create" ? 0 : 1;
      if (index === 1 && data.newPassword) {
        const passwordGroup = {
          newPassword: data.newPassword,
        };
        await updatePasswordDealers(data.id, passwordGroup);
      }
      const res = await functionArr[index](data);
      if (res.status === 200) {
        await updateDealerStatus(res.data.result.id, data.status);
        this.clickCloseBtn();
        this.getDealersList();
      }
    },
    clickCloseBtn() {
      this.dialog = false;
      this.action = "";
      this.selectedData = {};
    },
    async addContactAction(data) {
      this.$store.commit("setLoading", true);
      const res = await creatDealersContactInfos(data.dealerId, data);
      this.$store.commit("setLoading", false);
    },
    async updateContactAction(data) {
      this.$store.commit("setLoading", true);
      await updateDealersContactInfos(data.dealerId, data.id, data);
      this.$store.commit("setLoading", false);
    },
    // status filter for dealer
    dealerStatusFilter(status) {
      switch (status) {
        case 0:
          return "等待審核";
        case 1:
          return "正常";
        case 2:
          return "停用";
        case 3:
          return "禁用";
        default:
          return "";
      }
    },
    async getDealersList() {
      this.$store.commit("setLoading", true);
      const res = await getList(this.params);
      this.totalPage = res.data.result.pageCount;
      this.page = res.data.result.currentPage;
      this.data = res.data.result.results;
      this.data.forEach((item, index) => {
        this.data[index].status_revise = this.dealerStatusFilter(item.status);
      });
      this.$store.commit("setLoading", false);
    },
    closeContractDialog() {
      this.contractDialog = false;
    },
    openContractDialog(data) {
      this.selectedData = data;
      this.contractDialog = true;
    },
    async search(enableBoolean, nowSelectItem, data) {
      this.params = {};
      if (data) {
        this.params[nowSelectItem] = data;
      }
      this.getDealersList();
    },
    clickUpdateBtn(index) {
      this.openDialog("update", index);
    },
    openDialog(action, index) {
      this.action = action;
      this.dialog = true;
      if (typeof index === "number") {
        this.selectedData = this.data[index];
        this.selectedData.password = "1";
      } else {
        this.selectedData = {};
      }
    },
  },
  created() {
    this.haveSelectedTab();
  },
};
</script>
