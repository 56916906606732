<template>
  <div id="CreateFlowDetail">
    <CheckGroup
      :thead="thead"
      :haveFlow="true"
      :listData="listData"
      :refresh="refresh"
      :mode="mode"
      :sentListData="sentListData"
      :haveDate="haveDate"
      @searchFeature="getContractItemData"
      @judgeMode="judgeMode"
      @sentToList="sentToList"
      @cancelSentToList="cancelSentToList"
      @sentConfirm="clickConfirmBtn"
      @clickCloseDetailBtn="clickCloseDetailBtn"
    />
  </div>
</template>

<script>
import CheckGroup from "../CheckGroup.vue";
import { getFlowItems } from "@/apis/FlowItems";
export default {
  name: "CreateFlowDetail",
  props: ["action", "data", "refresh", "selectedSampleDetailId", "detailData"],
  components: {
    CheckGroup,
  },
  data: () => ({
    haveDate: false,
    thead: ["項目名稱", "操作"],
    listData: [],
    detailData_copy: [],
    sentListData: [],
    mode: "",
    rules: {
      required: (value) => !!value || "必填",
    },
  }),
  methods: {
    judgehaveChecked(isSearch, datas) {
      if (datas) {
        this.detailData_copy = [];
        datas.forEach((item, index) => {
          this.detailData_copy.push({
            flowItemName: item.name,
            flowSampleId: this.selectedSampleDetailId,
            participants: item.participants,
            prepared: item.prepared,
            sort: index + 1,
          });
        });
      }
      this.detailData_copy.forEach((item) => {
        // let boolean = false;
        this.listData.forEach((value, index) => {
          if (item.flowItemName === value.name || item.dealerFlowItemName === value.name) {
            this.listData[index].checked = true;
            this.listData[index].participants = item.participants;
            this.listData[index].prepared = item.prepared;
            if (item.memo) {
              this.listData[index].memoSample = item.memo;
            }
            if (item.date) {
              this.listData[index].date = item.date;
            }
            if (item.time) {
              this.listData[index].time = item.time;
            }
            this.listData[index].sort = item.sort;
            // boolean = true;
          }
        });
        // if (isSearch && !boolean) {
        //   this.listData.push({
        //     dealerId: item.dealerId,
        //     name: item.dealerFlowItemName || item.flowItemName,
        //     flowSampleId: item.flowSampleId,
        //     id: item.id,
        //     participants: item.participants,
        //     prepared: item.prepared,
        //     sort: item.sort,
        //     checked: true,
        //     date: item.date,
        //     time: item.time,
        //   });
        // }
      });
    },
    // judge mode is right to left or left to right
    judgeMode(value, selectedList) {
      if (selectedList.length > 0) {
        this.mode = value;
      } else {
        this.mode = "";
      }
    },
    // right to left button (add item to list)
    sentToList(selectedList) {
      this.sentListData.push(...selectedList);
      this.listData = this.listData.filter((item) => !selectedList.includes(item));
      this.mode = "";
    },
    // left to right button (remove item to list)
    cancelSentToList(selectedList) {
      this.listData.push(...selectedList);
      this.sentListData = this.sentListData.filter((item) => !selectedList.includes(item));
      this.mode = "";
    },
    clickCloseDetailBtn() {
      this.mode = "";
      this.sentListData = [];
      this.$emit("clickCloseDetailBtn");
    },
    // judge selected tab for return data
    judgeData(datas) {
      let data = [];
      const sortData = datas.sort((a, b) => {
        if (a.sort === undefined) return 1;
        return a.sort > b.sort ? 1 : -1;
      });
      sortData.forEach((item, index) => {
        data.push({
          flowItemName: item.name,
          participants: item.participants,
          flowSampleId: this.selectedSampleDetailId,
          prepared: item.prepared,
          memo: item.memoSample,
          sort: index + 1,
        });
        if (item.date) {
          data[index].date = item.date;
        }
        if (item.time) {
          data[index].time = item.time;
        }
      });
      return data;
    },
    clickConfirmBtn(datas) {
      const data = this.judgeData(datas);
      this.$emit("createDetail", data);
      this.clickCloseDetailBtn();
    },
    // get system's contract item data
    async getContractItemData(value, datas) {
      this.listData = [];
      let query = {};
      if (value) {
        query.name = value;
      }
      const { data } = await getFlowItems(query);
      this.listData = data.result;
      this.judgehaveChecked(value === "" || value === undefined ? false : true, datas);
    },
  },
  created() {
    if (this.$router.currentRoute.params.page !== "TemplateManagement") {
      this.haveDate = true;
    }
    this.detailData_copy = this.detailData;
    this.getContractItemData();
  },
  watch: {
    // when open dialog, need to get contract item data
    refresh(value) {
      if (value) {
        if (this.$router.currentRoute.params.page !== "TemplateManagement") {
          this.haveDate = true;
        }
        this.detailData_copy = this.detailData;
        this.getContractItemData();
      }
    },
  },
  computed: {
    disable() {
      return this.sentListData.length <= 0;
    },
  },
};
</script>
