import http from '@/http/axios';
import { judgeError, queryFilter } from './Common';
import { error, success } from '@/utils/notification';

export async function getContractItemsList(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/ContractItems/List${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  }
  return res;
}

export async function getContractItemsSingle(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/ContractItems${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  }
  return res;
}

export async function createContractItems(data) {
  const res = await http.post('/api/ContractItems', data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  } else {
    success();
  }
  return res;
}

export async function updateContractItems(data) {
  const res = await http.put(`/api/ContractItems/${data.id}`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  } else {
    success();
  }
  return res;
}

export async function disableContractItems(id) {
  const res = await http.post(`/api/ContractItems/${id}/Disable`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  } else {
    success();
  }
  return res;
}

export async function enableContractItems(id) {
  const res = await http.post(`/api/ContractItems/${id}/Enable`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  } else {
    success();
  }
  return res;
}
