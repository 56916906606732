<template>
  <div id="Calendar">
    <v-card class="ma-4 pa-2">
      <v-row no-gutters>
        <v-col
          cols="9"
          style="overflow-x: auto"
          class="mr-2"
          :style="`height:${height}px`"
          v-if="isCalendar"
        >
          <FullCalendar ref="calendar" :options="calendarOptions" />
        </v-col>
        <v-col style="overflow-y: auto" :style="`height:${height}px`">
          <FullCalendar ref="list" :options="listOptions" />
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="detailDialog"
      width="90%"
      max-width="1200"
      @click:outside="clickCloseBtn"
      @keydown.esc="clickCloseBtn"
    >
      <v-card>
        <v-card-title> 所有流程 </v-card-title>
        <div class="pa-3">
          <v-row
            no-gutters
            style="font-size: 16px; font-weight: 600; border-bottom: 1px solid #999"
            class="text-center py-1"
          >
            <v-col v-for="(item, index) in detailThead" :key="index">
              {{ item }}
            </v-col>
          </v-row>
          <v-row
            class="text-center py-1 d-flex align-center"
            style="font-size: 12px"
            no-gutters
            v-for="(item, index) in dialogData"
            :key="index"
          >
            <v-col>{{ item.subject }}</v-col>
            <v-col>{{ item.dateTime | dateTimeFilter }}</v-col>
            <v-col>
              <v-btn small color="primary" class="mr-2">業者資訊</v-btn>
              <v-btn small color="warning" @click="openDetailDialog('flow', index)">業者流程</v-btn>
            </v-col>
          </v-row>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="clickCloseBtn()"> 取消 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isflowDialog"
      width="90%"
      max-width="1200"
      @click:outside="clickCloseBtn"
      @keydown.esc="clickCloseBtn"
    >
      <v-card v-if="dialogData">
        <v-card-title> 流程細項 </v-card-title>
        <div class="pa-3">
          <v-row
            no-gutters
            style="font-size: 17px; font-weight: 600; border-bottom: 1px solid #999"
            class="text-center py-1"
          >
            <v-col v-for="(item, index) in thead" :key="index">
              {{ item }}
            </v-col>
          </v-row>
          <v-row
            class="text-center py-1 d-flex align-center"
            style="font-size: 15px"
            no-gutters
            v-for="(item, index) in dialogData"
            :key="index"
          >
            <v-col>{{ item.dealerFlowDetail.dealerFlowItemName }}</v-col>
            <v-col>{{ item.dealerFlowDetail.dateTime | dateTimeFilter }}</v-col>
            <v-col>{{ item.dealerFlowDetail.participants }}</v-col>
            <v-col>{{ item.dealerFlowDetail.prepared }}</v-col>
            <v-col>{{ item.dealerFlowDetail.place || "" }}</v-col>
            <v-col>{{ item.dealerFlowDetail.hasNotification ? "有" : "無" }}</v-col>
            <v-col>{{ item.dealerFlowDetail.memo || "" }}</v-col>
          </v-row>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="clickCloseBtn()"> 取消 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import zhTwLocale from "@fullcalendar/core/locales/zh-tw";
import { getDealerSchedules } from "@/apis/Dealers";
import { calendar } from "js-calendar-converter/calendar";

export default {
  name: "Calendar",
  components: {
    FullCalendar,
  },
  data() {
    return {
      height: 0,
      detailThead: ["名稱", "時間", "操作"],
      thead: ["名稱", "時間", "參與者", "準備", "地點", "是否有通知", "備註"],
      isflowDialog: false,
      listOptions: {
        displayEventTime: false,
        plugins: [dayGridPlugin, interactionPlugin, listPlugin],
        locale: zhTwLocale,
        eventTimeFormat: {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        },
        headerToolbar: {
          left: "",
          center: "",
          right: "today prev,next",
        },
        customButtons: {
          prev: {
            icon: "chevron-left",
            click: async () => {
              this.$store.commit("setLoading", true);
              if (this.isCalendar) {
                this.$refs.calendar.getApi().prev();
              }
              this.$refs.list.getApi().prev();
              this.getnewDate();
              await this.getSchedules();
              this.addButtonForGrid();
              this.$store.commit("setLoading", false);
            },
          },
          next: {
            icon: "chevron-right",
            click: async () => {
              this.$store.commit("setLoading", true);
              if (this.isCalendar) {
                this.$refs.calendar.getApi().next();
              }
              this.$refs.list.getApi().next();
              this.getnewDate();
              await this.getSchedules();
              this.addButtonForGrid();
              this.$store.commit("setLoading", false);
            },
          },
          today: {
            text: "今天",
            click: async () => {
              this.$store.commit("setLoading", true);
              if (this.isCalendar) {
                this.$refs.calendar.getApi().today();
              }
              this.$refs.list.getApi().today();
              this.getnewDate();
              await this.getSchedules();
              this.addButtonForGrid();
              this.$store.commit("setLoading", false);
            },
          },
          myCustomButton: {
            text: "custom!",
            click: function () {
              alert("clicked the custom button!");
            },
          },
        },
        initialView: "listMonth",
        handleWindowResize: false,
        eventClick: this.clickEvent, // click event
        windowResize: this.windowResize,
        height: "100%",
        events: [],
      },
      calendarOptions: {
        displayEventTime: false,
        plugins: [dayGridPlugin, interactionPlugin, listPlugin],
        views: {
          dayGridMonth: {
            dayCellContent(item) {
              let mark = sessionStorage.getItem("joinholiday");
              let _date = new Date(item.date);
              let _dateF = calendar.solar2lunar(
                _date.getFullYear(),
                _date.getMonth() + 1,
                _date.getDate()
              );
              if (item.dayNumberText == mark + "日") {
                return {
                  html: `<p id='selectedHolidy'><label>${_dateF.cDay}日</label></p><p style="color:#999;position:absolute;font-size: 16px;margin-top: 15px;"><label>${_dateF.IDayCn}</label></p>`,
                };
              }
              return {
                html: `<p><label>${_dateF.cDay}日</label></p><p style="color:#999;position:absolute;font-size: 16px;margin-top: 15px;"><label>${_dateF.IDayCn}</label></p>`,
              };
            },
          },
        },
        locale: zhTwLocale,
        headerToolbar: {
          left: "title",
          center: "",
          right: "",
        },
        eventTimeFormat: {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        },
        windowResizeDelay: 500,
        initialView: "dayGridMonth",
        handleWindowResize: true,
        eventClick: this.clickEvent, // click event
        windowResize: this.windowResize,
        height: "100%",
        aspectRatio: 2,
        events: [],
      },
      tabList: [
        {
          name: "行事曆",
          value: "DealerCustomers",
        },
        {
          name: "列表",
          value: "DealerCustomers",
        },
      ],
      selectedTabIndex: 0,
      request: { pageSize: 100, start: "", end: "" },
      dialogData: [],
      selectedDetailIndex: -1,
      detailDialog: false,
      specialList: [],
      isCalendar: true,
    };
  },
  methods: {
    getnewDate() {
      let date = null;
      if (this.isCalendar) {
        date = new Date(this.$refs.calendar.getApi().currentData.currentDate);
      } else {
        date = new Date(this.$refs.list.getApi().currentData.currentDate);
      }
      this.request.start = `${date.getFullYear()}-${
        date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-01`;
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.request.end = `${date.getFullYear()}-${
        date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${lastDay.getDate()}`;
    },
    clickCloseBtn() {
      this.isflowDialog = false;
    },
    openDetailDialog(type, index) {
      this.selectedDetailIndex = index;
      this.isflowDialog = true;
    },
    listMonthColorFilter() {
      const table = document.querySelector(".fc-list-table");
      if (table) {
        const lists = table.querySelectorAll(".fc-list-event");
        lists.forEach((item, index) => {
          const className = item.getAttribute("class");
          const color = `${className.split(" ").pop() === "red--text" ? "#F44336" : "#3F51B5"}`;
          lists[index]
            .querySelector(".fc-list-event-title")
            .querySelector("a")
            .setAttribute("style", `color: ${color} !important;`);
        });
      }
    },
    filterSchedulesLists(data) {
      const list = [];
      this.listOptions.events = [];
      let copyList = {};
      data.forEach((item) => {
        const calc = Math.trunc(item.dateTime / 1000000);
        let date = cloneDeep(item.dateTime.toString().split(""));
        let dateTime = `${date.splice(0, 4)}-${date.splice(0, 2)}-${date.splice(0, 2)}`;
        const getTime = `${date.splice(0, 2)}:${date.splice(0, 2)}:${date.splice(0, 2)}`;
        dateTime = `${dateTime} ${getTime}`;
        this.listOptions.events.push({
          title: `${item.dealerContract.name} ${item.subject} ${getTime
            .replaceAll(",", "")
            .split("")
            .splice(0, 5)
            .toString()
            .replaceAll(",", "")}`,
          date: dateTime.replaceAll(",", ""),
          classNames: item.dealerContract.gender === 1 ? "indigo--text" : "red--text",
        });
        if (copyList[calc] === undefined) {
          copyList[calc] = [];
        }
        copyList[calc].push({
          title: `${item.dealerContract.name} ${item.subject} ${getTime
            .replaceAll(",", "")
            .split("")
            .splice(0, 5)
            .toString()
            .replaceAll(",", "")}`,
          date: dateTime.replaceAll(",", ""),
          gender: item.dealerContract.gender,
          classNames: item.dealerContract.gender === 1 ? "indigo--text" : "red--text",
        });
      });
      const arr = Object.keys(copyList);
      arr.forEach((item) => {
        if (copyList[item].length <= 3) {
          copyList[item].forEach((value) => {
            list.push(value);
          });
          delete copyList[item];
        }
      });
      this.specialList = copyList;
      return list;
    },
    async getSchedules() {
      const res = await getDealerSchedules(localStorage.getItem("userId"), this.request);
      if (res.status === 200) {
        this.calendarOptions.events = this.filterSchedulesLists(res.data.result);
      }
    },
    async openDialog(date) {
      this.isflowDialog = true;
      const request = { pageSize: 100, start: date + " 00:00:00", end: date + " 23:59:59" };
      const res = await getDealerSchedules(localStorage.getItem("userId"), request);
      this.dialogData = res.data.result;
    },
    clickEvent(e) {
      const getDate = new Date(e.event.start);
      const date = `${getDate.getFullYear()}-${
        getDate.getMonth() + 1 >= 10 ? getDate.getMonth() + 1 : "0" + (getDate.getMonth() + 1)
      }-${getDate.getDate() > 9 ? getDate.getDate() : "0" + getDate.getDate()}`;
      this.openDialog(date);
    },
    windowResize() {
      this.height = window.innerHeight - 120;
      this.addButtonForGrid();
      this.$forceUpdate();
    },
    async addButtonForGrid() {
      const grids = document.querySelectorAll(".fc-daygrid-day-frame");
      for await (const item of grids) {
        const target = item.querySelector(".fc-daygrid-day-events");
        // specialList
        const dateRevise = cloneDeep(
          item.querySelector(".fc-daygrid-day-top").querySelector("a").getAttribute("aria-label")
        );
        let reviseData = dateRevise.replace("年", "-").replace("月", "-").replace("日", "");
        reviseData = reviseData.split("-");
        reviseData.forEach((item, index) => {
          if (index !== 0 && item.split("").length !== 2) {
            reviseData[index] = `0${item}`;
          }
        });
        reviseData = reviseData.toString().replaceAll(",", "");
        if (this.specialList[reviseData] !== undefined) {
          let htmlStr = "";
          for (let i = 0; i < 3; i++) {
            const data = this.specialList[reviseData][i];
            let timeStr = "";
            let time = data.date.split(" ")[1];
            time = time.split(":");
            timeStr = `${time[0]}:${time[1]}`;
            htmlStr += `<div class="fc-daygrid-event-harness" style="margin-top: 0px;"><a class="fc-daygrid-event fc-daygrid-dot-event fc-event fc-event-start fc-event-end fc-event-future" tabindex="0"><div class="fc-daygrid-event-dot"></div><div class="fc-event-time"></div><div class="fc-event-title ${
              data.gender === 1 ? "indigo--text" : "red--text"
            }">${data.title}</div></a></div>`;
          }
          target.innerHTML =
            htmlStr +
            `<div><button onclick='windowAddClickTag("${dateRevise
              .replace("年", "-")
              .replace("月", "-")
              .replace(
                "日",
                ""
              )}")' class='px-2' style='background-color: #3788d8;color:#fff;width:100%;height:35px'">查看更多</button></div>`;
        }
      }
      window.windowAddClickTag = (date) => {
        this.openDialog(date);
      };
    },
    resize() {
      if (document.getElementById("Calendar") !== null) {
        if (window.innerWidth <= 600) {
          this.isCalendar = false;
          this.listOptions.headerToolbar.left = "title";
        } else {
          this.isCalendar = true;
          this.listOptions.headerToolbar.left = "";
        }
      }
    },
  },
  async mounted() {
    this.resize();
    window.addEventListener("resize", () => {
      this.resize();
    });
    this.$store.commit("setLoading", true);
    this.height = window.innerHeight - 120;
    const date = new Date();
    this.request.start = `${date.getFullYear()}-${
      date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-01`;
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.request.end = `${date.getFullYear()}-${
      date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${lastDay.getDate()}`;
    await this.getSchedules();
    await this.addButtonForGrid();
    await this.listMonthColorFilter();
    this.$store.commit("setLoading", false);
  },
  watch: {},
};
</script>
<style>
.v-application a {
  color: #000 !important;
}
.fc tbody .fc-daygrid-day .fc-daygrid-day-frame .fc-daygrid-day-events {
  margin: 0;
  font-weight: 900;
}
</style>
