<template>
  <div id="ContractDialog">
    <v-card>
      <v-card-title> {{ action === "update" ? "修改" : "創建" }}合約 </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <div class="d-flex mb-2">
              <v-text-field
                clearable
                label="往生名稱"
                hide-details
                class="mr-1"
                v-model="data.name"
              ></v-text-field>
              <v-select
                :items="genderData"
                item-text="name"
                item-value="value"
                class="mr-1"
                hide-details
                label="性別"
                v-model="data.gender"
              ></v-select>
              <v-text-field
                clearable
                label="稱謂"
                hide-details
                class="mr-1"
                v-model="data.title"
              ></v-text-field>
              <v-select
                :items="burialTypeData"
                item-text="name"
                item-value="value"
                hide-details
                label="安葬方式"
                v-model="data.burialType"
              ></v-select>
            </div>
            <div class="d-flex mb-2">
              <DateTimePicker
                :label="'過世日期'"
                :value="date"
                @confirmDateTime="confirmDateTime"
                :keyValue="'date'"
                class="mr-1"
              />
              <DateTimePicker
                :label="'過世日期(農曆)'"
                :value="dateLunar"
                @confirmDateTime="confirmDateTime"
                :keyValue="'dateLunar'"
                class="mr-1"
              />
              <v-text-field
                clearable
                class="mr-1"
                label="地址"
                hide-details
                v-model="data.address"
              ></v-text-field>
              <v-text-field
                clearable
                label="告別式地點"
                hide-details
                v-model="data.memorialCeremonyAddress"
              ></v-text-field>
            </div>
            <div class="d-flex mb-2">
              <v-text-field
                clearable
                class="mr-1"
                label="停靈地點"
                hide-details
                v-model="data.morgueAddress"
              ></v-text-field>
              <v-select
                v-if="action === 'update'"
                class="mr-1"
                hide-details
                v-model="data.status"
                :items="statusData"
                item-text="name"
                item-value="value"
                label="狀態"
                @change="changeStatus()"
              >
              </v-select>
              <!-- include customer name -->
              <v-text-field
                label="客戶"
                hide-details
                class="mr-1"
                readonly
                style="cursor: pointer"
                v-model="data.customerName"
                @click="openCustomerDialog"
              ></v-text-field>
              <v-text-field
                clearable
                label="電話"
                hide-details
                class="mr-1"
                v-model="data.tel"
              ></v-text-field>
            </div>
            <div class="d-flex mb-2">
              <v-select
                :items="contractTypeData"
                item-text="name"
                item-value="value"
                class="mr-1"
                hide-details
                label="類型"
                v-model="data.type"
              ></v-select>
              <v-text-field
                clearable
                type="number"
                label="總金額"
                hide-details
                class="mr-1"
                v-model="data.totalAmount"
              ></v-text-field>
              <v-select
                :items="booleanData"
                item-text="name"
                item-value="value"
                class="mr-1"
                hide-details
                label="是否顯示金額"
                v-model="data.isShowAmount"
              ></v-select>
            </div>
            <v-textarea
              class="my-2"
              style="width: 100%"
              hide-details
              label="備註"
              v-model="data.memo"
            ></v-textarea>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="clickCloseBtn()"> 取消 </v-btn>
        <v-btn :disabled="disabledButton" color="green darken-1" text @click="clickConfirmBtn()">
          確認
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="dialog"
      width="90%"
      max-width="1200"
      @click:outside="clickCancelDialogBtn"
      @keydown.esc="clickCancelDialogBtn"
    >
      <!-- search customer name and mobileNumber -->
      <v-card>
        <v-card-title> 選取客戶 </v-card-title>

        <v-card-text>
          <div class="d-flex align-end">
            <v-select
              style="width: 8w; max-width: 145px; margin: 0"
              hide-details
              v-model="nowSelectItem"
              :items="selectItem"
              label="搜尋條件"
              class="mr-2"
            ></v-select>
            <v-text-field
              clearable
              label="搜尋"
              hide-details
              v-model="search"
              append-icon="mdi-magnify"
              v-on:keyup.enter="searchFunction()"
              @click:append="searchFunction()"
            ></v-text-field>
          </div>
          <v-radio-group v-model="selectedCustomer">
            <v-radio
              v-for="(item, index) in customersData"
              :key="index"
              :label="`${item.name} (${item.mobileNumber})`"
              :value="item"
            ></v-radio>
          </v-radio-group>
          <v-pagination
            v-if="totalPage > 0"
            v-model="page"
            :length="totalPage"
            :total-visible="7"
            circle
            @input="changePage"
          ></v-pagination>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="clickCancelDialogBtn()"> 取消 </v-btn>
          <v-btn color="green darken-1" text @click="clickConfirmDialogBtn()"> 確認 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import lodash from "lodash";
import { getDealerCustomersList } from "@/apis/DealerCustomers";
import { getLunisolar } from "@/apis/Notification";
import DateTimePicker from "@/components/DateTimePicker.vue";
export default {
  name: "ContractDialog",
  props: ["action", "data", "dealerData"],
  components: {
    DateTimePicker,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "必填",
    },
    date: "",
    dateLunar: "",
    nowSelectItem: "name",
    totalPage: 0,
    page: 0,
    search: "",
    selectItem: [
      { text: "名稱", value: "name" },
      { text: "電話", value: "mobileNumber" },
    ],
    genderData: [
      {
        name: "男",
        value: 1,
      },
      {
        name: "女",
        value: 2,
      },
    ],
    burialTypeData: [
      {
        name: "無",
        value: 0,
      },
      {
        name: "火葬",
        value: 1,
      },
      {
        name: "土葬",
        value: 2,
      },
      {
        name: "樹葬",
        value: 3,
      },
      {
        name: "海葬",
        value: 4,
      },
    ],
    statusData: [
      {
        name: "協調中",
        value: 1,
      },
      {
        name: "進行中",
        value: 2,
      },
      {
        name: "已圓滿",
        value: 3,
      },
      {
        name: "已取消",
        value: 4,
      },
    ],
    contractTypeData: [
      {
        name: "未知",
        value: 0,
      },
      {
        name: "包套",
        value: 1,
      },
      {
        name: "逐項計費",
        value: 2,
      },
    ],
    booleanData: [
      {
        name: "顯示",
        value: true,
      },
      {
        name: "不顯示",
        value: false,
      },
    ],
    dealerContractData: [],
    customersData: [],
    dialog: false,
    selectedCustomer: {},
    isPassAwayDate: false,
    isPassAwayLunarDate: false,
    params: {},
    disabledButton: true,
    isChangeStatus: false,
  }),
  methods: {
    changeStatus() {
      this.isChangeStatus = true;
    },
    confirmDateTime(dateTime, keyValue) {
      if (keyValue === "date") {
        this.date = dateTime;
        this.data.passAwayDate = dateTime;
        this.inputLunisolar();
      } else {
        this.dateLunar = dateTime;
      }
      this.checkForm();
      this.$forceUpdate();
    },
    async inputLunisolar() {
      this.isPassAwayDate = false;
      const targetDate = this.data.passAwayDate
        .split("")
        .splice(0, 10)
        .toString()
        .replaceAll("-", "")
        .replaceAll(",", "");
      const targetTime = this.data.passAwayDate
        .split("")
        .splice(11, 16)
        .toString()
        .replaceAll("-", "")
        .replaceAll(",", "");
      const res = await getLunisolar(targetDate);
      const lunrDate = res.data.result.lunarDateInt.toString().split("");
      const year = parseInt(lunrDate.splice(0, 4).toString().replaceAll(",", ""));
      const month = lunrDate.splice(0, 2).toString().replaceAll(",", "");
      const day = lunrDate.splice(0, 2).toString().replaceAll(",", "");
      this.data.passAwayLunarDate = `${year}-${month}-${day} ${targetTime}`;
      this.dateLunar = `${year}-${month}-${day} ${targetTime}`;
      this.$forceUpdate();
    },
    changePage() {
      this.params.page = this.page;
      this.getDealerCustomers();
    },
    searchFunction() {
      this.params = {
        dealerId: localStorage.getItem("userId"),
        pageSize: 100,
      };
      if (this.search !== "" && this.search !== null) {
        this.params[this.nowSelectItem] = this.search;
      }
      this.getDealerCustomers();
    },
    clickConfirmDialogBtn() {
      this.data.customerName = this.selectedCustomer.name;
      this.data.customerId = this.selectedCustomer.id;
      this.data.tel = this.selectedCustomer.mobileNumber;
      this.dialog = false;
      this.selectedCustomer = {};
      this.checkForm();
    },
    clickCancelDialogBtn() {
      this.isChangeStatus = false;
      this.data.customerName = "";
      this.data.customerId = -1;
      this.data.tel = "";
      this.dialog = false;
      this.selectedCustomer = {};
      this.checkForm();
    },
    clickCloseBtn() {
      this.$emit("clickCloseBtn");
    },
    dateCovernInt(date) {
      return Number(date.replaceAll("-", "").replaceAll(" ", "").replaceAll(":", ""));
    },
    async clickConfirmBtn() {
      let datas = lodash.cloneDeep(this.data);
      datas.passAwayDate = this.dateCovernInt(datas.passAwayDate);
      datas.passAwayLunarDate = this.dateCovernInt(datas.passAwayLunarDate);
      this.$emit("clickConfirmBtn", datas, this.isChangeStatus);
      this.clickCancelDialogBtn();
    },
    async getDealerCustomers() {
      const res = await getDealerCustomersList(this.params);
      this.customersData = res.data.result.results;
      this.totalPage = res.data.result.pageCount;
      this.page = res.data.result.currentPage;
      this.customersData.forEach((item, index) => {
        this.customersData[index].newLabel = `${item.name} (${item.mobileNumber})`;
        this.customersData[index].value = {
          name: item.name,
          id: item.id,
        };
      });
    },
    // open dialog select customer
    openCustomerDialog() {
      if (!this.data.customerName) {
        this.customersData.forEach((item) => {
          if (item.id === this.data.customerId) {
            this.selectedCustomer = item;
          }
        });
      }
      this.dialog = true;
    },
    filterDate(date) {
      const dateSplit = date.toString().split("");
      dateSplit.splice(4, 0, "-");
      dateSplit.splice(7, 0, "-");
      if (dateSplit.length === 10) {
        return dateSplit.toString().replaceAll(",", "") + " 00:00";
      }
      dateSplit.splice(10, 0, " ");
      dateSplit.splice(13, 0, ":");
      return dateSplit.toString().replaceAll(",", "");
    },
    checkForm() {
      if (
        !this.data.name ||
        !this.data.passAwayDate ||
        !this.data.passAwayLunarDate ||
        this.data.gender === undefined ||
        this.data.burialType === undefined ||
        this.data.customerId <= 0 ||
        !this.data.customerName ||
        !this.data.tel ||
        !this.data.title
      ) {
        this.disabledButton = true;
      } else {
        this.disabledButton = false;
      }
    },
  },
  created() {
    this.params.dealerId = localStorage.getItem("userId");
    this.params.pageSize = 100;
    this.getDealerCustomers();
    if (this.action === "update") {
      const date = this.filterDate(this.data.passAwayDate);
      const dateLunar = this.filterDate(this.data.passAwayLunarDate);
      this.data.passAwayDate = date;
      this.data.passAwayLunarDate = dateLunar;
      this.date = date;
      this.dateLunar = dateLunar;
    }
  },
  computed: {},
  watch: {
    action(value) {
      if (value === "update") {
        const date = this.filterDate(this.data.passAwayDate);
        const dateLunar = this.filterDate(this.data.passAwayLunarDate);
        this.data.passAwayDate = date;
        this.data.passAwayLunarDate = dateLunar;
        this.date = date;
        this.dateLunar = dateLunar;
      } else {
        this.date = "";
        this.dateLunar = "";
      }
    },
    data: {
      handler() {
        this.checkForm();
      },
      deep: true,
    },
  },
};
</script>
