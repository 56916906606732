import http from "@/http/axios";
import { judgeError, queryFilter } from "./Common";
import { error, success } from "@/utils/notification";
import store from "../store";

export async function getCustomersFamily(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/DealerCustomers/Family${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function postCustomersRelationKey(dealerId, data) {
  const res = await http.post(`/api/DealerCustomers/${dealerId}/RelationKey`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function getMeDealerCustomers() {
  const res = await http.get("/api/DealerCustomers/Me");
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function getDealerCustomersList(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/DealerCustomers/List${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function createDealerCustomers(data) {
  const res = await http.post(`/api/DealerCustomers/${data.dealerId}`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function updateDealerCustomers(data) {
  const res = await http.put(`/api/DealerCustomers/${data.id}`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function giveStatusDealerCustomers(id, status) {
  const res = await http.post(`/api/DealerCustomers/${id}/Status/${status}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function sendAuthCode(dealerId, mobileNumber) {
  const res = await http.get(
    `/api/DealerCustomers/SendAuthCode?dealerId=${dealerId}&mobileNumber=${mobileNumber}`
  );
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function getAuthCode(dealerId, mobileNumber) {
  const res = await http.get(
    `/api/DealerCustomers/GetAuthCode?dealerId=${dealerId}&mobileNumber=${mobileNumber}`
  );
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function checkExistence(dealerId, mobileNumber) {
  const res = await http.get(
    `/api/DealerCustomers/CheckExistence?dealerId=${dealerId}&mobileNumber=${mobileNumber}`
  );
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function signIn(dealerId, mobileNumber, authCode) {
  const res = await http.post(
    `/api/DealerCustomers/SignIn?dealerId=${dealerId}&mobileNumber=${mobileNumber}&authCode=${authCode}`
  );
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function dealerCustomerLogin(data) {
  const res = await http.post("/api/DealerCustomers/Login", data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function getCustomersShareCode(customerId) {
  const res = await http.get(`/api/DealerCustomers/${customerId}/ShareCode`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}
