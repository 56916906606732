<template>
  <v-app>
    <div id="EObituaryTemplate2">
      <div
        style="
          position: fixed;
          width: 100%;
          height: 100%;
          left: 0;
          background-color: white;
          z-index: 50;
        "
        v-if="loading"
      >
        <Loading />
      </div>
      <v-card class="d-flex justify-center" v-else-if="data !== undefined" style="padding: 10px 0">
        <div
          :class="backgroundBg === 'main' ? 'bgImage2 bg2 main' : ''"
          style="position: absolute; z-index: 1"
          :style="`width:${width}px;min-height:${height}px`"
        ></div>
        <div
          class="bgImage2 bgColor2"
          style="position: relative"
          :style="`width:${width}px;min-height:${height}px`"
        >
          <div>
            <v-btn
              fab
              absolute
              style="right: 5px; top: 5px; width: 40px; height: 40px"
              color="primary"
              @click="switchMusic()"
              id="test"
            >
              <v-icon v-if="isMusic">mdi-music</v-icon>
              <v-icon v-else>mdi-music-off</v-icon>
            </v-btn>
            <div
              style="
                height: 100%;
                position: absolute;
                top: 0;
                width: 70%;
                margin: 0 15%;
                box-shadow: 0 0 15px #000;
                z-index: 2;
                background-image: linear-gradient(to bottom, #000, #fff);
              "
              :class="`${divBackgroundAnimation}`"
              v-if="firstPage"
            ></div>
            <div
              style="
                height: 100%;
                position: absolute;
                top: 0;
                width: 70%;
                margin: 0 15%;
                z-index: 2;
              "
              v-if="firstPage"
            >
              <div class="d-flex jusify-center template2Fade" style="height: 35%">
                <div style="padding: 0 15%">
                  <div
                    style="width: 100%; height: 100%; overflow: hidden"
                    :style="imageAnimation === 'imageEnd' ? '' : 'background-color: #fff'"
                  >
                    <div
                      :class="`${imageAnimation}`"
                      style="width: 100%; height: 100%; overflow: hidden"
                    >
                      <img
                        v-for="(imgSrc, imgSrcIndex) in totalPic"
                        :key="imgSrcIndex"
                        :src="baseUrl + imgSrc"
                        style="width: 100%; height: 100%; object-fit: cover"
                        :class="currentImage === imgSrcIndex ? '' : 'd-none'"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="" style="height: 30%">
                <img
                  src="@/assets/template2/pigeon.png"
                  style="
                    width: 140%;
                    margin: 0 -20%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 2;
                  "
                  :class="`${pingeonAnimation} ${pigeonAndTextFadeAnimation}`"
                />
              </div>
              <div
                style="font-size: 2em; color: #fff; font-weight: 900; height: 35%"
                class="text-center template2Fade"
                :class="`${pigeonAndTextFadeAnimation}`"
              >
                <div>敬邀參加 {{ data.deceasedName }}</div>
                <div style="letter-spacing: 6px">追思告別儀式</div>
                <div>{{ data.ceremonyTime | chineseDate }}</div>
              </div>
            </div>
            <div
              v-if="!firstPage"
              class="template2Fade"
              style="
                height: 100%;
                font-size: 3em;
                font-weight: 900;
                width: 100%;
                position: sticky;
                z-index: 2;
              "
            >
              <div
                class="d-flex flex-column justify-center align-center"
                style="overflow-y: auto"
                :style="`width:${width}px;min-height:${height}px`"
              >
                <div class="my-4">
                  <button
                    style="
                      background-color: rgb(250, 241, 208);
                      border-radius: 0.5rem;
                      box-shadow: 0 0 10px #000;
                      width: 80vw;
                      max-width: 300px;
                    "
                    class="py-1"
                    @click="goGoogleForm()"
                  >
                    參加回函
                  </button>
                </div>
                <div class="my-4">
                  <button
                    style="
                      background-color: rgb(250, 241, 208);
                      border-radius: 0.5rem;
                      box-shadow: 0 0 10px #000;
                      width: 80vw;
                      max-width: 300px;
                    "
                    class="py-1"
                  >
                    訊息分享
                  </button>
                </div>
                <div class="my-4 d-flex flex-column">
                  <button
                    style="
                      background-color: rgb(250, 241, 208);
                      border-radius: 0.5rem;
                      box-shadow: 0 0 10px #000;
                      width: 80vw;
                      max-width: 300px;
                    "
                    class="py-1"
                    @click="openContext"
                  >
                    邀請內文
                  </button>
                  <div style="width: 80vw; max-width: 300px" class="mt-2">
                    <div
                      v-if="isContext"
                      class="text-center white py-2"
                      style="width: 100%; font-size: 16px"
                    >
                      {{ data.content }}
                      <div class="text-left mt-3">
                        <div>日期：{{ splitDate.date }}</div>
                        <div>時間：{{ splitDate.time }}</div>
                        <div>地點：{{ data.ceremonyPlace }}</div>
                        <div>電話：{{ data.contactTel }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="my-4 d-flex flex-column">
                  <button
                    style="
                      background-color: rgb(250, 241, 208);
                      border-radius: 0.5rem;
                      box-shadow: 0 0 10px #000;
                      width: 80vw;
                      max-width: 300px;
                    "
                    class="py-1"
                    @click="openMap()"
                  >
                    儀式地圖
                  </button>
                  <iframe
                    v-if="isMap"
                    width="100%"
                    height="300"
                    class="mt-2 white"
                    frameborder="0"
                    :src="`https://www.google.com/maps?q=${data.ceremonyAddress}&hl=en&output=embed`"
                  ></iframe>
                </div>
                <div class="my-4">
                  <button
                    style="
                      background-color: rgb(250, 241, 208);
                      border-radius: 0.5rem;
                      box-shadow: 0 0 10px #000;
                      width: 80vw;
                      max-width: 300px;
                    "
                    class="py-1"
                    @click="isDealerContract = !isDealerContract"
                  >
                    承辦單位聯絡資訊
                  </button>
                  <div class="mt-2" style="width: 80vw; max-width: 300px">
                    <div
                      v-if="isDealerContract"
                      class="text-center white py-2"
                      style="width: 100%; font-size: 16px"
                    >
                      業者聯絡方式: {{ data.dealerContactInfo }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <audio id="audio">
          <source :src="baseUrl + data.musicPath" type="audio/mpeg" />
        </audio>
      </v-card>
      <div v-else style="font-size: 50px" class="d-flex justify-center">
        <div>此訃聞不存在</div>
      </div>
    </div>
  </v-app>
</template>

<script>
import Loading from "@/components/Loading.vue";
export default {
  name: "Template2",
  props: ["data", "baseUrl"],
  components: {
    Loading,
  },
  data() {
    return {
      height: 0,
      width: 0,
      hidden: false,
      loading: false,
      isMap: false,
      isContext: false,
      splitDate: {},
      isOpenSponsorFlower: false,
      isMusic: false,
      hasFirst: true,
      pingeonAnimation: "",
      currentImage: 0,
      imageAnimation: "",
      divBackgroundAnimation: "topToBottomAnimation",
      pigeonAndTextFadeAnimation: "",
      firstPage: true,
      backgroundBg: "",
      isDealerContract: false,
      totalPic: [],
    };
  },
  methods: {
    switchMusic() {
      if (this.hasFirst) {
        this.autoPlayFunction();
      }
      this.isMusic = !this.isMusic;
      const audio = document.getElementById("audio");
      audio.muted = !this.isMusic;
    },
    autoPlayFunction() {
      const audio = document.getElementById("audio");
      audio.play();
      this.hasFirst = false;
    },
    splitDateFunction() {
      let revise = this.data.ceremonyTime.toString().split("");
      revise.splice(4, 0, "/");
      revise.splice(7, 0, "/");
      revise.splice(12, 0, ":");
      let date = revise.splice(0, 10);
      let time = revise.splice(0, 8);
      this.splitDate.date = date.toString().replaceAll(",", "");
      this.splitDate.time = time.toString().replaceAll(",", "");
    },
    goGoogleForm() {
      window.open(this.data.replyUrl, "_blank");
    },
    openContext() {
      this.isContext = !this.isContext;
    },
    openSponsorFlower() {
      if (this.data.sponsorFlowerType === 0) {
        this.isOpenSponsorFlower = !this.isOpenSponsorFlower;
      } else {
        window.open(this.data.sponsorFlowerUrl, "_blank");
      }
    },
    openMap() {
      this.isMap = !this.isMap;
    },
    getHeight() {
      this.height = window.innerHeight - 20;
      this.width = ((this.height / 16) * 9).toFixed(0);
    },
    setDoorHidden() {
      setTimeout(() => {
        this.hidden = true;
      }, 6000);
    },
    filterDate(date) {
      if (date) {
        let revise = date.toString().split("");
        revise.splice(4, 0, "/");
        revise.splice(7, 0, "/");
        return revise.toString().replaceAll(",", "");
      }
      return "";
    },
    imageCarousel() {
      let i = 0;
      const total = this.totalPic.length;
      const timer = setInterval(() => {
        if (i % total === 1) {
          this.imageAnimation = "imageCarousel";
        } else if (i % total === 2) {
          this.currentImage += 1;
        } else if (i % total === 0) {
          this.imageAnimation = "";
        }
        if (this.currentImage === total - 1) {
          clearInterval(timer);
          setTimeout(() => {
            this.imageAnimation = "imageEnd";
            setTimeout(() => {
              this.divBackgroundAnimation = "bottomToTopAnimation";
              this.pigeonAndTextFadeAnimation = "pigeonAndTextFade";
              setTimeout(() => {
                this.firstPage = false;
              }, 2000);
            }, 2000);
          }, 3000);
        }
        i += 1;
      }, 1000);
    },
    pingeonAnimationFunction() {
      this.pingeonAnimation = "pigeonAnimation";
      setTimeout(() => {
        this.pingeonAnimation = "pigeonFadeInAnimation";
        this.backgroundBg = "main";
      }, 2000);
    },
  },
  created() {
    this.totalPic = [this.data.deceasedPicUrl];
    this.data.media.forEach((item) => {
      if (item.mimeType.split("/")[0] === "image") {
        this.totalPic.push(item.mdeiaUrl);
      }
    });
    window.addEventListener(
      "resize",
      (event) => {
        this.getHeight();
      },
      true
    );
    this.splitDateFunction();
    this.pingeonAnimationFunction();
    this.getHeight();
    setTimeout(() => {
      this.imageCarousel();
    }, 3000);
  },
};
</script>
<style scoped>
#EObituaryTemplate2 {
  font-size: 10px;
}

.bgImage2 {
  border-radius: 0 !important;
  background-size: cover;
}
.bgColor2 {
  background-image: linear-gradient(to bottom, rgb(107, 103, 101), rgb(172, 131, 83));
}
.bg2 {
  background-image: url("../../assets/template2/BG.jpg");
}

@keyframes topToBottom {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes bottomToTop {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-100%);
  }
}

@keyframes leftFlyRight {
  0% {
    transform: translate(-100%, 0px);
  }

  100% {
    transform: translate(100%, -150px);
  }
}

@keyframes opacityTo1 {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes imageScaleSize {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.main {
  animation: fadein 3s ease;
}

.topToBottomAnimation {
  animation: topToBottom 2s ease;
}

.bottomToTopAnimation {
  animation: bottomToTop 2s ease;
}

.template2Fade {
  animation: fadein 4s ease;
}

.pigeonAnimation {
  animation: leftFlyRight 2s ease;
}

.pigeonFadeInAnimation {
  animation: fadein 2s ease;
}

.imageCarousel {
  animation: opacityTo1 3s ease;
}

.pigeonAndTextFade {
  animation: fadeOut 2s ease;
}

.imageEnd {
  animation: imageScaleSize 4s ease;
}
</style>
