import axios from "axios";
import Vue from "vue";
import { userError } from "@/utils/notification";
import store from "../store";

axios.defaults.withCredentials = false;
// 發送前的動作
axios.interceptors.request.use(
  (config) => config,
  (error) => {
    const value = error;
    return Promise.reject(value);
  }
);
// 接收響應前的動作
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const value = error.response;
    return Promise.resolve(value);
  }
);

let CancelToken = null;
let source = null;
CancelToken = axios.CancelToken;
source = CancelToken.source();
export default {
  http_headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },

  refreshHeaders() {
    let token = "";
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    }
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
    };
  },
  post(url, value) {
    const params = {
      method: "POST",
      baseURL: Vue.prototype.baseURL,
      url,
      data: value,
      timeout: 30000,
      headers: this.refreshHeaders(),
      cancelToken: source.token,
    };
    return axios(params)
      .then((response) => response)
      .catch((error) => {
        console.log(error);
      });
  },
  put(url, value) {
    let token = "";
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    }
    const params = {
      method: "PUT",
      baseURL: Vue.prototype.baseURL,
      url,
      data: value,
      timeout: 60000,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: token,
      },
      cancelToken: source.token,
    };
    return axios(params)
      .then((response) => response)
      .catch((response) => response);
  },
  delete(url, val) {
    let token = "";
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    }
    const params = {
      method: "DELETE",
      baseURL: Vue.prototype.baseURL,
      url,
      timeout: 60000,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: token,
      },
      data: val,
      cancelToken: source.token,
    };
    return axios(params)
      .then((response) => response)
      .catch((response) => response);
  },
  get(url) {
    const params = {
      method: "GET",
      baseURL: Vue.prototype.baseURL,
      url,
      timeout: 50000,
      headers: this.refreshHeaders(),
      cancelToken: source.token,
    };
    return axios(params)
      .then((response) => response)
      .then((response) => response)
      .catch((error) => {
        console.log(error);
      });
  },
  cancel() {
    source.cancel();
    store.commit("cancelToken", true);
    userError("中止", "已中止讀取資料");
  },
};
