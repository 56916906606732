<template>
  <div id="OperatorDialog">
    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <v-text-field
          class="mb-2 input px-2 py-1"
          clearable
          style="width: 100%"
          label="名稱"
          hide-details
          v-model="selectedData.displayName"
        ></v-text-field>
        <div class="d-flex mb-2">
          <v-text-field
            clearable
            class="input px-2 py-1"
            label="key"
            hide-details
            v-model="selectedData.name"
          ></v-text-field>
          <v-text-field
            class="mb-2 input px-2 py-1"
            clearable
            style="width: 100%"
            label="值"
            hide-details
            v-model="selectedData.value"
          ></v-text-field>
        </div>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
    <div class="d-flex justify-end pt-3">
      <v-btn color="error darken-1" text @click="closeInformationDialog()">
        取消
      </v-btn>
      <v-btn
        color="green darken-1"
        text
        @click="modifyConfirmBtn()"
        :disabled="disable"
      >
        確認
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "OperatorDialog",
  props: ["selectedData", "statusData", "mode"],
  data: () => ({
    passWordShow: false,
    oldPasswordShow: false,
    newPasswordShow: false,
    rules: {
      required: (value) => !!value || "必填",
    },
  }),
  methods: {
    closeInformationDialog() {
      this.$emit("closeInformationDialog");
    },
    modifyConfirmBtn() {
      if (this.mode === "modify") {
        this.$emit("modifyConfirmBtn");
      } else {
        this.$emit("createConfirmBtn");
      }
    },
  },
  computed: {
    disable() {
      return (
        !this.selectedData.displayName ||
        !this.selectedData.name ||
        !this.selectedData.value
      );
    },
  },
};
</script>
