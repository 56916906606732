<template>
  <div id="CreateContractDetail">
    <CheckGroup
      :thead="thead"
      :haveQuanity="true"
      :listData="listData"
      :refresh="refresh"
      :mode="mode"
      :sentListData="sentListData"
      @searchFeature="getContractItemData"
      @judgeMode="judgeMode"
      @sentConfirm="clickConfirmBtn"
      @cancelSentToList="cancelSentToList"
      @clickCloseDetailBtn="clickCloseDetailBtn"
    />
  </div>
</template>

<script>
import CheckGroup from "../CheckGroup.vue";
import { getContractItemsSingle } from "@/apis/ContractItems";
export default {
  name: "CreateContractDetail",
  props: ["action", "data", "refresh", "selectedSampleDetailId", "detailData"],
  components: {
    CheckGroup,
  },
  data: () => ({
    thead: ["項目名稱", "單位", "數量"],
    listData: [],
    detailData_copy: [],
    sentListData: [],
    mode: "",
    rules: {
      required: (value) => !!value || "必填",
    },
  }),
  methods: {
    judgehaveChecked(datas) {
      if (datas) {
        this.detailData_copy = [];
        datas.forEach((item, index) => {
          this.detailData_copy.push({
            contractItemName: item.name,
            contractSampleId: this.selectedSampleDetailId,
            quantity: item.quantity,
            sort: index + 1,
            unit: item.unit,
          });
        });
      }
      this.detailData_copy.forEach((item) => {
        this.listData.forEach((value, index) => {
          if (item.contractItemName === value.name) {
            this.listData[index].checked = true;
            this.listData[index].quantity = item.quantity;
            this.listData[index].unit = item.unit;
            this.listData[index].sort = item.sort;
          }
        });
      });
    },
    // judge mode is right to left or left to right
    judgeMode(value, selectedList) {
      if (selectedList.length > 0) {
        this.mode = value;
      } else {
        this.mode = "";
      }
    },
    // right to left button (add item to list)
    sentToList(selectedList) {
      this.sentListData.push(...selectedList);
      this.listData = this.listData.filter((item) => !selectedList.includes(item));
      this.mode = "";
    },
    // left to right button (remove item to list)
    cancelSentToList(selectedList) {
      this.listData.push(...selectedList);
      this.sentListData = this.sentListData.filter((item) => !selectedList.includes(item));
      this.mode = "";
    },
    clickCloseDetailBtn() {
      this.mode = "";
      this.sentListData = [];
      this.$emit("clickCloseDetailBtn");
    },
    // judge selected tab for return data
    judgeData(datas) {
      let data = [];
      const sortData = datas.sort((a, b) => {
        if (a.sort === undefined) return 1;
        return a.sort > b.sort ? 1 : -1;
      });
      sortData.forEach((item, index) => {
        data.push({
          contractItemName: item.name,
          quantity: item.quantity,
          contractSampleId: this.selectedSampleDetailId,
          unit: item.unit,
          sort: index + 1,
        });
      });
      return data;
    },
    clickConfirmBtn(datas) {
      const data = this.judgeData(datas);
      this.$emit("createDetail", data);
      this.getContractItemData();
      this.clickCloseDetailBtn();
    },
    // get system's contract item data
    async getContractItemData(value, datas) {
      this.listData = [];
      let query = {};
      if (value) {
        query.name = value;
      }
      const { data } = await getContractItemsSingle(query);
      this.listData = data.result;
      this.judgehaveChecked(datas);
    },
  },
  created() {
    this.detailData_copy = this.detailData;
    this.getContractItemData();
  },
  watch: {
    // when open dialog, need to get contract item data
    refresh(value) {
      if (value) {
        this.detailData_copy = this.detailData;
        this.getContractItemData();
      }
    },
  },
  computed: {
    disable() {
      return this.sentListData.length <= 0;
    },
  },
};
</script>
