<template>
    <div id="ContractDetail">
        <v-card class="py-2">
            <v-card class="ma-4">
                <div class="d-flex justify-space-between pa-3 w-100">
                    <div class="d-flex">
                        <v-tabs v-model="selectedTabIndex">
                            <v-tab v-for="(tab, tabIndex) in tabList" :key="tabIndex">
                                {{ tab.name }}
                            </v-tab>
                        </v-tabs>
                    </div>
                </div>
            </v-card>
            <v-card class="ma-4">
                <TablePage v-if="selectedTabIndex !== 0" :targetName="targetName" :selectedTabIndex="selectedTabIndex"
                    :selectData="selectItem" :thead="thead" :keyGroup="keyGroup" :data="data" :haveDetail="haveDetail"
                    :havePlay="havePlay" :notCreateBtn="notCreateBtn" :notSearchFeature="notSearchFeature"
                    @clickUpdateBtn="clickUpdateBtn" @changeisEnable="changeisEnable" @openDialog="openDialog"
                    @search="search" @clickDetail="openDetail" />
                <v-pagination v-if="((this.selectedTabIndex === 3) || (this.selectedTabIndex === 4)) && totalPage > 0"
                    v-model="page" :length="totalPage" :total-visible="7" circle @input="changePage"></v-pagination>
                <Detail v-if="selectedTabIndex === 0" :detailThead="thead" :refresh="dialog" :detailData="data"
                    :title="'編輯內容'" :selectedTabIndex="selectedTabIndex" :thead="thead" :selectedData="selectedData"
                    :data="data" :detailKey="keyGroup" @createDetail="createDetail" @clickDetail="openDetail"
                    @closeDetailDialog="clickCloseBtn" />
                <v-dialog v-model="dialog" width="90%" max-width="1200" @click:outside="clickCloseBtn"
                    @keydown.esc="clickCloseBtn">
                    <component :is="this.createBtn.value" :detailData="data" :action="action" :dialog="dialog"
                        :refresh="dialog" :data="selectedData" :templateGroup="templateGroup" :musicGroup="musicGroup"
                        :contractId="selectedContractData.id" :dealerConactGroup="dealerConactGroup"
                        :selected-customers="selectedCustomers" @createDetail="createDetail"
                        @clickCloseBtn="clickCloseBtn" @clickConfirmBtn="clickConfirmBtn"></component>
                </v-dialog>
                <v-dialog v-model="detailDialog" width="50%" max-width="1200" @click:outside="closeDetailDialog"
                    @keydown.esc="closeDetailDialog">
                    <v-card class="pa-2">
                        <Detail v-if="selectedTabIndex === 1" :selectedContractData="selectedContractData"
                            :detailThead="detailThead" :refresh="dialog" :detailData="detailData" :title="'編輯內容'"
                            :selectedTabIndex="selectedTabIndex" :thead="detailThead" :selectedData="selectedData"
                            :data="detailData" :haveDate="true" :haveNotify="true" :detailKey="detailKey"
                            @createDetail="createDetail" @clickDetail="openDetail"
                            @closeDetailDialog="closeDetailDialog" />
                    </v-card>
                </v-dialog>
                <v-dialog v-model="shareDialog" width="90%" max-width="1200" @click:outside="closeShareDialog"
                    @keydown.esc="closeShareDialog">
                    <v-card
                        style="padding:5%; display: flex; flex-direction: column; justify-items: center; align-items: center;">
                        <Qrcode-vue :text="shareUrl" level="H"></Qrcode-vue>
                        <div class="wrap"
                            style="padding-top: 1rem; padding-bottom: 2rem; display: flex; align-items: center;">
                            <v-text-field disabled v-model="shareUrl" style="width: 25vw;"></v-text-field>
                            <v-btn @click="copyUrl(shareUrl)" style=" margin-left:1rem;">複製</v-btn>
                        </div>
                    </v-card>
                </v-dialog>
            </v-card>
        </v-card>
    </div>
</template>

<script>
import Vue from "vue";
import lodash from "lodash";
import draggable from "vuedraggable";
import TablePage from "@/components/TablePage.vue";
import Notification from "@/components/contractManagement/Notification.vue";
import DealerFlow from "@/components/contractManagement/DealerFlow.vue";
import CreateContractDetail from "@/components/contractManagement/CreateContractDetail.vue";
import EObituary from "@/components/contractManagement/EObituary.vue";
import CustomerList from "@/components/contractManagement/CustomerList.vue";
import { enableDealerContractItems, disableDealerContractItems } from "@/apis/DealerContractItems";
import {
    getNotificationList,
    createNotification,
    updateNotification,
    updateNotificationStatus,
} from "@/apis/Notification";
import {
    getDealerFlowsList,
    createDealerFlows,
    getDetailDealerFlows,
    createDetailDealerFlows,
    updateDealerFlows,
} from "@/apis/DealerFlows";
import { getDealerContractsDetail, createDealerContractsDetail } from "@/apis/DealerContracts";
import {
    getEObituariesContract,
    getEObituaryMusics,
    getEObituaryTemplates,
    addEObituary,
    updateEObituary,
    disableEObituary,
    enableEObituary,
} from "@/apis/EObituary";
import { getDealerContractItemsSelect } from "@/apis/DealerContractItems";
import Detail from "../Detail.vue";
import { getRelationshipCustomers } from "@/apis/DealerContractualRelationships";
import QrcodeVue from "vue-qrcode-component";

export default {
    name: "ContractDetail",
    props: ["selectedContractData", "refresh"],
    components: {
        TablePage,
        Notification,
        DealerFlow,
        EObituary,
        draggable,
        CreateContractDetail,
        Detail,
        CustomerList,
        QrcodeVue
    },
    data() {
        return {
            targetName: "",
            totalPage: 0,
            params: {},
            contractDetailDialog: false,
            havePlay: false,
            detailDialog: false,
            shareDialog: false,
            shareUrl: "123",
            selectItem: [],
            action: "",
            dialog: false,
            selectedTabIndex: 0,
            tabList: [
                {
                    name: "內容",
                    value: "CreateContractDetail",
                },
                {
                    name: "流程",
                    value: "DealerFlow",
                },
                {
                    name: "訃聞",
                    value: "EObituary",
                },
                {
                    name: "通知",
                    value: "Notification",
                },
                {
                    name: "顧客列表",
                    value: "CustomerList"
                }
            ],
            selectedData: {},
            notCreateBtn: false,
            thead: [],
            data: [],
            keyGroup: [],
            createBtn: {
                name: "",
                value: "",
            },
            dealerId: -1,
            detailKey: [],
            detailThead: [],
            detailData: [],
            templateGroup: [],
            musicGroup: [],
            dealerConactGroup: [],
            notSearchFeature: false,
            selectedCustomers: []
        };
    },
    methods: {
        changePage() {
            this.params.page = this.page;
            this.getNotification();
        },
        async getContractOption() {
            const res = await getDealerContractItemsSelect({});
            this.dealerConactGroup = res.data.result;
        },
        async getTemplateOption() {
            const res = await getEObituaryTemplates({});
            this.templateGroup = res.data.result;
        },
        async getMusticOption() {
            const res = await getEObituaryMusics({});
            this.musicGroup = res.data.result;
        },
        confirmDrag() {
            this.selectedData.id = this.data[0].contractId;
            this.createDetail(this.data);
        },
        async createDetail(data, boolean) {
            switch (this.selectedTabIndex) {
                case 0: {
                    let datas = data;
                    datas.forEach((item, index) => {
                        datas[index].contractId = this.selectedContractData.id;
                    });
                    const res = await createDealerContractsDetail(this.selectedContractData.id, datas);
                    if (res.status === 200) {
                        this.haveSelectedTab();
                    }
                    break;
                }
                case 1:
                    let datas = data;
                    debugger;
                    if (!boolean) {
                        datas.forEach((item, index) => {
                            datas[index].dealerId = this.dealerId;
                            datas[index].dealerFlowId = this.selectedData.id;
                            datas[index].dealerFlowItemName = item.dealerFlowItemName;
                        });
                    }
                    const res = await createDetailDealerFlows(this.selectedData.id, datas);
                    if (res.status === 200) {
                        this.openDetail(this.selectedData);
                    }
                    break;
                default:
                    break;
            }
        },
        closeDetailDialog() {
            this.detailDialog = false;
        },
        closeShareDialog() {
            this.shareDialog = false;
        },
        async openDetail(data) {
            this.selectedData = data;
            if (this.selectedTabIndex === 1) {
                this.detailThead = ["名稱", "參與者", "準備", "地點", "備註", "日期"];
                this.detailKey = [
                    "dealerFlowItemName",
                    "participants",
                    "prepared",
                    "place",
                    "memo",
                    "dateTime",
                ];
                const res = await getDetailDealerFlows(this.dealerId, data.id);
                if (res.status === 200) {
                    this.detailData = res.data.result;//this.sortDataOnTime(res.data.result);
                    this.detailDialog = true;
                }
            }
        },
        sortDataOnTime(data) {
            debugger;
            let datas = data;
            datas.sort((a, b) => {
                return a.dateTime - b.dateTime;
            });
            return datas;
        },
        async getDealerContractsDetail() {
            this.$store.commit("setLoading", true);
            const res = await getDealerContractsDetail(this.dealerId, this.selectedContractData.id);
            if (res.status === 200) {
                this.data = res.data.result;
            }
            this.$store.commit("setLoading", false);
        },
        async getEObituaryList() {
            this.$store.commit("setLoading", true);
            this.data = [];
            const res = await getEObituariesContract(this.selectedContractData.id);
            if (res.status === 200) {
                this.data.push(res.data.result);
                if (this.data.length > 0) {
                    this.notCreateBtn = true;
                }
            }
            this.$store.commit("setLoading", false);
        },
        async getCustomersList() {
            this.$store.commit("setLoading", true);
            const res = await getRelationshipCustomers({ contractId: this.selectedContractData.id });
            if (res.status === 200) {
                this.data = res.data.result;
            }

            this.$store.commit("setLoading", false);
        },
        async haveSelectedTab() {
            this.notCreateBtn = false;
            this.createBtn = this.tabList[this.selectedTabIndex];
            this.data = [];
            switch (this.selectedTabIndex) {
                case 0:
                    this.haveDetail = false;
                    this.havePlay = false;
                    this.haveContact = false;
                    this.haveDetail = false;
                    this.selectItem = [
                        { text: "編號", value: "id" },
                        { text: "狀態", value: "status" },
                    ];
                    this.thead = ["合約項目名稱", "數量", "單位", "金額"];
                    this.keyGroup = ["contractItemName", "quantity", "unit", "unitAmount"];
                    this.getDealerContractsDetail();
                    break;

                case 1:
                    this.targetName = "contractFlowTable";
                    this.notSearchFeature = true;
                    this.havePlay = false;
                    this.haveContact = false;
                    this.haveDetail = true;
                    this.selectItem = [
                        { text: "編號", value: "id" },
                        { text: "狀態", value: "status" },
                    ];
                    this.thead = [
                        "序號",
                        "業者合約序號",
                        "備註",
                        "建立時間",
                        "建立人員",
                        "修改時間",
                        "修改人員",
                        "操作",
                    ];
                    this.keyGroup = [
                        "id",
                        "dealerContractId",
                        "memo",
                        "createTime",
                        "createUser",
                        "updateTime",
                        "updateUser",
                    ];
                    this.getDealerFlows();
                    break;

                case 2:
                    this.targetName = "contractEobTable";
                    this.notSearchFeature = true;
                    this.haveDetail = false;
                    this.havePlay = true;
                    this.selectItem = [
                        { text: "ID", value: "id" },
                        { text: "名稱", value: "name" },
                        { text: "啟用", value: "isEnable" },
                    ];
                    this.thead = [
                        "id",
                        "業者id",
                        "業者合約id",
                        "名稱",
                        "備註",
                        "往生者名字",
                        "往生者年紀",
                        "過世時間",
                        "儀式時間",
                        "儀式地方",
                        "儀式地址",
                        "聯絡人",
                        "聯絡電話",
                        "範本id",
                        "音樂id",
                        "到期日期",
                        "建立時間",
                        "創建人員",
                        "更新時間",
                        "更新人員",
                        "操作",
                    ];
                    this.keyGroup = [
                        "id",
                        "dealerId",
                        "dealerContractId",
                        "name",
                        "memo",
                        "deceasedName",
                        "deceasedAge",
                        "timeOfDeath",
                        "ceremonyTime",
                        "ceremonyPlace",
                        "ceremonyAddress",
                        "contactPerson",
                        "contactTel",
                        "templateId",
                        "musicId",
                        "expireDate",
                        "createTime",
                        "createUser",
                        "updateTime",
                        "updateUser",
                    ];
                    this.getEObituaryList();
                    break;

                case 3:
                    this.targetName = "contractNotificationTable";
                    this.notSearchFeature = false;
                    this.haveDetail = false;
                    this.havePlay = false;
                    this.selectItem = [
                        { text: "編號", value: "id" },
                        { text: "類別", value: "type" },
                        { text: "狀態", value: "status" },
                    ];
                    this.haveContact = true;
                    this.thead = [
                        "序號",
                        "業者合約序號",
                        "日期",
                        "節日名稱",
                        "內文",
                        "類別",
                        "狀態",
                        "通知時間",
                        "發送時間",
                        "建立時間",
                        "建立人員",
                        "操作",
                    ];
                    this.keyGroup = [
                        "id",
                        "dealerContractId",
                        "date",
                        "dateName",
                        "content",
                        "revise_type",
                        "revise_status",
                        "notifyTime",
                        "sentTime",
                        "createTime",
                        "createUser",
                    ];
                    this.getNotification();
                    this.haveDetail = false;
                    break;

                case 4:
                    this.targetName = "customerList";
                    this.haveDetail = false;
                    this.havePlay = false;
                    this.haveContact = false;
                    this.haveDetail = false;
                    this.selectItem = [
                        { text: "編號", value: "id" },
                        { text: "姓名", value: "name" },
                    ];
                    this.thead = ["編號", "名稱", "聯絡電話", "備註"];
                    this.keyGroup = ["id", "name", "mobileNumber", "memo"];
                    this.getCustomersList();
                    break;

                default:
                    this.$store.commit("setLoading", false);
                    this.totalPage = 0;
                    this.page = 0;
                    this.data = [];
                    break;
            }
        },
        async getDealerFlows() {
            this.$store.commit("setLoading", true);
            const res = await getDealerFlowsList(this.selectedContractData.id);
            if (res.status === 200) {
                this.data = [res.data.result];
            }
            if (this.data.length > 0) {
                this.notCreateBtn = true;
            }
            this.$store.commit("setLoading", false);
        },
        statusFilter(status) {
            switch (status) {
                case 0:
                    return "草稿";
                case 1:
                    return "未發送";
                case 2:
                    return "已送達";
                case 3:
                    return "取消";
            }
        },
        async getNotification() {
            this.params.dealerContractId = this.selectedContractData.id;
            this.$store.commit("setLoading", true);
            const res = await getNotificationList(this.params);
            this.totalPage = res.data.result.pageCount;
            this.page = res.data.result.currentPage;
            this.data = res.data.result.results;
            this.data.forEach((item, index) => {
                this.data[index].revise_type = item.type === 0 ? "固定節日" : "合約相關";
                this.data[index].revise_status = this.statusFilter(item.status);
            });
            this.$store.commit("setLoading", false);
        },
        async search(enableBoolean, nowSelectItem, data) {
            if (this.selectedTabIndex === 3) {
                this.params = {
                    dealerId: this.dealerId,
                    dealerContractId: this.selectedContractData.id,
                };
                if (data) {
                    this.params[nowSelectItem] = data;
                }
                this.getNotification();
            }
        },
        async changeisEnable(id, boolean) {
            switch (this.selectedTabIndex) {
                case 1:
                    if (boolean) {
                        await enableDealerContractItems(this.data[index].dealerId, this.data[index].id);
                    } else {
                        await disableDealerContractItems(this.data[index].dealerId, this.data[index].id);
                    }
                    break;
                case 2: {
                    let res = {};
                    if (boolean) {
                        res = await enableEObituary(id);
                    } else {
                        res = await disableEObituary(id);
                    }
                    if (res.status === 200) {
                        this.haveSelectedTab();
                    }
                    break;
                }
                default:
                    break;
            }
        },
        clickUpdateBtn(index) {
            this.openDialog("update", index);
        },
        async clickConfirmBtn(data) {
            let datas = data;
            datas.dealerId = this.dealerId;
            datas.dealerContractId = this.selectedContractData.id;
            if (this.action === "create") {
                switch (this.selectedTabIndex) {
                    case 1: {
                        const datas = data;
                        datas.isEnable = true;
                        const res = await createDealerFlows(datas.dealerId, datas);
                        if (res.status === 200) {
                            this.dialog = false;
                            this.action = "";
                            this.haveSelectedTab();
                        }
                        break;
                    }

                    case 2: {
                        const res = await addEObituary(this.dealerId, data);
                        if (res.status === 200) {
                            this.dialog = false;
                            this.action = "";
                            this.haveSelectedTab();
                        }

                        break;
                    }

                    case 3: {
                        const datas = data;
                        datas.isEnable = true;
                        datas.isFromSample = true;
                        const res = await createNotification(this.selectedContractData.id, datas);
                        if (res.status === 200) {
                            this.clickCloseBtn();
                            this.getNotification();
                        }
                        break;
                    }

                    case 4: {
                        this.clickCloseBtn();
                        this.getCustomersList();
                        break;
                    }

                    default:
                        break;
                }
            } else if (this.action === "update") {
                switch (this.selectedTabIndex) {
                    case 1: {
                        const res = await updateDealerFlows(datas.id, datas);
                        if (res.status === 200) {
                            this.dialog = false;
                            this.action = "";
                            this.haveSelectedTab();
                        }
                        break;
                    }

                    case 2: {
                        const res = await updateEObituary(datas.id, datas);
                        if (res.status === 200) {
                            this.dialog = false;
                            this.action = "";
                            this.haveSelectedTab();
                        }
                        break;
                    }

                    case 3: {
                        if (data.changeStatus) {
                            await updateNotificationStatus(data.id, data.status);
                        }
                        const res = await updateNotification(data.id, data);
                        if (res.status === 200) {
                            this.dialog = false;
                            this.action = "";
                            this.getNotification();
                        }
                        break;
                    }

                    case 4:
                        //update
                        break;

                    default:
                        break;
                }
            }
        },
        clickCloseBtn() {
            this.dialog = false;
            this.action = "";
            this.selectedData = {};
        },
        openDialog(action, index) {
            if (this.selectedTabIndex === 0) {
                this.selectedData = this.selectedContractData;
            }

            if (this.selectedTabIndex === 1 && action === "create") {
                this.selectedData = {};
            }

            if (this.selectedTabIndex === 2 && action === "share") {
                this.shareUrl = `${Vue.prototype.ssrURL}eObituaries/${this.selectedContractData.id}`;
                console.log(this.shareUrl);
                this.shareDialog = true;
                return;
            }

            if (this.selectedTabIndex === 2) {
                this.getMusticOption();
                this.getTemplateOption();
                this.getContractOption();
            }

            this.action = action;
            if (this.selectedTabIndex === 2 && action === "create") {
                this.selectedData = {
                    deceasedName: this.selectedContractData.name,
                    contactPerson: this.selectedContractData.customerName,
                    contactTel: this.selectedContractData.tel,
                    content:
                        "我們摯愛的XXXX，在中午12時30分辭世往生，(享壽)XX歲\n\n僅擇於(儀式日期)中午12時30分於(儀式地點)舉行告別儀式，敬邀諸位親友蒞臨告別致意。\n\n(邀請人)叩謝",
                };
            }

            if (this.selectedTabIndex === 3 && action === "create") {
                this.selectedData = {
                    status: 1,
                };
            }
            this.dialog = true;
            if (typeof index === "number") {
                this.selectedData = lodash.cloneDeep(this.data[index]);
            }
        },
        async copyUrl(url) {
            const permission = await navigator.permissions.query({
                name: "clipboard-read",
            });

            if (permission.state === "denied") {
                return;
            }

            navigator.clipboard.writeText(url);
        },
    },
    created() {
        this.dealerId = localStorage.getItem("userId");
        this.params.dealerId = this.dealerId;
        this.haveSelectedTab();
    },
    watch: {
        selectedTabIndex() {
            this.haveSelectedTab();
        },
        refresh(value) {
            if (value) {
                this.haveSelectedTab();
            } else {
                localStorage.removeItem("day");
            }
        },
    },
    // components: {
    //   QrcodeVue,

    // },
};
</script>
