<template>
  <div id="OperatorDialog">
    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <div class="d-flex mb-2">
          <v-text-field
            clearable
            :disabled="mode === 'modify'"
            class="input px-2 py-1"
            label="帳號"
            hide-details
            v-model="selectedData.accountName"
          ></v-text-field>
          <v-text-field
            v-if="mode !== 'modify'"
            clearable
            class="ml-1 input px-2 py-1"
            label="密碼"
            hide-details
            id="password"
            :type="passWordShow ? 'text' : 'password'"
            :append-icon="passWordShow ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="passWordShow = !passWordShow"
            v-model="selectedData.password"
          ></v-text-field>
        </div>
        <div class="d-flex mb-2" v-if="mode === 'modify'">
          <v-text-field
            clearable
            class="input px-2 py-1"
            label="舊密碼"
            id="oldPassword"
            :type="oldPasswordShow ? 'text' : 'password'"
            :append-icon="oldPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="oldPasswordShow = !oldPasswordShow"
            hide-details
            v-model="selectedData.oldPassword"
          ></v-text-field>
          <v-text-field
            clearable
            class="ml-1 input px-2 py-1"
            label="新密碼"
            hide-details
            id="newPassword"
            :type="newPasswordShow ? 'text' : 'password'"
            :append-icon="newPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="newPasswordShow = !newPasswordShow"
            v-model="selectedData.newPassword"
          ></v-text-field>
        </div>
        <v-text-field
          class="mb-2 input px-2 py-1"
          clearable
          style="width: 100%"
          label="操作員名稱"
          hide-details
          v-model="selectedData.name"
        ></v-text-field>
        <v-textarea
          class="input px-2 py-1"
          hide-details
          label="備註"
          style="width: 100%"
          v-model="selectedData.memo"
        ></v-textarea>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
    <div class="d-flex justify-end pt-3">
      <v-btn color="error darken-1" text @click="closeInformationDialog()"> 取消 </v-btn>
      <v-btn color="green darken-1" text @click="modifyConfirmBtn()" :disabled="disable">
        確認
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "OperatorDialog",
  props: ["selectedData", "statusData", "mode", "refresh"],
  data: () => ({
    passWordShow: false,
    oldPasswordShow: false,
    newPasswordShow: false,
    rules: {
      required: (value) => !!value || "必填",
    },
  }),
  methods: {
    appendDisableAuto() {
      if (this.mode === "modify") {
        document.getElementById("newPassword").autocomplete = "new-password";
        document.getElementById("oldPassword").autocomplete = "new-password";
      } else {
        document.getElementById("password").autocomplete = "new-password";
      }
    },
    closeInformationDialog() {
      this.$emit("closeInformationDialog");
    },
    modifyConfirmBtn() {
      if (this.mode === "modify") {
        this.$emit("modifyConfirmBtn");
      } else {
        this.$emit("createConfirmBtn");
      }
    },
  },
  mounted() {
    this.appendDisableAuto();
  },
  watch: {
    refresh() {
      if (this.refresh) {
        this.$nextTick(() => {
          this.appendDisableAuto();
        });
      }
    },
  },
  computed: {
    disable() {
      return (
        !this.selectedData.accountName || !this.selectedData.password || !this.selectedData.name
      );
    },
  },
};
</script>
