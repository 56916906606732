<template>
  <v-app id="app">
    <Loading v-if="$store.state.loading" />
    <v-navigation-drawer v-model="drawer" app style="height: 100%">
      <v-sheet color="grey lighten-4" class="pa-4 text-center">
        <div class="companyName">黃思帶後台管理系統</div>
        <span class="loginAccount">當前登入:{{ myInfo.name }}</span>
        <u class="pl-5 primary--text ma-auto" style="cursor: pointer" @click="clickUpdatePassword()"
          >修改密碼</u
        >
      </v-sheet>
      <v-list class="pa-0">
        <v-list-item-group v-model="currentPage" color="primary">
          <v-list-item
            v-for="(item, index) in tabList"
            :key="index"
            link
            v-show="item.role === 'All' || role === item.role"
            @click="goPage(item)"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="list_title">{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <v-col class="red text-center py-2" style="cursor: pointer" cols="12" @click="logout()">
          <p class="mb-0 text-lg-h6 text-sm-subtitle-1 font-weight-black white--text">登出</p>
        </v-col>
      </template>
    </v-navigation-drawer>
    <v-app-bar app class="header">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-row no-gutters>
      <v-col>
        <v-main>
          <v-row no-gutters>
            <v-col cols="12">
              <components :is="$store.state.view" @reset="reset"></components>
            </v-col>
          </v-row>
        </v-main>
      </v-col>
      <v-dialog
        v-model="updatePassDialog"
        width="50%"
        max-width="600"
        @click:outside="closeUpdatePassWordDialog"
        @keydown.esc="closeUpdatePassWordDialog"
      >
        <v-card>
          <v-card-title> 修改密碼 </v-card-title>
          <div class="pa-3">
            <div>
              <v-row>
                <v-col cols="1"></v-col>
                <v-col cols="10">
                  <div class="d-flex mb-2">
                    <v-text-field
                      clearable
                      class="input px-2 py-1"
                      label="舊密碼"
                      :type="oldPasswordShow ? 'text' : 'password'"
                      :append-icon="oldPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="oldPasswordShow = !oldPasswordShow"
                      hide-details
                      v-model="myNewPass.oldPassword"
                    ></v-text-field>
                    <v-text-field
                      clearable
                      class="ml-1 input px-2 py-1"
                      label="新密碼"
                      hide-details
                      :type="newPasswordShow ? 'text' : 'password'"
                      :append-icon="newPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
                      v-on:keyup.enter="updatePassword"
                      @click:append="newPasswordShow = !newPasswordShow"
                      v-model="myNewPass.newPassword"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="1"></v-col>
              </v-row>
              <div class="d-flex justify-end pt-3">
                <v-btn color="error darken-1" text @click="closeUpdatePassWordDialog()">
                  取消
                </v-btn>
                <v-btn color="green darken-1" text @click="updatePassword()"> 確認 </v-btn>
              </div>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import { getMe, updatePasswordOperators } from "@/apis/Operator";
import { getMeDealer, updatePasswordDealers } from "@/apis/Dealers";
import IndustryManagement from "./IndustryManagement";
import TemplateManagement from "./TemplateManagement.vue";
import CustomerManagement from "./CustomerManagement.vue";
import ContractManagement from "./ContractManagement.vue";
import OperatorManagement from "./OperatorManagement.vue";
import EObituaryManagement from "./EObituaryManagement.vue";
import DealerManagement from "./DealerManagement.vue";
import AnnounceManagement from "./AnnounceManagement.vue";
import AdvertiseManagement from "./AdvertiseManagement.vue";
import SiteConfigsManagement from "./SiteConfigsManagement.vue";
import QAManagement from "./QAManagement.vue";
import Calendar from "./Calendar.vue";
import Loading from "../components/Loading.vue";
import { getSettings } from "@/apis/Settings";

export default {
  name: "Home",
  components: {
    IndustryManagement,
    EObituaryManagement,
    TemplateManagement,
    CustomerManagement,
    ContractManagement,
    OperatorManagement,
    DealerManagement,
    AnnounceManagement,
    AdvertiseManagement,
    SiteConfigsManagement,
    QAManagement,
    Calendar,
    Loading,
  },
  data: () => ({
    role: "",
    updatePassDialog: false,
    oldPasswordShow: false,
    newPasswordShow: false,
    currentPage: 0,
    myInfo: {},
    myNewPass: {},
    drawer: true,
    tabList: [
      {
        icon: "mdi-account-group",
        path: "OperatorManagement",
        name: "操作員管理",
        role: "Operators",
      },
      {
        icon: "mdi-account-group",
        path: "CustomerManagement",
        name: "客戶管理",
        role: "Dealers",
      },
      {
        icon: "mdi-account-group",
        path: "DealerManagement",
        name: "業者管理",
        role: "Operators",
      },
      {
        icon: "mdi-file-document",
        path: "ContractManagement",
        name: "合約管理",
        role: "Dealers",
      },
      {
        icon: "mdi-book",
        path: "EObituaryManagement",
        name: "訃聞管理",
        role: "Operators",
      },
      {
        icon: "mdi-book",
        path: "TemplateManagement",
        name: "系統範本管理",
        role: "Operators",
      },
      {
        icon: "mdi-account-group",
        path: "IndustryManagement",
        name: "範本管理",
        role: "Dealers",
      },
      {
        icon: "mdi-calendar",
        path: "Calendar",
        name: "流程行事曆",
        role: "Dealers",
      },
      {
        icon: "mdi-bullhorn-variant",
        path: "AnnounceManagement",
        name: "公告管理",
        role: "Operators",
      },
      {
        icon: "mdi-advertisements",
        path: "AdvertiseManagement",
        name: "廣告管理",
        role: "Operators",
      },
      {
        icon: "mdi-cogs",
        path: "SiteConfigsManagement",
        name: "系統管理",
        role: "Operators",
      },
      {
        icon: "mdi-help-circle",
        path: "QAManagement",
        name: "Q&A管理",
        role: "Operators",
      },
    ],
  }),
  methods: {
    async getSettings() {
      const res = await getSettings();
      if (res.status === 200) {
        this.$store.commit("setStorageDomain", res.data.result.storageDomain);
      }
    },
    clickUpdatePassword() {
      this.updatePassDialog = true;
    },
    findCurrentPage() {
      this.tabList.forEach((item, index) => {
        if (this.$store.state.view === item.path) {
          this.currentPage = index;
        }
      });
    },
    goPage(item) {
      if (item.path !== null && item.path !== undefined && this.$store.state.view !== item.path) {
        this.$store.commit("setView", item.path);
        this.$store.commit("setName", item.name);
        this.$router.push(`/${item.path}`);
      }
    },
    setViewFunction() {
      const path = this.$route.params.page || "IndustryManagement";
      this.$store.commit("setView", path);
    },
    async getMyInfo() {
      const functionArr = [getMe, getMeDealer];
      const index = this.role === "Operators" ? 0 : 1;
      const res = await functionArr[index]();
      if (res.status === 200) {
        this.myInfo = res.data.result;
      }
    },
    closeUpdatePassWordDialog() {
      this.myNewPass = {};
      this.updatePassDialog = false;
    },
    async updatePassword() {
      const functionArr = [updatePasswordOperators, updatePasswordDealers];
      const index = this.role === "Operators" ? 0 : 1;
      const res = await functionArr[index](this.myInfo.id, this.myNewPass);
      if (res.status === 200) {
        this.closeUpdatePassWordDialog();
      }
    },
    logout() {
      localStorage.clear();
      this.$router.push("/");
    },
    reset() {
      this.role = localStorage.getItem("user");
      this.setViewFunction();
      this.findCurrentPage();
      this.getMyInfo();
    },
  },
  created() {
    this.role = localStorage.getItem("user");
    this.setViewFunction();
    this.findCurrentPage();
    this.getMyInfo();
    this.getSettings();
  },
};
</script>
<style scoped lang="sass">
.companyName
  font-size: 1.5rem
  font-weight: 900
.loginAccount
  font-size: 1rem
  font-weight: 700
.list_title
  font-size: 1rem
  font-weight: 600
.list_icon
  font-size: 1rem
.header
  box-shadow: 1px 1px 15px rgb(0 0 0 / 25%) !important
@media screen and (max-width: 1263px)
  .sideBar
    position: fixed
    width: 220px !important
    height: 100vh
    z-index: 999
</style>
