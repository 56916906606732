<template>
  <div id="OperatorDialog">
    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <div class="d-flex mb-2">
          <v-text-field
            clearable
            class="input px-2 py-1"
            label="問題"
            hide-details
            v-model="selectedData.question"
          ></v-text-field>
        </div>
        <v-textarea
          class="input px-2 py-1"
          hide-details
          label="答案"
          style="width: 100%"
          v-model="selectedData.answer"
        ></v-textarea>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
    <div class="d-flex justify-end pt-3">
      <v-btn color="error darken-1" text @click="closeInformationDialog()"> 取消 </v-btn>
      <v-btn color="green darken-1" text @click="modifyConfirmBtn()" :disabled="disable">
        確認
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "OperatorDialog",
  props: ["selectedData", "statusData", "mode"],
  data: () => ({
    rules: {
      required: (value) => !!value || "必填",
    },
  }),
  methods: {
    closeInformationDialog() {
      this.$emit("closeInformationDialog");
    },
    modifyConfirmBtn() {
      if (this.mode === "modify") {
        this.$emit("modifyConfirmBtn");
      } else {
        this.$emit("createConfirmBtn");
      }
    },
  },
  computed: {
    disable() {
      return !this.selectedData.question || !this.selectedData.answer;
    },
  },
};
</script>
