<template>
  <v-app>
    <div id="EObituaryTemplate4" class="">
      <div
        style="
          position: fixed;
          width: 100%;
          height: 100%;
          left: 0;
          background-color: white;
          z-index: 50;
        "
        v-if="loading"
      >
        <Loading />
      </div>
      <div v-else-if="data !== undefined" class="d-flex justify-center" style="position: relative">
        <div
          style="
            max-width: 375px;
            width: 100%;
            position: relative;
            min-height: 1800px;
            overflow: hidden;
          "
          class="bgImage"
        >
          <div>
            <img
              src="../../assets/template4/hibiscus01.png"
              style="position: absolute; right: -37%; top: -5%"
            />
            <img
              src="../../assets/template4/hibiscus02.png"
              style="position: absolute; left: -35%; top: 0%"
            />
          </div>
          <div style="position: relative; top: 12%; width: 100%" class="d-flex justify-center">
            <div class="d-flex justify-center" style="width: 80%; position: relative">
              <div class="eob-border d-flex align-center justify-center template4Fade">
                <!-- <img
                  :src="`${baseUrl}${data.deceasedPicUrl}`"
                  style="
                    width: 100%;
                    max-width: 250px;
                    height: 300px;
                    object-fit: cover;
                    margin-top: -15px;
                  "
                /> -->
                <div>
                  <div
                    style="width: 100%; height: 100%; overflow: hidden"
                    :style="imageAnimation === '' ? '' : 'background-color: #fff'"
                  >
                    <div
                      :class="`${imageAnimation}`"
                      style="width: 100%; height: 100%; overflow: hidden"
                    >
                      <img
                        v-for="(imgSrc, imgSrcIndex) in totalPic"
                        :key="imgSrcIndex"
                        :src="baseUrl + imgSrc"
                        style="
                          width: 100%;
                          max-width: 250px;
                          height: 300px;
                          object-fit: cover;
                          margin-top: -15px;
                        "
                        :class="currentImage % totalPic.length === imgSrcIndex ? '' : 'd-none'"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style="
              font-size: 2em;
              width: 100%;
              letter-spacing: 10px;
              padding-top: 90%;
              position: relative;
            "
            class="text-center white--text"
          >
            <img
              src="../../assets/template4/hibiscus03.png"
              style="position: absolute; right: -53%; top: 35%"
            />
            <div style="padding-bottom: 1%">{{ data.deceasedName }}</div>
            <div style="padding-top: 1%">追思告別儀式</div>
          </div>
          <div
            style="font-size: 2em; width: 100%; padding-top: 25%"
            class="text-center white--text"
          >
            {{ data.ceremonyTime | dateForChineseFilter }}
            <img src="../../assets/template4/longDivider.png" />
          </div>
          <div style="position: relative">
            <div style="padding-top: 30%; position: relative">
              <div
                style="letter-spacing: 3px; font-size: 2em; cursor: pointer"
                class="d-flex flex-column align-center white--text"
                v-if="data.isShowContent"
                @click="openContext"
              >
                <span class="ml-5">邀請文</span>
                <img src="../../assets/template4/shortDivider.png" style="margin-top: 3%" />
                <div v-if="isContext" class="text-center pa-2" style="font-size: 1em">
                  {{ data.content }}
                  <div class="text-left mt-3">
                    <div>日期：{{ splitDate.date }}</div>
                    <div>時間：{{ splitDate.time }}</div>
                    <div>地點：{{ data.ceremonyPlace }}</div>
                    <div>電話：{{ data.contactTel }}</div>
                  </div>
                </div>
              </div>
              <div
                style="letter-spacing: 3px; font-size: 2em; cursor: pointer; margin-top: 10%"
                class="d-flex flex-column align-center white--text pa-2"
                v-if="data.isShowMap"
                @click="openMap"
              >
                <span class="ml-5">儀式地圖</span>
                <img src="../../assets/template4/shortDivider.png" style="margin-top: 3%" />
                <iframe
                  v-if="isMap"
                  style="font-size: 1em"
                  height="300"
                  class="pa-2 white"
                  frameborder="0"
                  :src="`https://www.google.com/maps?q=${data.ceremonyAddress}&hl=en&output=embed`"
                ></iframe>
              </div>
              <div
                style="letter-spacing: 3px; font-size: 2em; cursor: pointer; margin-top: 10%"
                class="d-flex flex-column align-center white--text"
                @click="copyLink"
              >
                <span class="ml-5">訊息分享</span>
                <img src="../../assets/template4/shortDivider.png" style="margin-top: 3%" />
              </div>
              <div
                style="letter-spacing: 3px; font-size: 2em; cursor: pointer; margin-top: 10%"
                class="d-flex flex-column align-center white--text"
                v-if="data.isShowReply"
                @click="goGoogleForm()"
              >
                <span class="ml-5">參加回函</span>
                <img src="../../assets/template4/shortDivider.png" style="margin-top: 3%" />
              </div>
              <div
                style="letter-spacing: 3px; font-size: 2em; cursor: pointer; margin-top: 10%"
                class="d-flex flex-column align-center white--text"
                v-if="data.isShowSponsorFlower"
                @click="openSponsorFlower"
              >
                <span class="ml-5">致贈花卉</span>
                <img src="../../assets/template4/shortDivider.png" style="margin-top: %" />
                <div
                  v-if="isOpenSponsorFlower"
                  class="text-center pa-2 break-all"
                  style="
                    font-size: 1em;
                    width: 100%;
                    position: relative;
                    z-index: 1;
                    word-break: break-all;
                  "
                >
                  {{ data.sponsorFlowerInfo }}
                </div>
              </div>

              <img
                src="../../assets/template4/hibiscus04.png"
                style="position: absolute; left: -25%; top: 60%"
              />
            </div>
            <div class="d-flex justify-center">
              <img
                src="../../assets/template4/longDivider.png"
                style="padding-top: 40%; margin: auto 0; position: relative; z-index: 1"
              />
            </div>
            <div class="d-flex justify-center" style="padding-top: 10%">
              <img
                src="../../assets/template4/copyright.png"
                style="position: relative; z-index: 1"
              />
            </div>
            <div style="position: relative; height: 200px">
              <img
                src="../../assets/template4/hibiscus05.png"
                style="position: absolute; margin-left: 37%; margin-top: -65%; width: 80%"
              />
              <img
                src="../../assets/template4/hibiscus06.png"
                style="
                  position: absolute;
                  margin-top: -20%;
                  z-index: 1;
                  width: 120%;
                  margin-left: -26%;
                "
              />
            </div>
          </div>
        </div>
        <audio id="audio">
          <source :src="baseUrl + data.musicPath" type="audio/mpeg" />
        </audio>
      </div>
      <div v-else style="font-size: 50px" class="d-flex justify-center">
        <div>此訃聞不存在</div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { copySuccess } from "../../utils/notification";
import Loading from "@/components/Loading.vue";
export default {
  name: "Template4",
  props: ["data", "baseUrl"],
  components: {
    Loading,
  },
  data() {
    return {
      height: 0,
      width: 0,
      loading: false,
      isMap: false,
      isContext: false,
      splitDate: {},
      isOpenSponsorFlower: false,
      isMusic: false,
      hasFirst: true,
      totalPic: [],
      imageAnimation: "",
      currentImage: 0,
    };
  },
  methods: {
    imageCarousel() {
      let i = 0;
      const total = this.totalPic.length;
      setInterval(() => {
        if (i % total === 1) {
          this.imageAnimation = "imageCarousel";
        } else if (i % total === 2) {
          this.currentImage += 1;
        } else if (i % total === 0) {
          this.imageAnimation = "";
        }
        i += 1;
      }, 1000);
    },
    switchMusic() {
      if (this.hasFirst) {
        this.autoPlayFunction();
      }
      this.isMusic = !this.isMusic;
      const audio = document.getElementById("audio");
      audio.muted = !this.isMusic;
    },
    autoPlayFunction() {
      const audio = document.getElementById("audio");
      audio.play();
      this.hasFirst = false;
    },
    splitDateFunction() {
      let revise = this.data.ceremonyTime.toString().split("");
      revise.splice(4, 0, "/");
      revise.splice(7, 0, "/");
      revise.splice(12, 0, ":");
      revise.splice(15, 0, ":");
      let date = revise.splice(0, 10);
      let time = revise.splice(0, 8);
      this.splitDate.date = date.toString().replaceAll(",", "");
      this.splitDate.time = time.toString().replaceAll(",", "");
    },
    goGoogleForm() {
      window.open(this.data.replyUrl, "_blank");
    },
    openContext() {
      this.isContext = !this.isContext;
    },
    openSponsorFlower() {
      if (this.data.sponsorFlowerType === 0) {
        this.isOpenSponsorFlower = !this.isOpenSponsorFlower;
      } else {
        window.open(this.data.sponsorFlowerUrl, "_blank");
      }
    },
    openMap() {
      this.isMap = !this.isMap;
    },
    getHeight() {
      this.height = window.innerHeight - 20;
      this.width = ((this.height / 16) * 9).toFixed(0);
    },
    copyLink() {
      navigator.clipboard.writeText(window.location.href);
      copySuccess();
    },
  },
  created() {
    this.totalPic = [this.data.deceasedPicUrl];
    this.data.media.forEach((item) => {
      if (item.mimeType.split("/")[0] === "image") {
        this.totalPic.push(item.mdeiaUrl);
      }
    });
    window.addEventListener(
      "resize",
      (event) => {
        this.getHeight();
      },
      true
    );
    this.getHeight();
  },
  mounted() {
    this.imageCarousel();
  },
};
</script>
<style scoped>
@font-face {
  font-family: "eobTemplate";
  src: local("eobTemplate"), url("../../assets/fonts/FZZhaoMFKSJF.TTF"), format("truetype");
}

.eob-border {
  background-image: url("../../assets/template4/border.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  height: 100%;
  z-index: 1;
  background-position: 7px 0px;
  width: 300px;
  height: 350px;
}

#EObituaryTemplate4 {
  font-family: "eobTemplate" !important;
  font-size: 15px;
}
.bgImage {
  border-radius: 0 !important;
  background-size: 100% 100%;
  background-image: url("../../assets/template4/background.png");
}

@keyframes opacityTo1 {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.template4Fade {
  animation: fadein 4s ease;
}
.imageCarousel {
  animation: opacityTo1 3s ease;
}
</style>
