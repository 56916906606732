<template>
  <div
    style="
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: rgb(0, 0, 0, 0.5);
      z-index: 999;
      left: 0;
      top: 0;
    "
    v-if="isCroppie"
  >
    <vue-croppie
      ref="croppieRef"
      :enableOrientation="true"
      :options="true"
      :boundary="boundary"
      :viewport="viewport"
      @result="result"
      class="mt-5"
    >
    </vue-croppie>

    <!-- the result -->
    <img v-bind:src="cropped" />

    <!-- Rotate angle is Number -->
    <div class="d-flex justify-center" style="position: fixed; left: 0; bottom: 20px; width: 100%">
      <v-btn @click="cancelCrop()" color="red" class="white--text mx-2">取消</v-btn>
      <v-btn @click="rotate(-90)" color="purple" class="white--text mx-2">左旋轉</v-btn>
      <v-btn @click="crop()" color="primary" class="mx-2">裁切</v-btn>
      <v-btn @click="rotate(90)" color="success" class="mx-2">右旋轉</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "CroppiePhoto",
  props: ["isCroppie", "photoUrl", "isAdvertise"],
  data() {
    return {
      cropped: null,
      boundary: { width: "70%", height: "70%" },
      viewport: {},
    };
  },
  methods: {
    bind() {
      // Randomize cat photos, nothing special here.
      let url = this.images[Math.floor(Math.random() * 4)];

      // Just like what we did with .bind({...}) on
      // the mounted() function above.
      this.$refs.croppieRef.bind({
        url: url,
      });
    },
    // CALBACK USAGE
    crop() {
      // Here we are getting the result via callback function
      // and set the result to this.cropped which is being
      // used to display the result above.
      let options = {
        type: "base64",
        format: "jpeg",
        circle: false,
      };
      this.$refs.croppieRef.result(options, (output) => {
        this.$emit("finishCropPhoto", output);
      });
    },
    cancelCrop() {
      this.$emit("cancelCrop");
    },
    // EVENT USAGE
    cropViaEvent() {
      this.$refs.croppieRef.result(options);
    },
    result(output) {
      this.cropped = output;
    },
    rotate(rotationAngle) {
      // Rotates the image
      this.$refs.croppieRef.rotate(rotationAngle);
    },
  },
  watch: {
    isCroppie() {
      if (this.isCroppie) {
        if (this.isAdvertise) {
          this.boundary = {
            height: "70%",
          };
          this.viewport = {
            width: 1100,
            height: 300,
          };
        } else {
          this.boundary = { width: "70%", height: "70%" };
          this.viewport = {};
        }
        this.$nextTick(() => {
          this.$refs.croppieRef.bind({
            url: this.photoUrl,
          });
        });
      }
    },
  },
};
</script>
