import http from '@/http/axios';
import { judgeError, queryFilter } from './Common';
import { error, success } from '@/utils/notification';

export async function getNotificationList(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/Notification/List${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  }
  return res;
}

export async function getNotification(id) {
  const res = await http.get(`/api/Notification/${id}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  }
  return res;
}

export async function getNotificationLunisolar(year) {
  const res = await http.get(`/api/Notification/Lunisolar/Existed?year=${year}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  }
  return res;
}

export async function createNotification(contractId, data) {
  const res = await http.post(`/api/Notification/${contractId}`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  } else {
    success();
  }
  return res;
}

export async function updateNotification(id, data) {
  const res = await http.put(`/api/Notification/${id}`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  } else {
    success();
  }
  return res;
}

export async function updateNotificationStatus(id, status) {
  const res = await http.post(`/api/Notification/${id}/Status/${status}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  }
  return res;
}

export async function getLunisolar(date) {
  const res = await http.get(`/api/Notification/Lunisolar?date=${date}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  }
  return res;
}

export async function getHundredDay(day) {
  const res = await http.get(`/api/Notification/HundredDay?day=${day}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}
