<template>
  <div id="Detail">
    <div
      class="d-flex justify-end align-center py-2 px-2"
      style="position: sticky; top: 0; background-color: #fff; z-index: 1"
    >
      <v-switch
        v-model="isOpenDrag"
        label="排序"
        hide-details
        class="mr-2"
        style="margin: 0"
        @change="switchDrag()"
      ></v-switch>
      <v-btn
        color="red"
        large
        class="white--text create_industryBtn mr-2"
        @click="openFlowSample()"
        :disabled="isOpenDrag"
        v-if="haveDate"
      >
        <v-icon class="mr-2">mdi-file</v-icon>
        PDF預覽
      </v-btn>
      <v-btn
        v-if="!isOnlySort"
        color="primary"
        large
        class="create_industryBtn"
        @click="addContactInfo()"
        :disabled="isOpenDrag"
      >
        <v-icon class="mr-2">mdi-plus</v-icon>
        編輯內容
      </v-btn>
    </div>
    <v-row
      no-gutters
      style="font-size: 18px; border-bottom: 1px solid #d2d2d2; color: #777"
      class="px-2 pb-2"
    >
      <v-col v-for="(th, thIndex) in thead" :key="thIndex" v-show="th !== '操作'">{{ th }}</v-col>
      <v-col v-if="isOpenDrag"></v-col>
    </v-row>
    <draggable
      style="padding-bottom: 48px"
      v-model="detailData_copy"
      @change="changeSort"
      :sort="isOpenDrag"
    >
      <transition-group>
        <v-row
          no-gutters
          class="px-2"
          style="font-size: 14px; border-bottom: 1px solid #d2d2d2"
          :style="isOpenDrag ? 'cursor: grab;' : ''"
          v-show="detailData_copy.length > 0"
          v-for="(item, index) in detailData_copy"
          :key="index + 1"
        >
          <v-col v-for="(val, valIndex) in detailKey" :key="valIndex">
            <div v-if="(val === 'dateTime' || val === 'createTime') && item[val] !== 0">
              {{ item[val] | dateTimeFilter }}
            </div>
            <div v-else-if="val === 'dateTime' && item[val] === 0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" x-small color="red">
                    <v-icon color="white" style="font-size: 18px"> mdi-exclamation </v-icon>
                  </v-btn>
                </template>
                <span>尚未填寫時間</span>
              </v-tooltip>
            </div>
            <div v-else>
              {{ item[val] }}
            </div>
          </v-col>
          <v-col v-if="isOpenDrag">
            <v-icon style="color: #b2b2b2">mdi-menu</v-icon>
          </v-col>
        </v-row>
      </transition-group>
    </draggable>
    <v-row
      v-show="
        detailData_copy.length > 0 &&
        $router.currentRoute.params.page === 'ContractManagement' &&
        selectedTabIndex === 0
      "
      class="pa-2"
      style="font-size: 18px"
      no-gutters
    >
      <v-col>統計</v-col>
      <v-col :cols="detailKey.length - 1"
        ><span style="font-weight: 900; border-bottom: 1px solid #000" class="px-2">{{
          calcTotalAmount()
        }}</span
        >元</v-col
      >
    </v-row>
    <v-row
      no-gutters
      class="py-2 px-2 text-center"
      style="font-size: 14px; border-bottom: 1px solid #d2d2d2"
      :style="isOpenDrag ? 'cursor: grab;' : ''"
      v-show="detailData_copy.length <= 0"
    >
      <v-col>無資料</v-col>
    </v-row>
    <div
      class="d-flex justify-end"
      v-if="isOpenDrag"
      style="
        position: sticky;
        z-index: 2;
        bottom: 0;
        background-color: #fff;
        width: 100%;
        left: 0;
        margin-bottom: 5px;
      "
    >
      <v-btn color="error darken-1" text @click="cancelDrag()"> 取消 </v-btn>
      <v-btn color="green darken-1" text @click="confirmDrag()"> 確定 </v-btn>
    </div>
    <div
      class="d-flex justify-end"
      v-else-if="
        !($router.currentRoute.params.page === 'ContractManagement' && selectedTabIndex === 0)
      "
      style="
        position: sticky;
        z-index: 2;
        bottom: 0;
        background-color: #fff;
        width: 100%;
        left: 0;
        margin-bottom: 5px;
      "
    >
      <v-btn color="error darken-1" text @click="closeDetailDialog()"> 取消 </v-btn>
    </div>
    <v-dialog
      v-model="dialog"
      width="90%"
      max-width="1200"
      scrollable
      @click:outside="closeCreateDialog"
      @keydown.esc="closeCreateDialog"
    >
      <CheckGroup
        :thead="theadRevise"
        :listData="listData"
        :refresh="dialog"
        :mode="mode"
        :haveDealerContractItem="haveDealerContractItem"
        :haveFlow="haveFlow"
        :haveDate="haveDate"
        :haveNotify="haveNotify"
        :sentListData="sentListData"
        @searchFeature="getContractItemData"
        @judgeMode="judgeMode"
        @sentToList="sentToList"
        @cancelSentToList="cancelSentToList"
        @sentConfirm="confirmAction"
        @clickCloseDetailBtn="closeCreateDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="isFlowSamplePdf"
      width="90%"
      max-width="1200"
      scrollable
      @click:outside="closeFlowSamplePdf"
      @keydown.esc="closeFlowSamplePdf"
    >
      <FlowSamplePDF
        :data="detailData_copy"
        :isOpen="isFlowSamplePdf"
        @closeFlowSamplePdf="closeFlowSamplePdf"
        :selectedData="selectedContractData"
      />
    </v-dialog>
    <v-dialog
      v-model="dragHintDialog"
      width="40%"
      max-width="300"
      scrollable
      @click:outside="cancelDrag"
      @keydown.esc="cancelDrag"
    >
      <v-card class="rounded pa-2">
        <span style="font-size: 16px" class="pa-4">需要保存變更後的排序嗎?</span>
        <div class="d-flex justify-end pt-2">
          <v-btn class="mr-2" color="red" text @click="cancelDrag">取消</v-btn>
          <v-btn class="" color="primary" text @click="confirmDrag">保存</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import draggable from "vuedraggable";
import CheckGroup from "@/components/CheckGroup.vue";
import { getDealerContractItemsSelect } from "@/apis/DealerContractItems";
import { getDealerFlowItems } from "@/apis/DealerFlowItems";
import FlowSamplePDF from "./contractManagement/FlowSamplePDF.vue";
export default {
  name: "Detail",
  props: [
    "selectedData",
    "thead",
    "title",
    "dealerData",
    "detailData",
    "detailKey",
    "selectedTabIndex",
    "detailThead",
    "haveNotify",
    "selectedContractData",
    "isOnlySort",
  ],
  data: () => ({
    isOpenDrag: false,
    dialog: false,
    data: {},
    listData: [],
    sentListData: [],
    mode: "",
    haveDealerContractItem: false,
    haveFlow: false,
    haveDate: false,
    theadRevise: [],
    detailData_copy: [],
    isFlowSamplePdf: false,
    haveChange: false,
    dragHintDialog: false,
  }),
  components: {
    CheckGroup,
    draggable,
    FlowSamplePDF,
  },
  methods: {
    switchDrag() {
      if (this.haveChange) {
        this.dragHintDialog = true;
        this.haveChange = false;
      }
    },
    openFlowSample() {
      this.isFlowSamplePdf = true;
    },
    calcTotalAmount() {
      if (this.haveDealerContractItem) {
        let total = 0;
        this.detailData_copy.forEach((item) => {
          total += Number(item.quantity) * Number(item.unitAmount);
        });
        return total;
      }
    },
    cancelDrag() {
      this.isOpenDrag = false;
      this.dragHintDialog = false;
      this.haveChange = false;
      this.detailData_copy = this.detailData;
    },
    changeSort() {
      this.haveChange = true;
      this.detailData_copy.forEach((item, index) => {
        this.detailData_copy[index].sort = index + 1;
      });
    },
    judgehaveCheckedInit(isSearch, selectedList) {
      let detailData = [];
      if (!selectedList) {
        detailData = this.detailData;
      } else {
        detailData = selectedList;
      }
      let data = cloneDeep(this.listData);
      if (this.haveFlow) {
        detailData.forEach((val) => {
          let item = val;
          if (typeof val === "string") {
            item = JSON.parse(val);
          }
          let boolean = false;
          this.listData.forEach((value, index) => {
            const name = item.flowItemName || item.dealerFlowItemName || item.name;
            if (name === value.name) {
              data[index].checked = true;
              if (item.participants) {
                data[index].participants = item.participants;
              }
              if (item.prepared) {
                data[index].prepared = item.prepared;
              }
              if (item.dateTime !== undefined) {
                data[index].dateTime = item.dateTime;
              }
              if (item.place !== undefined) {
                data[index].place = item.place;
              }
              if (item.memo || item.memoSample) {
                data[index].memoSample = item.memo || item.memoSample;
              }
              data[index].sort = item.sort;
              boolean = true;
            }
          });
          if (!boolean && isSearch) {
            let reivse_data = {
              dealerId: item.dealerId,
              name: item.flowItemName || item.dealerFlowItemName || item.name,
              flowSampleId: item.flowSampleId,
              id: item.id,
              participants: item.participants,
              prepared: item.prepared,
              sort: item.sort,
              checked: true,
            };
            if (item.memo || item.memoSample) {
              reivse_data.memoSample = item.memo || item.memoSample;
            }
            if (item.dateTime !== undefined) {
              reivse_data.dateTime = item.dateTime;
            }
            if (item.place !== undefined) {
              reivse_data.place = item.place;
            }
            data.push(reivse_data);
          }
        });
      } else {
        detailData.forEach((item) => {
          let boolean = false;
          const itemJson = {
            name: item.contractItemName || item.name,
            unit: item.unit,
          };
          this.listData.forEach((value, index) => {
            const valueJson = {
              name: value.name,
              unit: value.unit,
            };
            if (JSON.stringify(itemJson) === JSON.stringify(valueJson)) {
              data[index].checked = true;
              data[index].quantity = item.quantity;
              data[index].unitAmount = item.unitAmount;
              data[index].sort = item.sort;
              boolean = true;
            }
          });
          if (!boolean && isSearch) {
            data.push({
              dealerId: item.dealerId,
              name: item.contractItemName || item.name,
              contractId: item.contractId,
              id: item.id,
              unit: item.unit,
              unitAmount: item.unitAmount,
              quantity: item.quantity,
              sort: item.sort,
              checked: true,
            });
          }
        });
      }
      this.listData = data;
    },
    // get system's contract item data
    async getContractItemData(value, selectedList) {
      this.listData = [];
      let query = {};
      if (value) {
        query.name = value;
      }
      let res = {};
      if (this.selectedTabIndex === 0) {
        this.haveDealerContractItem = true;
        this.haveFlow = false;
        res = await getDealerContractItemsSelect(query);
      } else if (this.selectedTabIndex === 1) {
        this.haveDealerContractItem = false;
        this.haveFlow = true;
        res = await getDealerFlowItems(query);
      }
      if (res.status === 200) {
        this.listData = res.data.result;
        this.judgehaveCheckedInit(value === "" || value === undefined ? true : false, selectedList);
      }
    },
    // judge mode is right to left or left to right
    judgeMode(value, selectedList) {
      if (selectedList.length > 0) {
        this.mode = value;
      } else {
        this.mode = "";
      }
    },
    // right to left button (add item to list)
    sentToList(selectedList) {
      this.sentListData.push(...selectedList);
      this.listData = this.listData.filter((item) => !selectedList.includes(item));
      this.mode = "";
    },
    // left to right button (remove item to list)
    cancelSentToList(selectedList) {
      this.listData.push(...selectedList);
      this.sentListData = this.sentListData.filter((item) => !selectedList.includes(item));
      this.mode = "";
    },
    async removeItem(data) {
      await deleteDealersContactInfos(data.id);
      this.getDealerContactInfo();
    },
    openUpdate(data) {
      this.data = data;
      this.dialog = true;
    },
    async getDealerContactInfo() {
      this.$emit("getDealerContactInfo");
    },
    closeDetailDialog() {
      this.$emit("closeDetailDialog");
    },
    addContactInfo() {
      this.dialog = true;
    },
    closeCreateDialog() {
      this.listData = [];
      this.sentListData = [];
      this.dialog = false;
      this.data = {};
    },
    confirmDrag() {
      this.$emit("createDetail", this.detailData_copy);
      this.cancelDrag();
    },
    async confirmAction(datas) {
      let data = [];
      const sortData = datas.sort((a, b) => {
        if (a.sort === undefined) return 1;
        return a.sort > b.sort ? 1 : -1;
      });
      if (this.selectedTabIndex === 0) {
        sortData.forEach((item, index) => {
          data.push({
            dealerId: item.dealerId,
            contractSampleId: this.selectedData.id,
            contractItemName: item.name,
            quantity: Number(item.quantity),
            unitAmount: Number(item.unitAmount),
            unit: item.unit,
            sort: index + 1,
          });
        });
      } else if (this.selectedTabIndex === 1) {
        sortData.forEach((item, index) => {
          if (this.$router.currentRoute.params.page === "ContractManagement") {
            data.push({
              dealerId: item.dealerId,
              flowSampleId: this.selectedData.id,
              dealerFlowItemName: item.name,
              participants: item.participants,
              prepared: item.prepared,
              memo: item.memo,
              dateTime: Number(item.dateTime) || 0,
              hasNotification: item.notifyTime && item.notifyContent ? true : false,
              notifyTime: item.notifyTime,
              notifyContent: item.notifyContent,
              place: item.place,
              sort: index + 1,
            });
          } else {
            data.push({
              dealerId: item.dealerId,
              flowSampleId: this.selectedData.id,
              flowItemName: item.name,
              participants: item.participants,
              prepared: item.prepared,
              memo: item.memo,
              sort: index + 1,
            });
          }
          if (this.haveDate) {
            data[index].date = item.date;
            data[index].time = item.time;
          }
        });
      } else if (this.selectedTabIndex === 2) {
        sortData.forEach((item, index) => {
          sortData[index].sort = index + 1;
        });
        data = sortData;
      }
      this.closeCreateDialog();
      this.$emit("createDetail", data);
    },
    closeFlowSamplePdf() {
      this.isFlowSamplePdf = false;
    },
  },
  created() {
    if (this.isOnlySort) {
      this.isOpenDrag = true;
    } else {
      this.isOpenDrag = false;
    }
    this.getContractItemData();
    if (this.detailThead.indexOf("準備") > -1) {
      this.theadRevise = ["名稱", "操作"];
    } else {
      this.theadRevise = ["名稱", "單位", "數量", "金額"];
    }
    setTimeout(() => {
      this.detailData_copy = this.detailData;
    }, 500);
    this.haveDate = this.$router.currentRoute.params.page === "ContractManagement" && this.haveFlow;
  },
  watch: {
    // when open dialog, need to get contract item data
    dialog(value) {
      if (value) {
        this.getContractItemData();
      }
    },
    detailData() {
      if (this.isOnlySort) {
        this.isOpenDrag = true;
      } else {
        this.isOpenDrag = false;
      }
      this.detailData_copy = this.detailData;
    },
    detailThead() {
      if (this.detailThead.indexOf("準備") > -1) {
        this.theadRevise = ["名稱", "操作"];
      } else {
        this.theadRevise = ["名稱", "單位", "數量", "金額"];
      }
    },
  },
};
</script>
