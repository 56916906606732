<template>
  <div id="Notification">
    <v-card>
      <v-card-title>編輯顧客</v-card-title>
      <v-card-text>
        <!--search-->
        <!-- <div class="d-flex justify-end mt-2">
          <div class="d-flex" v-if="!notSearchFeature">
            <div class="d-flex align-center px-2">
              <v-btn text icon :disabled="$store.state.loading">
                <v-icon @click="refresh()">mdi-refresh</v-icon>
              </v-btn>
              <v-select v-model="nowSelectItem" :items="selectData" label="搜尋條件"></v-select>
            </div>
            <div class="d-flex align-center" v-if="searchSelect">
              <v-text-field clearable label="搜尋" :type="nowSelectItem === 'id' ? 'number' : 'text'" v-model="searchItem"
                append-icon="mdi-magnify" @keypress.enter="search(false, nowSelectItem, searchItem)"
                @click:append="search(false, nowSelectItem, searchItem)"></v-text-field>
            </div>
            <div class="d-flex align-center" v-else-if="nowSelectItem === 'isEnable'">
              <v-select v-model="nowSearchEableItem" :items="searchEable"></v-select>
              <div>
                <v-btn text icon :disabled="$store.state.loading"
                  @click="search(true, nowSelectItem, nowSearchEableItem)">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="d-flex align-center" v-else-if="nowSelectItem === 'isOrderByAsc'">
              <v-select v-model="nowSearchEableItem" :items="sortGroup"></v-select>
              <div>
                <v-btn text icon :disabled="$store.state.loading"
                  @click="search(true, nowSelectItem, nowSearchEableItem)">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="d-flex align-center" v-else-if="nowSelectItem === 'type'">
              <v-select v-model="nowSearchStatusItem" :items="typeGroup"></v-select>
              <div>
                <v-btn text icon :disabled="$store.state.loading"
                  @click="search(true, nowSelectItem, nowSearchStatusItem)">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="d-flex align-center" v-else-if="nowSelectItem === 'isFromSample'">
              <v-select v-model="nowSearchStatusItem" :items="fromSampleGroup"></v-select>
              <div>
                <v-btn text icon :disabled="$store.state.loading"
                  @click="search(true, nowSelectItem, nowSearchStatusItem)">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="d-flex align-center" v-else>
              <v-select v-model="nowSearchStatusItem" :items="searchStatus"></v-select>
              <div>
                <v-btn text icon :disabled="$store.state.loading"
                  @click="search(true, nowSelectItem, nowSearchStatusItem)">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div> -->
        <!--data table-->
        <div style="font-size: 1rem; font-weight: bold; color:gray; line-height: 2;"> 業者是否能在App檢視
          <input type="checkbox" v-model="dealerCanSee" @click="clickDealer">
        </div>
        <span style="font-size: 1rem; font-weight: bold; color:gray;"> 顧客列表 </span>
        <table style="border-collapse: collapse;width: 100%;">
          <thead>
            <tr style="border-bottom: 1px solid lightgray; font-weight: bold; line-height: 2;">
              <td style="width: 1rem; text-align: right; padding-right: 1rem;">選擇</td>
              <td style="width: 2rem;">編號</td>
              <td style="width: 3rem;">姓名</td>
              <td style="width: 3rem;">聯絡電話</td>
              <td style="width: 10rem;">備註</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in customers" :key="index">
              <td style="width: 1rem; text-align: right; padding-right: 1rem;">
                <input type="checkbox" v-model="item.hasRelation" @click="clickCustomer($event, item, false)">
              </td>
              <td style="width: 2rem;">{{ item.id }}</td>
              <td style="width: 3rem;">{{ item.name }}</td>
              <td style="width: 3rem;">{{ item.mobileNumber }}</td>
              <td style="width: 10rem;">{{ item.memo }}</td>
            </tr>
          </tbody>
        </table>
        <v-pagination v-if="totalPages > 0" v-model="page" :length="totalPages" :total-visible="7" circle
          @input="changePage" />
        <v-divider />
        <span style="font-size: 1rem; font-weight: bold; color:gray;"> 已選擇 </span>
        <table style="border-collapse: collapse;width: 100%;">
          <thead>
            <tr style="border-bottom: 1px solid lightgray; font-weight: bold;">
              <td style="width: 1rem; text-align: right; padding-right: 1rem;">選擇</td>
              <td style="width: 2rem;">編號</td>
              <td style="width: 3rem;">姓名</td>
              <td style="width: 3rem;">聯絡電話</td>
              <td style="width: 10rem;">備註</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in selected" :key="index">
              <td style="width: 1rem; text-align: right; padding-right: 1rem;">
                <input type="checkbox" v-model="item.hasRelation" @click="clickCustomer($event, item, true)">
              </td>
              <td style="width: 2rem;">{{ item.id }}</td>
              <td style="width: 3rem;">{{ item.name }}</td>
              <td style="width: 3rem;">{{ item.mobileNumber }}</td>
              <td style="width: 10rem;">{{ item.memo }}</td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="clickCloseBtn()"> 取消 </v-btn>
        <v-btn :disabled="disabled" color="green darken-1" text @click="clickConfirmBtn()">
          確認
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {
  createContractualRelatonship,
  deleteContractualRelatonship,
  getCustomersList,
  getContractualRelatonship,
  getRelationshipCustomers
} from "@/apis/DealerContractualRelationships"

export default {
  name: "CustomerList",
  props: ["action", "contractId"],
  data: () => ({
    totalPages: 0,
    customers: [],
    selected: [],
    originalSelected: [],
    dealerCanSee: false,
    page: 1
  }),
  methods: {
    clickCustomer(event, customer, isSelectedArea) {
      if (event.target.checked) {
        this.selected.push({
          hasRelation: true,
          id: customer.id,
          name: customer.name,
          memo: customer.memo
        });
      } else {
        const i = this.selected.indexOf(customer);
        if (i !== -1) {
          this.selected.splice(i, 1);
        }

        // 選擇區如果有要勾掉
        this.customers.find(x => x.id === customer.id).hasRelation = false;
      }

      if (isSelectedArea) {
        event.preventDefault();
      }
    },
    async clickDealer(event) {
      this.$store.commit("setLoading", true);
      if (event.target.checked) {
        let dealerId = Number(localStorage.getItem("userId"));
        await createContractualRelatonship(this.contractId, [{ relationId: dealerId, relationshipType: 0 }]);
      } else {
        await deleteContractualRelatonship(this.contractId, [{ relationId: dealerId, relationshipType: 0 }])
      }

      this.$store.commit("setLoading", false);
    },
    clickCloseBtn() {
      this.$emit("clickCloseBtn");
    },
    async clickConfirmBtn() {
      this.action = "create";
      await this.updateContractualRelatonship();
      this.$emit("clickConfirmBtn", { dealerId: 0, dealerContractId: 0 });
    },
    changePage() {
      this.getCustomers();
    },
    async getCustomers() {
      this.$store.commit("setLoading", true);
      const res = await getCustomersList({ contractId: this.contractId, page: this.page });
      this.customers = res.data.result.results;
      this.page = res.data.result.currentPage;
      this.totalPages = res.data.result.pageCount;
      this.$store.commit("setLoading", false);
    },
    async getHasRelationCustomers() {
      this.$store.commit("setLoading", true);
      const res = await getRelationshipCustomers({ contractId: this.contractId });
      this.originalSelected = structuredClone(res.data.result);
      this.selected = structuredClone(res.data.result);
      this.$store.commit("setLoading", false);
    },
    async updateContractualRelatonship() {
      this.$store.commit("setLoading", true);
      await this.createContractualRelatonship();
      await this.deleteContractualRelatonship();
      this.$store.commit("setLoading", false);
    },
    async createContractualRelatonship() {
      let datas = this.selected.filter(x => !(this.originalSelected.findIndex(y => y.id === x.id) !== -1));
      if (datas.length > 0) {
        let body = [];
        datas.forEach(x => body.push({
          relationId: x.id,
          relationshipType: 1
        }));
        await createContractualRelatonship(this.contractId, body);
      }
    },
    async deleteContractualRelatonship() {
      let datas = this.originalSelected.filter(x => !(this.selected.findIndex(y => y.id === x.id) !== -1));
      if (datas.length > 0) {
        let body = [];
        datas.forEach(x => body.push({
          relationId: x.id,
          relationshipType: 1
        }));
        await deleteContractualRelatonship(this.contractId, body);
      }
    }
  },
  async mounted() {
    await this.getCustomers();
    await this.getHasRelationCustomers();
    const res = await getContractualRelatonship(this.contractId);
    if (res.status === 200) {
      this.dealerCanSee = true;
    }
    else if (res.status === 404) {
      this.dealerCanSee = false;
    }
  }
};
</script>
