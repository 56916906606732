<template>
  <v-app>
    <components :is="componentName" :data="data" :baseUrl="baseUrl"></components>
  </v-app>
</template>

<script>
import { getSettings } from "@/apis/Settings";
import { getEObituariesContract } from "@/apis/EObituary";
import Template1 from "@/components/eObituaryTemplate/Template1.vue";
import Template2 from "@/components/eObituaryTemplate/Template2.vue";
import Template3 from "@/components/eObituaryTemplate/Template3.vue";
import Template4 from "../components/eObituaryTemplate/Template4.vue";

export default {
  name: "EObituaryTemplate",
  components: {
    Template1,
    Template2,
    Template3,
    Template4,
  },
  data() {
    return {
      data: {},
      baseUrl: "",
      splitDate: {},
      componentName: "",
    };
  },
  methods: {
    switchComponent() {
      switch (this.data.templateId) {
        case 1:
          this.componentName = "Template1";
          break;
        case 6:
          this.componentName = "Template2";
          break;
        case 8:
          this.componentName = "Template3";
          break;
        case 9:
          this.componentName = "Template4";
          break;
        default:
          this.componentName = "";
          break;
      }
    },
    splitDateFunction() {
      let revise = this.data.ceremonyTime.toString().split("");
      revise.splice(4, 0, "/");
      revise.splice(7, 0, "/");
      revise.splice(12, 0, ":");
      revise.splice(15, 0, ":");
      let date = revise.splice(0, 10);
      let time = revise.splice(0, 8);
      this.splitDate.date = date.toString().replaceAll(",", "");
      this.splitDate.time = time.toString().replaceAll(",", "");
    },
    async getData() {
      const id = this.$router.currentRoute.params.id;
      const res = await getEObituariesContract(id);
      this.data = res.data.result;
      this.splitDateFunction();
      document.title = `${this.data.name}${this.data.title} 追思告別儀式`;
      const newMeta = document.createElement("meta");
      newMeta.name = "description";
      newMeta.content = `hello`;
      document.head.append(newMeta);
      const meta = document.getElementsByTagName("meta");
      this.switchComponent();
    },
    async getSettings() {
      const res = await getSettings();
      if (res.status === 200) {
        this.baseUrl = res.data.result.storageDomain;
      }
    },
  },
  created() {
    this.getSettings();
    this.getData();
  },
};
</script>
<style></style>
