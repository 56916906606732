import http from '@/http/axios';
import { judgeError } from './Common';
import { error, success } from '@/utils/notification';

export default async function login(role, token, code, data) {
  const res = await http.post(`/api/${role}/Login?token=${token}&code=${code}`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  } else {
    success();
  }
  return res;
}
