<template>
  <div id="OperatorManagement">
    <v-card class="ma-4">
      <TablePage
        :targetName="'siteConfigTable'"
        :selectData="selectItem"
        :thead="thead"
        :keyGroup="keyGroup"
        :data="data"
        :canDelete="true"
        @refresh="search"
        @search="search"
        @openDialog="openDialog"
        @clickUpdateBtn="openInformationDialog"
        @clickDelete="clickDelete"
      />
      <v-pagination
        v-if="totalPage > 0"
        v-model="page"
        :length="totalPage"
        :total-visible="7"
        circle
        @input="changePage"
      ></v-pagination>
      <v-dialog
        v-model="informationDialog"
        width="90%"
        max-width="1200"
        @click:outside="closeInformationDialog"
        @keydown.esc="closeInformationDialog"
      >
        <v-card>
          <v-card-title>
            {{ dialogIndex == 0 ? "修改SiteConfig" : "新增SiteConfig" }}
          </v-card-title>
          <div class="pa-3">
            <SiteConfigsDialog
              :selectedData="selectedData"
              :mode="mode"
              @closeInformationDialog="closeInformationDialog"
              @modifyConfirmBtn="modifyConfirmBtn"
              @createConfirmBtn="createConfirmBtn"
            />
          </div>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import lodash from "lodash";
import SiteConfigsDialog from "@/components/siteConfigsManagement/SiteConfigsDialog.vue";
import {
  getSiteConfigs,
  createSiteConfigs,
  updateSiteConfigs,
  deleteSiteConfigs,
} from "@/apis/SiteConfigs";
import TablePage from "@/components/TablePage.vue";
export default {
  name: "SiteConfigsManagement",
  components: {
    SiteConfigsDialog,
    TablePage,
  },
  data() {
    return {
      params: {},
      page: 1,
      totalPage: 0,
      dialogIndex: 0,
      selectedData: {},
      selectItem: [{ text: "key", value: "name" }],
      thead: ["id", "名稱", "key", "值", "操作"],
      keyGroup: ["id", "displayName", "name", "value"],
      informationDialog: false,
      data: [],
      mode: "",
    };
  },
  methods: {
    async clickDelete(data) {
      this.$store.commit("setLoading", true);
      const res = await deleteSiteConfigs(data.id);
      if (res.status === 204) {
        this.getSiteConfigs();
      }
      this.$store.commit("setLoading", false);
    },
    async search(enableBoolean, nowSelectItem, data) {
      this.params = {};
      if (data) {
        this.params[nowSelectItem] = data;
      }
      this.getSiteConfigs();
    },
    openDialog() {
      this.mode = "";
      this.dialogIndex = 1;
      this.informationDialog = true;
    },
    async modifyConfirmBtn() {
      this.$store.commit("setLoading", true);
      const res = await updateSiteConfigs(this.selectedData.id, this.selectedData);
      if (res.status === 200) {
        this.getSiteConfigs();
        this.closeInformationDialog();
        this.selectedData = {};
        this.mode = "";
      }
      this.$store.commit("setLoading", false);
    },
    async createConfirmBtn() {
      this.$store.commit("setLoading", true);
      const res = await createSiteConfigs(this.selectedData);
      if (res.status === 200) {
        this.getSiteConfigs();
        this.closeInformationDialog();
        this.selectedData = {};
      }
      this.$store.commit("setLoading", false);
    },
    openInformationDialog(index) {
      this.dialogIndex = 0;
      if (typeof index === "number") {
        this.selectedData = lodash.cloneDeep(this.data[index]);
        this.selectedData.password = "1";
      }
      this.mode = "modify";
      this.informationDialog = true;
    },
    closeInformationDialog() {
      this.selectedData = {};
      this.informationDialog = false;
    },
    async changePage() {
      this.params.page = this.page;
      this.getSiteConfigs();
    },
    async getSiteConfigs() {
      this.$store.commit("setLoading", true);
      const res = await getSiteConfigs(this.params);
      this.$store.commit("setLoading", false);
      if (res.status === 200) {
        this.data = res.data.result;
      }
    },
  },
  created() {
    this.getSiteConfigs();
  },
};
</script>
