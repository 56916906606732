import Vue from "vue";
import { filterError, filterErrorMsg } from "@/apis/Common";

export function error(title, status, msg) {
  Vue.notify({
    group: "foo",
    title: `標題：${filterError(title)}`,
    text: `狀態碼： ${status}<br /> 訊息： ${filterErrorMsg(msg)}`,
    type: "error",
  });
}

export function errorString(title, status, msg) {
  Vue.notify({
    group: "foo",
    title: `標題：${title}`,
    text: `狀態碼： ${status}<br /> 訊息： ${msg}`,
    type: "error",
  });
}

export function success() {
  Vue.notify({
    group: "foo",
    title: "成功",
  });
}

export function copySuccess() {
  Vue.notify({
    group: "foo",
    title: "已複製連結",
  });
}

export function info(msg) {
  Vue.notify({
    group: "foo",
    title: "Important message",
    text: "Hello user! This is a notification!",
  });
}

export function userError(title, msg) {
  Vue.notify({
    group: "foo",
    title: title,
    text: msg,
    type: "error",
  });
}
