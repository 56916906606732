<template>
  <div id="DealerContractItem">
    <v-card>
      <v-card-title>
        {{ action === "update" ? "修改" : "創建" }}合約項目
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-card class="px-5 mb-3" v-if="action !== 'update'">
              <div class="d-flex justify-space-between w-100">
                <div class="d-flex">
                  <v-tabs @change="tabChange" v-model="selectedTabIndex">
                    <v-tab v-for="(tab, tabIndex) in tabList" :key="tabIndex">
                      {{ tab }}
                    </v-tab>
                  </v-tabs>
                </div>
              </div>
            </v-card>
            <Transfer
              v-if="selectedTabIndex === 0"
              :listData="listData"
              :refresh="refresh"
              :mode="mode"
              :sentListData="sentListData"
              @searchFeature="searchFeature"
              @judgeMode="judgeMode"
              @sentToList="sentToList"
              @cancelSentToList="cancelSentToList"
            />
            <v-card class="pa-5" v-else-if="selectedTabIndex === 1">
              <div class="">
                <div class="d-flex align-center">
                  <v-text-field
                    clearable
                    class="mr-1"
                    label="名稱"
                    hide-details
                    v-model="data.name"
                  ></v-text-field>
                  <v-text-field
                    clearable
                    class="mr-1"
                    label="單位"
                    hide-details
                    v-model="data.unit"
                  ></v-text-field>
                  <v-text-field
                    clearable
                    label="類別"
                    hide-details
                    v-model="data.category"
                  ></v-text-field>
                </div>
                <div class="my-2 d-flex align-end">
                  <v-textarea
                    hide-details
                    label="備註"
                    style="width: 100%"
                    v-model="data.memo"
                  ></v-textarea>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="clickCloseBtn()">
          取消
        </v-btn>
        <v-btn
          :disabled="disable"
          color="green darken-1"
          text
          @click="clickConfirmBtn()"
        >
          確認
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="hintDialog" width="100%" max-width="400">
      <v-card class="text-center">
        <v-card-text class="pa-5">
          <h3>確定要切換嗎？</h3>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error darken-1"
            text
            @click="hintDialog = false;selectedTabIndex = 0;"
          >
            取消
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="hintConfirm()"
          >
            確認
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Transfer from "../Transfer.vue";
import { getContractItemsSingle } from "@/apis/ContractItems";
export default {
  name: "DealerContractItem",
  props: ["action", "data", "refresh", 'hasData'],
  components: {
    Transfer,
  },
  data: () => ({
    listData: [],
    sentListData: [],
    mode: "",
    selectedTabIndex: 0,
    tabList: ["系統預設", "創建"],
    rules: {
      required: (value) => !!value || "必填",
    },
    hintDialog: false,
  }),
  methods: {
    async searchFeature(value) {
      this.listData = await this.getContractItemData(value);
      this.removeHaveData();
    },
    // when has data, not display
    judgeHasData() {
      this.hasData.forEach((item) => {
        const itemReviseName = `${item.name}/${item.unit}/${item.category}`;
        this.listData.forEach((val) => {
          if (itemReviseName === val.revise_name) {
            let json = val;
            json.hasValue = true;
            this.sentListData.push(json);
          }
        });
      });
      this.removeHaveData();
    },
    // judge mode is right to left or left to right
    judgeMode(value, selectedList) {
      if (selectedList.length > 0) {
        this.mode = value;
      } else {
        this.mode = "";
      }
    },
    sentToList(data) {
      this.sentListData.push(...data);
      let removeArr = [];
      this.listData.forEach((item, index) => {
        data.forEach((value) => {
          if (item.revise_name === value.revise_name) {
            removeArr.unshift(index);
          }
        });
      });
      removeArr.forEach((item) => {
        this.listData.splice(item, 1);
      });
    },
    // right to left button (add item to list)
    removeHaveData() {
      // this.sentListData = [];
      // this.sentListData.push(...selectedList);
      let removeArr = [];
      this.listData.forEach((item, index) => {
        this.sentListData.forEach((value) => {
          if (item.revise_name === value.revise_name) {
            removeArr.unshift(index);
          }
        });
      });
      removeArr.forEach((item) => {
        this.listData.splice(item, 1);
      });
      this.$store.commit("setLoading", false);
      this.mode = "";
    },
    // left to right button (remove item to list)
    cancelSentToList(selectedList) {
      this.listData.push(...selectedList);
      this.sentListData = this.sentListData.filter(
        (item) => !selectedList.includes(item)
      );
      this.mode = "";
    },
    clickCloseBtn() {
      this.mode = "";
      this.sentListData = [];
      this.$emit("clickCloseBtn");
    },
    // judge selected tab for return data
    judgeTab() {
      let data = [];
      if (this.selectedTabIndex === 0) {
        this.sentListData.forEach((item) => {
          if (!item.hasValue) {
            data.push({
              name: item.name,
              unit: item.unit,
              category: item.category,
              isFromSample: true,
              isEnable: item.isEnable,
            });
          }
        });
      } else {
        data.push(this.data);
        data[0].isEnable = true;
        data[0].isFromSample = false;
      }
      return data;
    },
    clickConfirmBtn() {
      const data = this.judgeTab();
      this.$emit("clickConfirmBtn", data);
    },
    async initData() {
      this.$store.commit("setLoading", true);
      this.sentListData = [];
      this.listData = await this.getContractItemData();
      await setTimeout(() => {
        this.judgeHasData();
      }, 500);
    },
    // get system's contract item data
    async getContractItemData(value) {
      this.$store.commit("setLoading", true);
      this.listData = [];
      let query = {};
      if (value) {
        query.name = value;
      }
      const { data } = await getContractItemsSingle(query);
      let result = data.result;
      result.forEach((item, index) => {
        result[index].revise_name = `${item.name}/${item.unit}/${item.category}`;
      });
      this.$store.commit("setLoading", false);
      return result;
    },
    // click hint dialog's confirm
    hintConfirm() {
      this.initData();
      this.selectedTabIndex = 1;
      this.hintDialog = false;
    },
    // when change tab, judge sentListData's length
    tabChange(index) {
      if (this.hasData.length !== this.sentListData.length && index === 1) {
        this.hintDialog = true;
      } else {
        this.selectedTabIndex = index;
      }
    },
  },
  created() {
    if (this.data.id) {
      this.selectedTabIndex = 1;
    }
    this.initData();
  },
  watch: {
    // when open dialog, need to get contract item data
    refresh(value) {
      if (value) {
        if (this.data.id) {
          this.selectedTabIndex = 1;
        }
        if (this.action === 'create') {
          this.data.category = '無';
        }
        this.initData();
      }
    },
  },
  computed: {
    disable() {
      if (this.selectedTabIndex === 0) {
        return false;
      }
      return !this.data.name || !this.data.unit || !this.data.category;
    },
  },
};
</script>
