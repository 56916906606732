import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    view: '',
    name: '',
    StorageDomain: '',
    isCancelToken: false,
  },
  mutations: {
    cancelToken(state, value) {
      state.isCancelToken = value;
    },
    setStorageDomain(state, value) {
      state.StorageDomain = value;
    },
    setLoading(state, value) {
      state.loading = value;
    },
    setView(state, value) {
      state.view = value;
    },
    setName(state, value) {
      state.name = value;
    }
  },
  actions: {},
  modules: {},
});
