<template>
  <div id="CustomerManagement">
    <v-card class="ma-4">
      <div class="d-flex pa-3">
        <v-tabs v-model="selectedTabIndex">
          <v-tab v-for="(tab, tabIndex) in tabList" :key="tabIndex" v-show="tab.value !== ''">
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </div>
    </v-card>
    <v-card class="ma-4">
      <TablePage :targetName="'customerTable'" :selectData="selectItem" :thead="thead" :keyGroup="keyGroup" :data="data"
        :isCusomterRelation="true" :shareCode="false" @clickUpdateBtn="clickUpdateBtn" @search="search"
        @clickDetail="clickDetail" @openDialog="openDialog" />
      <v-pagination v-if="totalPage > 0" v-model="page" :length="totalPage" :total-visible="7" circle
        @input="changePage"></v-pagination>
      <v-dialog v-model="dialog" width="90%" max-width="1200" @click:outside="clickCloseBtn"
        @keydown.esc="clickCloseBtn">
        <component :is="this.createBtn.value" :action="action" :refresh="dialog" :data="selectedData"
          @clickCloseBtn="clickCloseBtn" @clickConfirmBtn="clickConfirmBtn"></component>
      </v-dialog>

      <v-dialog v-model="contractDialog" width="90%" max-width="1200" scrollable @click:outside="closeContractDialog"
        @keydown.esc="closeContractDialog">
        <v-card>
          <v-card-title> 合約列表 </v-card-title>
          <div class="pl-10 pb-10">
            <table style="border-collapse: collapse; text-align: center;">
              <thead>
                <tr style="border-bottom: 1px solid lightgray; font-weight: bold; line-height: 2;">
                  <td style="width: 5rem;">
                    姓名
                  </td>
                  <td style="width: 5rem;">
                    稱謂
                  </td>
                  <td style="width: 3rem;">
                    性別
                  </td>
                  <td style="width: 6rem;">
                    過世日期
                  </td>
                  <td style="width: 8rem;">
                    過世日期（農曆）
                  </td>
                  <td style="width: 6rem;">
                    聯絡電話
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr v-for=" (item, index) in contractList" :key="index" style="line-height: 2">
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    {{ item.title }}
                  </td>
                  <td>
                    {{ item.gender === 1 ? '男' : '女' }}
                  </td>
                  <td>
                    {{ item.passAwayDate | dateFilter }}
                  </td>
                  <td>
                    {{ item.passAwayLunarDate | dateFilter }}
                  </td>
                  <td>
                    {{ item.tel }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card>
      </v-dialog>

      <v-dialog v-model="isCopyDialog" max-width="500">
        <v-card class="pa-4 d-flex justify-center" style="font-size: 18px">
          <input id="shareCodeInput" type="text" readonly :value="shareCodeUrl"
            style="border-radius: 0px; background-color: #f2f2f2" class="px-2 py-1" />
          <button class="primary px-2 py-1" style="border-radius: 0; color: white" @click="copyText()">
            複製
          </button>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import { copySuccess } from "@/utils/notification";
import TablePage from "@/components/TablePage.vue";
import DealerCustomers from "@/components/customerManagement/DealerCustomers.vue";
import FamilyTable from "@/components/customerManagement/FamilyTable.vue";
import {
  getDealerCustomersList,
  updateDealerCustomers,
  createDealerCustomers,
  giveStatusDealerCustomers,
  getCustomersFamily,
  getCustomersShareCode,
} from "@/apis/DealerCustomers";
import {
  getCustomerContractList
} from "@/apis/DealerContractualRelationships";

export default {
  name: "CustomerManagement",
  components: {
    TablePage,
    DealerCustomers,
    FamilyTable,
  },
  data() {
    return {
      contractDialog: false,
      familyDatas: [],
      createBtn: {
        name: "",
        value: "",
      },
      thead: [],
      keyGroup: [],
      selectItem: [],
      selectedData: {},
      data: [],
      tabList: [
        {
          name: "客戶",
          value: "DealerCustomers",
        },
      ],
      selectedTabIndex: 0,
      dialog: false,
      action: "",
      totalPage: 0,
      page: 0,
      dealerId: 0,
      shareCodeUrl: "",
      isCopyDialog: false,
      contractList: []
    };
  },
  methods: {
    async changePage() {
      this.params.page = this.page;
      this.getCustomerList();
    },
    async haveSelectedTab() {
      this.createBtn = this.tabList[this.selectedTabIndex];
      this.selectItem = [
        { text: "Id", value: "id" },
        { text: "名稱", value: "name" },
        { text: "電話", value: "mobileNumber" },
        { text: "狀態", value: "status" },
      ];
      this.thead = [
        "編號",
        "業者編號",
        "名稱",
        "狀態",
        "手機",
        "備註",
        "建立時間",
        "創建人員",
        "更新時間",
        "更新人員",
        "登入時間",
        "操作",
      ];
      this.keyGroup = [
        "id",
        "dealerId",
        "name",
        "status_revise",
        "mobileNumber",
        "memo",
        "createTime",
        "createUser",
        "updateTime",
        "updateUser",
        "signInTime",
      ];
      this.getCustomerList();
    },
    async clickConfirmBtn(data) {
      const functionArr = [createDealerCustomers, updateDealerCustomers];
      const functionStatus = [giveStatusDealerCustomers, new Function()];
      const index = this.action === "create" ? 0 : 1;
      data.dealerId = this.dealerId;
      if (this.action === "update" && data.isChangeStatus) {
        await giveStatusDealerCustomers(data.id, data.status);
      }
      const res = await functionArr[index](data);
      if (res.status === 200) {
        await functionStatus[index](res.data.result.id, 1);
        this.clickCloseBtn();
        this.getCustomerList();
      }
    },
    copyText() {
      let input = document.querySelector("#shareCodeInput");
      input.select();
      document.execCommand("copy");
      copySuccess();
      this.isCopyDialog = false;
    },
    async clickShareCode(data) {
      const res = await getCustomersShareCode(data.id);
      if (res.status === 200) {
        // pc
        this.shareCodeUrl = `${Vue.prototype.baseURL}regist?shareCode=${res.data.result}`;

        // navigator.clipboard.writeText(url);

        // mobile
        // let copyInput = document.createElement("input");
        // copyInput.value = url;
        // document.body.appendChild(copyInput);
        // copyInput.select();
        // document.execCommand("Copy");
        // document.body.removeChild(copyInput);
        this.isCopyDialog = true;
      }
    },
    clickCloseBtn() {
      this.dialog = false;
      this.action = "";
      this.selectedData = {};
    },
    async search(enableBoolean, nowSelectItem, data) {
      this.params = { dealerId: this.dealerId };
      if (data) {
        this.params[nowSelectItem] = data;
      }
      this.getCustomerList();
    },
    clickUpdateBtn(index) {
      this.openDialog("update", index);
    },
    openDialog(action, index) {
      this.action = action;
      this.dialog = true;
      if (typeof index === "number") {
        this.selectedData = this.data[index];
      } else {
        this.selectedData = {};
      }
    },
    filterStatus(data) {
      switch (data) {
        case 0:
          return "等待審核";
        case 1:
          return "正常";
        case 2:
          return "停用";
        case 3:
          return "禁用";
        default:
          return "";
      }
    },
    async getCustomerList() {
      this.$store.commit("setLoading", true);
      const res = await getDealerCustomersList(this.params);
      if (res.status === 200) {
        this.data = res.data.result.results;
        this.data.forEach((item, index) => {
          this.data[index].status_revise = this.filterStatus(item.status);
        });
        this.totalPage = res.data.result.pageCount;
        this.page = res.data.result.currentPage;
      }
      this.$store.commit("setLoading", false);
    },
    clickDetail(data) {
      this.selectedData = data;
      //this.getCustomerFamily();
      this.getCustomerContractList();
      this.contractDialog = true;
    },
    closeContractDialog() {
      this.selectedData = {};
      this.contractDialog = false;
    },
    async getCustomerFamily() {
      const res = await getCustomersFamily({ relationKeyId: this.selectedData.relationKeyId });
      if (res.status === 200) {
        this.familyDatas = res.data.result.results;
      }
    },
    async getCustomerContractList() {
      const res = await getCustomerContractList({ relationId: this.selectedData.id, relationType: 1 });
      if (res.status === 200) {
        this.contractList = res.data.result;
      }
    }
  },
  created() {
    this.dealerId = localStorage.getItem("userId");
    this.params = {
      dealerId: this.dealerId,
    };
    this.haveSelectedTab();
  },
};
</script>
