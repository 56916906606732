<template>
  <div id="Notification">
    <v-card>
      <v-card-title> {{ action === "update" ? "修改" : "創建" }}通知 </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <div class="d-flex">
              <v-sheet>
                <v-switch
                  @change="switchSelect()"
                  v-model="switchFestival"
                  inset
                  :label="`選擇節日`"
                ></v-switch>
              </v-sheet>
            </div>

            <div>
              <div class="d-flex mb-2">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  v-if="!switchFestival"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      class="mr-1"
                      label="節日日期"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    locale="zh-cn"
                    v-model="date"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
                <div class="d-flex mb-2" v-else style="width: 100%">
                  <v-select
                    class="mr-1"
                    :items="yearsData"
                    hide-details
                    label="年份"
                    v-model="selectFestivalYear"
                    @change="getFestival"
                    style="width: 33%"
                  ></v-select>
                  <v-select
                    @change="setFestivalData"
                    class="mr-1"
                    :items="festivalData"
                    item-text="lunarDate"
                    item-value="date"
                    hide-details
                    label="選擇節日"
                    v-model="selectFestivalData"
                    style="width: 33%"
                  ></v-select>
                </div>
                <DateTimePicker
                  :label="'通知時間'"
                  :hasSecond="true"
                  :value="date2"
                  @confirmDateTime="confirmDateTime"
                  :style="switchFestival ? 'width:34%' : 'width:50%'"
                />
              </div>
              <div class="d-flex mb-2">
                <v-text-field
                  clearable
                  class="mr-1 input"
                  label="節日名稱"
                  hide-details
                  v-model="createData.dateName"
                  @input="checkDisable()"
                >
                </v-text-field>
                <v-select
                  :items="statusData"
                  hide-details
                  label="狀態"
                  v-model="createData.status"
                ></v-select>
              </div>
              <div class="d-flex mb-2">
                <v-textarea name="input-7-4" v-model="createData.content" label="內文"></v-textarea>
              </div>
            </div>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="clickCloseBtn()"> 取消 </v-btn>
        <v-btn :disabled="disabled" color="green darken-1" text @click="clickConfirmBtn()">
          確認
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { getNotificationLunisolar } from "@/apis/Notification";
import DateTimePicker from "@/components/DateTimePicker.vue";
export default {
  name: "Notification",
  props: ["action", "data", "refresh"],
  data: () => ({
    rules: {
      required: (value) => !!value || "必填",
    },
    switchFestival: false,
    dealerId: 0,
    menu: false,
    menu2: false,
    disabled: true,
    createData: {
      date: 0,
      dateName: "",
      type: 1,
      content: "",
      status: 0,
      notifyTime: 0,
    },
    date2: null,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    selectFestivalYear: new Date().getFullYear(),
    yearsData: [
      new Date().getFullYear(),
      new Date().getFullYear() + 1,
      new Date().getFullYear() + 2,
    ],
    selectFestivalData: {},
    festivalData: [],
    contractData: [],
    statusData: [
      {
        text: "草稿",
        value: 0,
      },
      {
        text: "未發送",
        value: 1,
      },
      {
        text: "已送達",
        value: 2,
      },
      {
        text: "取消",
        value: 3,
      },
    ],
  }),
  methods: {
    confirmDateTime(dateTime) {
      this.date2 = dateTime;
      this.checkDisable();
      this.$forceUpdate();
    },
    initFestival() {
      this.selectFestivalData = this.festivalData[0];
      this.createData.date = this.festivalData[0].date;
      this.createData.dateName = this.festivalData[0].holidays.join("");
      this.createData.content = this.festivalData[0].content;
    },
    async getFestival() {
      const res = await getNotificationLunisolar(this.selectFestivalYear);
      this.festivalData = res.data.result;
      if (!this.createData.id) {
        this.initFestival();
      } else {
        this.festivalData.forEach((item) => {
          if (item.date === this.createData.date) {
            this.selectFestivalData = item.date;
          }
        });
      }
      this.checkDisable();
    },
    setFestivalData() {
      const data = (element) => element.date === this.selectFestivalData;
      const index = this.festivalData.findIndex(data);
      this.createData.date = this.festivalData[index].date;
      this.createData.dateName = this.festivalData[index].holidays.join(", ");
      this.createData.content = this.festivalData[index].content;
      this.checkDisable();
    },
    switchSelect() {
      if (this.switchFestival) {
        this.createData.type = 0;
        this.getFestival();
      } else {
        this.createData = {
          date: 0,
          dateName: "",
          type: 1,
          content: "",
          status: 0,
          notifyTime: 0,
        };
        this.checkDisable();
      }
    },
    clickCloseBtn() {
      this.$emit("clickCloseBtn");
    },
    filterDateTime(date) {
      return date.replaceAll("-", "").replaceAll(" ", "").replaceAll(":", "");
    },
    clickConfirmBtn() {
      if (!this.createData.content) {
        this.createData.content = "";
      }
      if (!this.switchFestival) {
        this.createData.date = parseInt(this.date.split("-").join(""));
      }
      this.createData.notifyTime = this.filterDateTime(this.date2);
      let changeStatus = -1;
      this.statusData.forEach((item) => {
        if (item.text === this.createData.revise_status) {
          changeStatus = item.value;
        }
      });
      if (changeStatus !== this.createData.status) {
        this.createData.changeStatus = true;
      }
      this.$emit("clickConfirmBtn", this.createData);
    },
    switchDateTime(dateTime) {
      const date = dateTime.toString().split("");
      const year = Number(date.splice(0, 4).toString().replaceAll(",", ""));
      const month = Number(date.splice(0, 2).toString().replaceAll(",", ""));
      const day = Number(date.splice(0, 2).toString().replaceAll(",", ""));
      const hours = Number(date.splice(0, 2).toString().replaceAll(",", ""));
      const minutes = Number(date.splice(0, 2).toString().replaceAll(",", ""));
      return `${year}-${month >= 10 ? month : "0" + month}-${day >= 10 ? day : "0" + day} ${
        hours >= 10 ? hours : "0" + hours
      }:${minutes >= 10 ? minutes : "0" + minutes}`;
    },
    checkDisable() {
      if (!this.createData.dateName || !this.date2) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
  },
  created() {
    this.createData = this.data;
    this.createData.type = 1;
    if (this.createData.type === 0) {
      this.switchFestival = true;
      this.getFestival();
    }
    this.dealerId = localStorage.getItem("userId");
    if (this.action === "update") {
      const date = this.data.date.toString().split("");
      date.splice(4, 0, "-");
      date.splice(7, 0, "-");
      this.date = date.toString().replaceAll(",", "");
      this.date2 = this.switchDateTime(this.data.notifyTime);
    }
  },
  watch: {
    refresh(value) {
      if (value) {
        this.createData = this.data;
        this.createData.type = 1;
        if (this.createData.type === 0) {
          this.switchFestival = true;
          this.getFestival();
        } else {
          this.switchFestival = false;
        }
      }
    },
    action(value) {
      if (value === "update") {
        const date = this.data.date.toString().split("");
        date.splice(4, 0, "-");
        date.splice(7, 0, "-");
        this.date = date.toString().replaceAll(",", "");
        this.date2 = this.switchDateTime(this.data.notifyTime);
      }
    },
  },
  components: { DateTimePicker },
};
</script>
