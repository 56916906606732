<template>
  <div id="CreateContractDetail">
    <CheckGroup
      :thead="thead"
      :listData="listData"
      :refresh="dialog"
      :mode="mode"
      :haveDealerContractItem="true"
      :sentListData="sentListData"
      @searchFeature="getContractItemData"
      @judgeMode="judgeMode"
      @sentToList="sentToList"
      @cancelSentToList="cancelSentToList"
      @sentConfirm="clickConfirmBtn"
      @clickCloseDetailBtn="clickCloseDetailBtn"
      />
  </div>
</template>

<script>
import CheckGroup from '@/components/CheckGroup.vue';
import {
  getContractItemsSingle,
} from '@/apis/ContractItems';
export default {
  name: 'CreateContractDetail',
  props: ['action', 'data', 'refresh', 'selectedSampleDetailId', 'detailData', 'dialog'],
  components: {
    CheckGroup,
  },
  data: () => ({
    thead: ['項目名稱', '單位', '數量', '金額'],
    listData: [],
    sentListData: [],
    mode: '',
    rules: {
      required: (value) => !!value || "必填",
    },
  }),
  methods: {
    judgehaveChecked() {
      this.detailData.forEach((item) => {
        let boolean = true;
        const itemJson = {
          name: item.name,
          category: item.category,
        };
        this.listData.forEach((value, index) => {
          const valueJson = {
            name: value.name,
            category: value.category,
          };
          if (JSON.stringify(itemJson) === JSON.stringify(valueJson)) {
            this.listData[index].checked = true;
            this.listData[index].quantity = item.quantity;
            this.listData[index].unit = item.unit;
            this.listData[index].unitAmount = item.unitAmount;
            boolean = false;
          }
        });
        if (boolean) {
          this.listData.push({
            dealerId: item.dealerId,
            name: item.contractItemName,
            id: item.id,
            contractId: item.contractId,
            quantity: item.quantity,
            sort: item.sort,
            unit: item.unit,
            unitAmount: item.unitAmount,
            checked: true,
          });
        }
      });
    },
    // judge mode is right to left or left to right
    judgeMode(value, selectedList) {
      if (selectedList.length > 0) {
        this.mode = value;
      } else {
        this.mode = '';
      }
    },
    // right to left button (add item to list)
    sentToList(selectedList) {
      this.sentListData.push(...selectedList);
      this.listData = this.listData.filter((item) => !selectedList.includes(item));
      this.mode = '';
    },
    // left to right button (remove item to list)
    cancelSentToList(selectedList) {
      this.listData.push(...selectedList);
      this.sentListData = this.sentListData.filter((item) => !selectedList.includes(item));
      this.mode = '';
    },
    clickCloseDetailBtn() {
      this.mode = '';
      this.sentListData = [];
      this.$emit('clickCloseBtn');
    },
    // judge selected tab for return data
    judgeData(datas) {
      let data = [];
      datas.forEach((item) => {
        data.push({
          dealerId: localStorage.getItem('userId'),
          contractId: this.data.id,
          contractItemName: item.name,
          quantity: item.quantity,
          unit: item.unit,
          unitAmount: item.unitAmount,
        });
      });
      return data;
    },
    clickConfirmBtn(datas) {
      const data = this.judgeData(datas);
      this.$emit('createDetail', data);
      this.getContractItemData();
      this.clickCloseDetailBtn();
    },
    // get system's contract item data
    async getContractItemData(value) {
      this.listData = [];
      let query = {};
      if (value) {
        query.name = value;
      }
      const { data } = await getContractItemsSingle(query);
      this.listData = data.result;
      this.judgehaveChecked();
    },
  },
  created() {
    this.getContractItemData();
  },
  watch: {
    // when open dialog, need to get contract item data
    refresh(value) {
      if (value) {
        this.getContractItemData();
      }
    },
  },
};
</script>
