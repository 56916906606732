<template>
  <div id="ModifyPage">
    <v-card>
      <v-card-title> {{ action === "update" ? "修改" : "創建" }}業者 </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <div class="d-flex mb-2">
              <v-text-field
                clearable
                class="input px-2 py-1"
                label="帳號"
                hide-details
                v-model="data.accountName"
                :disabled="action === 'update'"
              ></v-text-field>
              <v-text-field
                v-if="action !== 'update'"
                clearable
                class="ml-1 input px-2 py-1"
                label="密碼"
                id="password"
                hide-details
                type="password"
                v-model="data.password"
              ></v-text-field>
            </div>
            <div class="d-flex mb-2" v-if="action === 'update'">
              <v-text-field
                clearable
                id="newPassword"
                class="px-2 py-1"
                label="新密碼"
                hide-details
                :type="newPasswordShow ? 'text' : 'password'"
                :append-icon="newPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="newPasswordShow = !newPasswordShow"
                v-model="data.newPassword"
              ></v-text-field>
            </div>
            <div class="d-flex mb-2">
              <v-text-field
                class="input px-2 py-1"
                clearable
                style="width: 100%"
                label="業者名稱"
                hide-details
                v-model="data.name"
              ></v-text-field>
              <v-select
                class="px-2 py-1"
                hide-details
                v-model="data.status"
                :items="statusDatas"
                item-text="name"
                item-value="value"
                label="狀態"
              ></v-select>
            </div>
            <div class="d-flex mb-2">
              <v-text-field
                clearable
                class="input px-2 py-1"
                label="統一編號"
                hide-details
                v-model="data.uniformNumbers"
                :disabled="action === 'update'"
              ></v-text-field>
            </div>
            <v-textarea
              class="input px-2 py-1"
              hide-details
              label="備註"
              style="width: 100%"
              v-model="data.memo"
            ></v-textarea>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <div class="d-flex justify-end pt-3">
          <v-btn color="error darken-1" text @click="clickCloseBtn()"> 取消 </v-btn>
          <v-btn :disabled="disable" color="green darken-1" text @click="clickConfirmBtn()">
            確認
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ModifyPage",
  props: ["data", "statusData", "mode", "action", "refresh"],
  data: () => ({
    rules: {
      required: (value) => !!value || "必填",
    },
    newPasswordShow: false,
    statusDatas: [
      { name: "等待審核", value: 0 },
      { name: "正常", value: 1 },
      { name: "停用", value: 2 },
      { name: "禁用", value: 3 },
    ],
  }),
  methods: {
    appendDisableAuto() {
      if (this.action === "update") {
        document.getElementById("newPassword").autocomplete = "new-password";
      } else {
        document.getElementById("password").autocomplete = "new-password";
      }
    },
    clickCloseBtn() {
      this.$emit("clickCloseBtn");
    },
    clickConfirmBtn() {
      this.$emit("clickConfirmBtn", this.data);
    },
  },
  computed: {
    disable() {
      return !this.data.accountName || !this.data.password || !this.data.name;
    },
  },
  mounted() {
    this.appendDisableAuto();
  },
  watch: {
    refresh() {
      if (this.refresh) {
        this.$nextTick(() => {
          this.appendDisableAuto();
        });
      }
    },
  },
};
</script>
