import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import EObituaryTemplate from "../views/EObituaryTemplate.vue";
import PrivacyAgreement from "../views/PrivacyAgreement.vue";
import Support from "../views/Support.vue";
import Disclaimer from "../views/Disclaimer.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/Login",
  },
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/PrivacyAgreement",
    name: "PrivacyAgreement",
    component: PrivacyAgreement,
  },
  {
    path: "/Support",
    name: "Support",
    component: Support,
  },
  {
    path: "/Disclaimer",
    name: "Disclaimer",
    component: Disclaimer,
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
  },
  {
    path: "/:page",
    name: "Home",
    component: Home,
  },
  // {
  //   path: "/EObituaries/:id",
  //   name: "EObituaries",
  //   component: EObituaryTemplate,
  // },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const getToken = localStorage.getItem("token");
  if (
    to.name !== "Login" &&
    to.name !== "EObituaries" &&
    to.name !== "PrivacyAgreement" &&
    to.name !== "Disclaimer" &&
    to.name !== "Support" &&
    (getToken === null || getToken === undefined)
  ) {
    next("/login");
  } else {
    next();
  }
});

export default router;
