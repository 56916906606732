<template>
  <div id="FamilyTable">
    <div class="d-flex justify-end"></div>
    <v-simple-table>
      <!-- 要可以排序 -->
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" v-for="(th, thIndex) in theadData" :key="thIndex">
              {{ th }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in familyDatas" :key="index" v-show="familyDatas.length > 0">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.mobileNumber }}</td>
            <td>
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="openDialog(item)" icon color="primary" v-bind="attrs" v-on="on">
                    <v-icon>mdi-swap-horizontal</v-icon>
                  </v-btn>
                </template>
                <span>移動家族</span>
              </v-tooltip>
            </td>
          </tr>
          <tr v-show="familyDatas.length <= 0" class="text-center">
            <td colspan="4">無資料</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="d-flex justify-end pt-3">
      <v-btn color="error darken-1" text @click="closeDialog()"> 取消 </v-btn>
    </div>
    <v-dialog
      v-model="dialog"
      width="90%"
      max-width="1200"
      @click:outside="closeSelectedDialog"
      @keydown.esc="closeSelectedDialog"
    >
      <v-card>
        <v-card-title> 選取來移動家族 </v-card-title>
        <div class="pa-3">
          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="10">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">編號</th>
                      <th>名稱</th>
                      <th>操作</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in allFamilyDatas"
                      :key="index"
                      v-show="allFamilyDatas.length > 0"
                    >
                      <td>{{ item.id }}</td>
                      <td>{{ item.name }}</td>
                      <td>
                        <v-checkbox
                          class="ma-0"
                          @click="selectedRadioId = item.relationKeyId"
                          v-model="selectedRadioId"
                          :value="item.relationKeyId"
                          hide-details
                        ></v-checkbox>
                      </td>
                    </tr>
                    <tr v-show="allFamilyDatas.length <= 0" class="text-center">
                      <td colspan="4">無資料</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="1"></v-col>
          </v-row>
          <div class="d-flex justify-end pt-3">
            <v-btn color="error darken-1" text @click="closeSelectedDialog()"> 取消 </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="createRelationKey()"
              :disabled="selectedRadioId === -1"
            >
              確認
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { getCustomersFamily, postCustomersRelationKey } from "@/apis/DealerCustomers";
export default {
  name: "FamilyTable",
  props: ["selectedData", "familyDatas"],
  data: () => ({
    theadData: ["序號", "名稱", "電話", "操作"],
    dialog: false,
    data: {
      type: 0,
    },
    allFamilyDatas: [],
    selectedCustomer: {},
    selectedRadioId: -1,
  }),
  methods: {
    closeSelectedDialog() {
      this.selectedCustomer = {};
      this.selectedRadioId = -1;
      this.dialog = false;
    },
    openDialog(data) {
      this.selectedCustomer = data;
      this.getAllFamily();
      this.dialog = true;
    },
    async getAllFamily() {
      const res = await getCustomersFamily({});
      if (res.status === 200) {
        this.allFamilyDatas = res.data.result.results;
      }
    },
    async createRelationKey() {
      const req = {
        dealerCustomerId: this.selectedCustomer.id,
        relationKeyId: this.selectedRadioId,
      };
      const res = await postCustomersRelationKey(this.selectedCustomer.dealerId, req);
      if (res.status === 200) {
        this.closeSelectedDialog();
        this.closeDialog();
      }
    },
    async removeItem(data) {
      // await deleteDealersContactInfos(data.id);
      this.getCustomerFamily();
    },
    openUpdate(data) {
      this.data = _.cloneDeep(data);
      this.dialog = true;
    },
    closeDialog() {
      this.$emit("closeContractDialog");
    },
    async getCustomerFamily() {
      this.$emit("getCustomerFamily");
    },
    closeContractDialog() {
      this.data = {};
      this.dialog = false;
    },
    addContactInfo() {
      this.dialog = true;
    },
    async addContactAction() {
      if (this.data.id === undefined) {
        const data = {
          dealerId: this.selectedData.id,
          type: Number(this.data.type),
          content: this.data.content,
        };
        await this.$emit("addContactAction", data);
      } else {
        this.data.type = Number(this.data.type);
        await this.$emit("updateContactAction", this.data);
      }
      this.data = {
        type: 0,
      };
      this.dialog = false;
      setTimeout(() => {
        this.getCustomerFamily();
      }, 500);
    },
  },
  computed: {
    disable() {
      return !this.data.content || this.data.type === undefined;
    },
  },
};
</script>
