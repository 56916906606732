import http from '@/http/axios';
import { judgeError, queryFilter } from './Common';
import { error, success } from '@/utils/notification';

export async function getFlowItemsList(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/FlowItems/List${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  }
  return res;
}

export async function getFlowItems(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/FlowItems${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  }
  return res;
}

export async function createFlowItems(data) {
  const res = await http.post('/api/FlowItems', data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  } else {
    success();
  }
  return res;
}

export async function updateFlowItems(data) {
  const res = await http.put(`/api/FlowItems/${data.id}`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  }
  return res;
}

export async function disableFlowItems(id) {
  const res = await http.post(`/api/FlowItems/${id}/Disable`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  }
  return res;
}

export async function enableFlowItems(id) {
  const res = await http.post(`/api/FlowItems/${id}/Enable`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  }
  return res;
}
