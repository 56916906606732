<template>
  <div id="PrivacyAgreement">
    <v-app>
      <v-dialog persistent width="1000" v-model="dialog" hide-overlay>
        <v-card style="font-size: 18px" class="text-center px-5">
          <div style="font-size: 26px; font-weight: 900" class="py-4">APP隱私權協議</div>
          <p>
            歡迎使用我們的app！我們非常重視您的隱私權，並承諾保護您的個人信息。本隱私權協議描述我們如何收集、使用、共享和保護您的個人信息，並向您說明您的選擇權限。如果您有任何疑問或擔憂，請與我們聯繫。
          </p>
          <p class="text-left">
            <ul style="list-style-type: cjk-ideographic">
              <li>
                <div>收集的個人信息</div>
                <p>我們可能會收集您的以下個人信息：</p>
                <ul style="list-style-type: decimal;">
                  <li>設備信息：包括您的設備型號、操作系統版本、唯一裝置識別號和移動網絡信息等。</li>
                  <li>日誌信息：包括您使用我們的app的日期和時間、搜索詞和點擊數、查看內容和使用時間長短等。</li>
                  <li>位置信息：包括您的大致位置信息，以及您設置的任何位置偏好。</li>
                  <li>其他信息：包括您在我們的app上提供的任何其他信息，如姓名、性別、年齡、電子郵件地址、電話號碼等。</li>
                </ul>
              </li>
              <li>
                <div>使用的個人信息</div>
                <p>我們可能會將您的個人信息用於以下用途：</p>
                <ul style="list-style-type: decimal;">
                  <li>提供服務：我們使用您的個人信息來向您提供我們的app服務，並改善我們的app。</li>
                  <li>個性化體驗：我們使用您的個人信息來定制和個性化您的app體驗，例如推薦特定的內容、服務或廣告。</li>
                  <li>經營和分析：我們使用您的個人信息來了解和分析我們的app使用情況，並改進我們的業務和服務。</li>
                  <li>與第三方合作：我們可能與第三方合作共同提供服務，您的個人信息可能會被共享給這些合作方。</li>
                  <li>法律要求：我們可能會根據法律要求或政府機構的要求公開您的個人信息。</li>
                </ul>
              </li>
              <li>
                <div>個人信息的保護</div>
                <p>我們採取適當的技術和組織措施來保護您的個人信息，以防止未經授權的訪問、使用、修改、洩露或破壞。我們只允許有權訪問您的個人信息的工作人員訪問您的個人信息，這些工作人員需要保護這些信息的機密性。</p>
              </li>
              <li>
                <div>第三方服務</div>
                <p>我們的app可能包含指向第三方網站或服務的鏈接。這些第三方網站和服務有自己的隱私權政策，我們不對其負責。我們建議您在使用這些網站和服務之前仔細閱讀其隱私權政策。</p>
              </li>
              <li>
                <div>隱私權政策的修改</div>
                <p>我們保留隨時修改本隱私權政策的權利。如果我們對隱私權政策進行任何更改，我們將在我們的app上張貼更新的版本。如果您繼續使用我們的app，即表示您接受這些更改。</p>
              </li>
              <li>
                <div>聯繫我們</div>
                <p>如果您對我們的隱私權政策有任何疑問或建議，請通過以下聯繫方式與我們聯繫：</p>
                <ul style="list-style-type: disc;">
                  <li>Email: <span style="font-weight: 900;">service@ywbliss.com</span></li>
                  <li>手機號碼: <span style="font-weight: 900;">0970-604323</span></li>
                </ul>
              </li>
            </ul>
          </p>
          <p class="mb-0 pb-4" style="font-weight: 900;">感謝您使用我們的app！</p>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
export default {
  name: "PrivacyAgreement",
  components: {},
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
  },
};
</script>
<style scoped lang="sass">
</style>
