<template>
  <div id="OperatorManagement">
    <v-card class="ma-4">
      <TablePage
        :targetName="'announceTable'"
        :selectData="selectItem"
        :thead="thead"
        :keyGroup="keyGroup"
        :data="data"
        @refresh="search"
        @search="search"
        @openDialog="openDialog"
        @clickUpdateBtn="openInformationDialog"
        @changeisEnable="changeisEnable"
      />
      <v-pagination
        v-if="totalPage > 0"
        v-model="page"
        :length="totalPage"
        :total-visible="7"
        circle
        @input="changePage"
      ></v-pagination>
      <v-dialog
        v-model="informationDialog"
        width="90%"
        max-width="1200"
        @click:outside="closeInformationDialog"
        @keydown.esc="closeInformationDialog"
      >
        <v-card>
          <v-card-title>
            {{ dialogIndex == 0 ? "修改公告" : "新增公告" }}
          </v-card-title>
          <div class="pa-3">
            <AnnounceDialog
              :selectedData="selectedData"
              :mode="mode"
              @closeInformationDialog="closeInformationDialog"
              @modifyConfirmBtn="modifyConfirmBtn"
              @createConfirmBtn="createConfirmBtn"
            />
          </div>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import lodash from "lodash";
import AnnounceDialog from "@/components/announceManagement/Dialog.vue";
import {
  getAnnouncementsList,
  createAnnouncements,
  updateAnnouncements,
  disableAnnouncements,
  enableAnnouncements,
} from "@/apis/Announcements";
import TablePage from "@/components/TablePage.vue";
export default {
  name: "AnnounceManagement",
  components: {
    AnnounceDialog,
    TablePage,
  },
  data() {
    return {
      params: {},
      page: 1,
      totalPage: 0,
      dialogIndex: 0,
      selectedData: {},
      selectItem: [{ text: "啟用", value: "isEnable" }],
      thead: ["序號", "標題", "內文", "建立時間", "創建人員", "操作"],
      keyGroup: ["id", "title", "content_revise", "createTime", "createUser"],
      informationDialog: false,
      data: [],
      mode: "",
    };
  },
  methods: {
    async search(enableBoolean, nowSelectItem, data) {
      this.params = {};
      if (data) {
        this.params[nowSelectItem] = data;
      }
      this.getAnnounce();
    },
    openDialog() {
      this.mode = "";
      this.dialogIndex = 1;
      this.informationDialog = true;
    },
    async changeisEnable(id, boolean) {
      if (boolean) {
        await enableAnnouncements(id);
      } else {
        await disableAnnouncements(id);
      }
      this.getAnnounce();
    },
    async modifyConfirmBtn() {
      this.$store.commit("setLoading", true);
      const res = await updateAnnouncements(this.selectedData.id, this.selectedData);
      if (res.status === 200) {
        this.getAnnounce();
        this.closeInformationDialog();
        this.selectedData = {};
        this.mode = "";
      }
      this.$store.commit("setLoading", false);
    },
    async createConfirmBtn() {
      this.$store.commit("setLoading", true);
      this.selectedData.isEnable = true;
      const res = await createAnnouncements(this.selectedData);
      if (res.status === 200) {
        this.getAnnounce();
        this.closeInformationDialog();
        this.selectedData = {};
      }
      this.$store.commit("setLoading", false);
    },
    openInformationDialog(index) {
      this.dialogIndex = 0;
      if (typeof index === "number") {
        this.selectedData = lodash.cloneDeep(this.data[index]);
        this.selectedData.password = "1";
      }
      this.mode = "modify";
      this.informationDialog = true;
    },
    closeInformationDialog() {
      this.selectedData = {};
      this.informationDialog = false;
    },
    async changePage() {
      this.params.page = this.page;
      this.getAnnounce();
    },
    async getAnnounce() {
      this.$store.commit("setLoading", true);
      const res = await getAnnouncementsList(this.params);
      this.$store.commit("setLoading", false);
      if (res.status === 200) {
        this.data = this.filterText(res.data.result.results);
        this.totalPage = res.data.result.pageCount;
        this.page = res.data.result.currentPage;
      }
    },
    filterText(data) {
      data.forEach((item, index) => {
        if (item.content.split("").length > 15) {
          data[index].content_revise =
            item.content.split("").splice(0, 15).toString().replaceAll(",", "") + "...";
        } else {
          data[index].content_revise = item.content;
        }
      });
      return data;
    },
  },
  created() {
    this.getAnnounce();
  },
};
</script>
