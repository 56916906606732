import http from '@/http/axios';
import { judgeError, queryFilter } from './Common';
import { error, success } from '@/utils/notification';

export async function getAnnouncementsList(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/Announcements/List${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  }
  return res;
}

export async function getAnnouncements(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/Announcements${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  }
  return res;
}

export async function createAnnouncements(data) {
  const res = await http.post('/api/Announcements', data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  } else {
    success();
  }
  return res;
}

export async function updateAnnouncements(id, data) {
  const res = await http.put(`/api/Announcements/${id}`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  } else {
    success();
  }
  return res;
}

export async function disableAnnouncements(id) {
  const res = await http.post(`/api/Announcements/${id}/Disable`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  } else {
    success();
  }
  return res;
}

export async function enableAnnouncements(id) {
  const res = await http.post(`/api/Announcements/${id}/Enable`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error('錯誤', '未知錯誤', '未知錯誤');
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }

  } else {
    success();
  }
  return res;
}
