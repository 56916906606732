const dateTimeFilter = (timestamp) => {
  if (timestamp === undefined || timestamp <= 0) {
    return "";
  }
  const getTime = Math.floor(timestamp).toString().split("");
  const year = getTime.splice(0, 4).toString();
  const month = getTime.splice(0, 2).toString();
  const day = getTime.splice(0, 2).toString();
  const hour = getTime.splice(0, 2).toString();
  const minute = getTime.splice(0, 2).toString();
  const second = getTime.splice(0, 2).toString();
  return `${year}/${month}/${day} ${hour}:${minute}:${second ? second : "00"}`.replaceAll(",", "");
};
const dateFilter = (date) => {
  if (!date) return;
  let revise = date.toString().split("");
  revise.splice(4, 0, "/");
  revise.splice(7, 0, "/");
  return revise.splice(0, 10).toString().replaceAll(",", "");
};

const dateForChineseFilter = (date) => {
  if (!date) return;
  let revise = date.toString().split("");
  revise.splice(4, 0, "年");
  revise.splice(7, 0, "月");
  revise.splice(10, 0, "日");
  return revise.splice(0, 11).toString().replaceAll(",", "");
};

const chineseDate = (date) => {
  if (!date) return;
  let revise = date.toString().split("");
  revise.splice(4, 0, "年");
  revise.splice(7, 0, "月");
  revise.splice(10, 0, "日");
  revise.splice(11, revise.length - 11);
  return revise.toString().replaceAll(",", "");
};

const contactType = (value) => {
  switch (value) {
    case 0:
      return "手機";
    case 1:
      return "市話";
    case 2:
      return "傳真";
    case 3:
      return "地址";
    default:
      return "";
  }
};

export default {
  dateTimeFilter,
  dateFilter,
  contactType,
  chineseDate,
  dateForChineseFilter,
};
