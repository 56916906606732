<template>
  <div id="TemplateManagement">
    <v-card class="ma-4">
      <div class="d-flex pa-3 w-100">
        <div class="d-flex w-100 tab_rwd">
          <v-tabs v-model="selectedTabIndex" show-arrows>
            <v-tab v-for="(tab, tabIndex) in tabList" :key="tabIndex" class="text_rwd">
              {{ tab.name }}
            </v-tab>
          </v-tabs>
        </div>
      </div>
    </v-card>
    <v-card class="ma-4">
      <TablePage
        :targetName="targetName"
        :selectedTabIndex="selectedTabIndex"
        :selectData="selectItem"
        :selectedButtonIndex="selectedButtonIndex"
        :buttonGroup="buttonGroup"
        :thead="thead"
        :keyGroup="keyGroup"
        :data="data"
        :haveDetail="haveDetail"
        @clickUpdateBtn="clickUpdateBtn"
        @changeisEnable="changeisEnable"
        @clickDetail="clickDetail"
        @changeButtonGroup="changeButtonGroup"
        @openDialog="openDialog"
        @search="search"
      />
      <v-pagination
        v-if="totalPage > 0"
        v-model="page"
        :length="totalPage"
        :total-visible="7"
        circle
        @input="changePage"
      ></v-pagination>
      <v-dialog
        v-model="dialog"
        width="90%"
        max-width="1200"
        @click:outside="closeDetailDialog"
        @keydown.esc="closeDetailDialog"
      >
        <component
          :is="this.createBtn.value"
          :action="action"
          :data="selectedData"
          :refresh="dialog"
          @clickCloseBtn="clickCloseBtn"
          @clickConfirmBtn="clickConfirmBtn"
        ></component>
      </v-dialog>
      <v-dialog
        v-model="contractSamplesDetails"
        width="90%"
        max-width="1200"
        @click:outside="clickCloseBtn"
        @keydown.esc="clickCloseBtn"
      >
        <v-card class="pa-2" v-if="selectedTabIndex === 1">
          <div class="d-flex justify-end">
            <v-switch v-model="isOpenDrag" label="排序" @change="switchDrag()"></v-switch>
            <v-btn
              color="primary"
              @click="openDetailDialog"
              class="create_industryBtn ma-2"
              :disabled="isOpenDrag"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>
              編輯內容
            </v-btn>
          </div>
          <v-row
            no-gutters
            style="font-size: 18px; border-bottom: 1px solid #d2d2d2; color: #777"
            class="px-2 pb-2"
          >
            <v-col>範本項目名稱</v-col>
            <v-col>單位</v-col>
            <v-col>數量</v-col>
          </v-row>
          <draggable v-model="detailData" @change="changeSort" :sort="isOpenDrag">
            <transition-group>
              <v-row
                no-gutters
                class="py-2 px-2"
                style="font-size: 14px; border-bottom: 1px solid #d2d2d2"
                :style="isOpenDrag ? 'cursor: grab;' : ''"
                v-for="(item, index) in detailData"
                :key="index + 1"
              >
                <v-col>{{ item.contractItemName }}</v-col>
                <v-col>{{ item.unit }}</v-col>
                <v-col>{{ item.quantity }}</v-col>
              </v-row>
            </transition-group>
          </draggable>
          <v-row no-gutters class="pa-2 text-center" v-if="detailData.length === 0">
            <v-col style="font-size: 16px">尚無資料</v-col>
          </v-row>
          <div class="d-flex justify-end pt-3" v-if="isOpenDrag">
            <v-btn color="error darken-1" text @click="cancelDrag()"> 取消 </v-btn>
            <v-btn color="green darken-1" text @click="createDetail(detailData)"> 確定 </v-btn>
          </div>
          <div class="d-flex justify-end pt-3" v-else>
            <v-btn color="error darken-1" text @click="closeDetailDialog()"> 取消 </v-btn>
          </div>
        </v-card>
        <v-card class="pa-2" v-else>
          <div class="d-flex justify-end">
            <v-switch v-model="isOpenDrag" label="排序" @change="switchDrag()"></v-switch>
            <v-btn
              color="primary"
              @click="openDetailDialog"
              class="create_industryBtn ma-2"
              :disabled="isOpenDrag"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>
              編輯內容
            </v-btn>
          </div>
          <v-row
            no-gutters
            style="font-size: 18px; border-bottom: 1px solid #d2d2d2; color: #777"
            class="px-2 pb-2"
          >
            <v-col>流程項目名稱</v-col>
            <v-col>參與者</v-col>
            <v-col>準備事項</v-col>
            <v-col>備註</v-col>
          </v-row>
          <draggable v-model="detailData" @change="changeSort" :sort="isOpenDrag">
            <transition-group>
              <v-row
                no-gutters
                class="py-2 px-2"
                style="font-size: 14px; border-bottom: 1px solid #d2d2d2"
                :style="isOpenDrag ? 'cursor: grab;' : ''"
                v-for="(item, index) in detailData"
                :key="index + 1"
              >
                <v-col>{{ item.flowItemName }}</v-col>
                <v-col>{{ item.participants }}</v-col>
                <v-col>{{ item.prepared }}</v-col>
                <v-col>{{ item.memo }}</v-col>
              </v-row>
            </transition-group>
          </draggable>
          <v-row no-gutters class="pa-2 text-center" v-if="detailData.length === 0">
            <v-col style="font-size: 16px">尚無資料</v-col>
          </v-row>
          <div class="d-flex justify-end pt-3" v-if="isOpenDrag">
            <v-btn color="error darken-1" text @click="cancelDrag()"> 取消 </v-btn>
            <v-btn color="green darken-1" text @click="createDetail(detailData)"> 確定 </v-btn>
          </div>
          <div class="d-flex justify-end pt-3" v-else>
            <v-btn color="error darken-1" text @click="closeDetailDialog()"> 取消 </v-btn>
          </div>
        </v-card>
        <v-dialog
          v-model="dragHintDialog"
          width="40%"
          max-width="300"
          scrollable
          @click:outside="cancelDrag"
          @keydown.esc="cancelDrag"
        >
          <v-card class="rounded pa-2">
            <span style="font-size: 16px" class="pa-4">需要保存變更後的排序嗎?</span>
            <div class="d-flex justify-end pt-2">
              <v-btn class="mr-2" color="red" text @click="cancelDrag">取消</v-btn>
              <v-btn class="" color="primary" text @click="createDetail(detailData)">保存</v-btn>
            </div>
          </v-card>
        </v-dialog>
      </v-dialog>
      <v-dialog
        v-model="isCreateContractDetail"
        width="90%"
        max-width="1200"
        @click:outside="clickCloseDetailBtn"
        @keydown.esc="clickCloseDetailBtn"
        style="height: 100%"
      >
        <CreateContractDetail
          v-if="selectedTabIndex === 1"
          :action="action"
          :data="selectedData"
          :refresh="isCreateContractDetail"
          :selectedSampleDetailId="selectedSampleDetailId"
          :detailData="detailData"
          @clickCloseDetailBtn="clickCloseDetailBtn"
          @createDetail="createDetail"
        ></CreateContractDetail>
        <CreateFlowDetail
          v-else
          :detailData="detailData"
          :data="selectedData"
          :refresh="isCreateContractDetail"
          :selectedSampleDetailId="selectedSampleDetailId"
          @clickCloseDetailBtn="clickCloseDetailBtn"
          @createDetail="createDetail"
        ></CreateFlowDetail>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import lodash from "lodash";
import {
  getPersonnelList,
  createPersonnel,
  updatePersonnel,
  enablePersonnel,
  disablePersonnel,
} from "@/apis/Personnel";
import {
  getContractItemsList,
  createContractItems,
  updateContractItems,
  disableContractItems,
  enableContractItems,
} from "@/apis/ContractItems";
import {
  getContractSamplesList,
  createContractSamples,
  updateContractSamples,
  disableContractSamples,
  enableContractSamples,
  getContractSamplesDetails,
  createContractSamplesDetails,
} from "@/apis/ContractSamples";
import {
  getFlowSampleList,
  createFlowSample,
  updateFlowSample,
  disableFlowSample,
  enableFlowSample,
  flowSampleDetails,
  postFlowSampleDetails,
} from "@/apis/FlowSample";
import {
  getFlowItemsList,
  createFlowItems,
  updateFlowItems,
  disableFlowItems,
  enableFlowItems,
} from "@/apis/FlowItems";
import {
  getFlowMaterialsList,
  createFlowMaterials,
  updateFlowMaterials,
  disableFlowMaterials,
  enableFlowMaterials,
} from "@/apis/FlowMaterials";
import TablePage from "@/components/TablePage.vue";
import Personnel from "@/components/templateManagement/Personnel.vue";
import ContractSample from "@/components/templateManagement/ContractSample.vue";
import ContractItem from "@/components/templateManagement/ContractItem.vue";
import FlowSample from "@/components/templateManagement/FlowSample.vue";
import FlowItems from "@/components/templateManagement/FlowItems.vue";
import FlowMaterials from "@/components/templateManagement/FlowMaterials.vue";
import CreateContractDetail from "@/components/templateManagement/CreateContractDetail.vue";
import CreateFlowDetail from "@/components/templateManagement/CreateFlowDetail.vue";

export default {
  name: "TemplateManagement",
  components: {
    TablePage,
    Personnel,
    ContractSample,
    ContractItem,
    FlowSample,
    FlowItems,
    FlowMaterials,
    CreateContractDetail,
    CreateFlowDetail,
    draggable,
  },
  data() {
    return {
      targetName: "",
      haveChange: false,
      dragHintDialog: false,
      isEnabledFunctionArray: [
        [[enablePersonnel, disablePersonnel]],
        [
          [enableContractSamples, disableContractSamples],
          [enableContractItems, disableContractItems],
        ],
        [
          [enableFlowSample, disableFlowSample],
          [enableFlowItems, disableFlowItems],
          [enableFlowMaterials, disableFlowMaterials],
        ],
      ],
      getDetailListArray: [new Function(), getContractSamplesDetails, flowSampleDetails],
      getListFunctionArray: [
        [getPersonnelList],
        [getContractSamplesList, getContractItemsList],
        [getFlowSampleList, getFlowItemsList, getFlowMaterialsList],
      ],
      createDetailFunctionArray: [
        new Function(),
        createContractSamplesDetails,
        postFlowSampleDetails,
      ],
      createUpdateFunctionArray: [
        [[createPersonnel, updatePersonnel]],
        [
          [createContractSamples, updateContractSamples],
          [createContractItems, updateContractItems],
        ],
        [
          [createFlowSample, updateFlowSample],
          [createFlowItems, updateFlowItems],
          [createFlowMaterials, updateFlowMaterials],
        ],
      ],
      params: {},
      isOpenDrag: false,
      detailComponentName: "",
      isCreateContractDetail: false,
      haveDetail: false,
      contractSamplesDetails: false,
      action: "",
      page: 1,
      totalPage: 0,
      dialog: false,
      selectedTabIndex: 0,
      selectItem: [],
      tabList: [
        {
          name: "角色",
          value: "Personnel",
        },
        {
          name: "合約",
          value: "ContractItem",
        },
        {
          name: "流程",
          value: "Flow",
        },
      ],
      selectedData: {},
      selectedButtonIndex: 0,
      buttonGroup: [],
      thead: [],
      data: [],
      keyGroup: [],
      createBtn: {
        name: "",
        value: "",
      },
      detailData: [],
      selectedSampleDetailId: 0,
    };
  },
  methods: {
    switchDrag() {
      if (this.haveChange) {
        this.dragHintDialog = true;
        this.haveChange = false;
      }
    },
    cancelDrag() {
      this.isOpenDrag = false;
      this.dragHintDialog = false;
      this.haveChange = false;
      this.clickDetail({ id: this.selectedSampleDetailId });
    },
    changeSort() {
      this.haveChange = true;
      this.detailData.forEach((item, index) => {
        this.detailData[index].sort = index + 1;
      });
    },
    closeDetailDialog() {
      this.contractSamplesDetails = false;
      this.detailData = [];
    },
    async search(enableBoolean, nowSelectItem, data) {
      this.params = {};
      if (data) {
        this.params[nowSelectItem] = data;
      }
      this.getListFunction(
        this.getListFunctionArray[this.selectedTabIndex][this.selectedButtonIndex]
      );
    },
    async changePage() {
      this.params.page = this.page;
      this.getListFunction(
        this.getListFunctionArray[this.selectedTabIndex][this.selectedButtonIndex]
      );
    },
    async createDetail(data) {
      const res = await this.createDetailFunctionArray[this.selectedTabIndex](
        this.selectedSampleDetailId,
        data
      );
      if (res.status === 200) {
        this.isCreateContractDetail = false;
        this.contractSamplesDetails = false;
        this.clickDetail({ id: this.selectedSampleDetailId });
        this.isOpenDrag = false;
      }
    },
    async clickDetail(data) {
      this.$store.commit("setLoading", true);
      const res = await this.getDetailListArray[this.selectedTabIndex](data.id);
      if (res.status === 200) {
        this.selectedSampleDetailId = data.id;
        this.contractSamplesDetails = true;
        this.detailData = res.data.result;
      }
      this.$store.commit("setLoading", false);
    },
    async changeisEnable(id, boolean) {
      await this.isEnabledFunctionArray[this.selectedTabIndex][this.selectedButtonIndex][
        Number(!boolean)
      ](id);
      this.getListFunction(
        this.getListFunctionArray[this.selectedTabIndex][this.selectedButtonIndex]
      );
    },
    clickUpdateBtn(index) {
      this.openDialog("update", index);
    },
    async clickConfirmBtn(data) {
      if (this.action === "create") {
        data.isEnable = true;
      }
      const action = this.action === "create" ? 0 : 1;
      const res = await this.createUpdateFunctionArray[this.selectedTabIndex][
        this.selectedButtonIndex
      ][action](data);
      if (res.status === 200) {
        this.clickCloseBtn();
        this.getListFunction(
          this.getListFunctionArray[this.selectedTabIndex][this.selectedButtonIndex]
        );
      }
    },
    clickCloseDetailBtn() {
      this.isCreateContractDetail = false;
    },
    clickCloseBtn() {
      this.dialog = false;
      this.action = "";
      this.selectedData = {};
    },
    openDialog(action, index) {
      this.selectedData = {};
      this.action = action;
      this.dialog = true;
      if (typeof index === "number") {
        this.selectedData = lodash.cloneDeep(this.data[index]);
      }
    },
    openDetailDialog() {
      this.isCreateContractDetail = true;
    },
    haveButtonGroup(reserve) {
      const buttonGroupArray = [
        [],
        [
          {
            name: "範本",
            value: "ContractSample",
          },
          {
            name: "項目",
            value: "ContractItem",
          },
        ],
        [
          {
            name: "範本",
            value: "FlowSample",
          },
          {
            name: "項目",
            value: "FlowItems",
          },
          {
            name: "物品",
            value: "FlowMaterials",
          },
        ],
      ];
      if (!reserve) {
        this.selectedButtonIndex = 0;
      }
      this.buttonGroup = buttonGroupArray[this.selectedTabIndex];
      if (this.buttonGroup.length > 0) {
        this.changeButtonGroup(this.selectedButtonIndex);
      }
    },
    async changeButtonGroup(index) {
      this.params = {};
      this.haveDetail = false;
      this.selectedButtonIndex = index;
      this.createBtn = this.buttonGroup[index];
      if (this.selectedTabIndex === 1) {
        switch (this.selectedButtonIndex) {
          case 0: {
            this.targetName = "contractSampleTable";
            this.haveDetail = true;
            this.selectItem = [
              { text: "ID", value: "id" },
              { text: "名稱", value: "name" },
              { text: "啟用", value: "isEnable" },
            ];
            this.thead = [
              "序號",
              "名稱",
              "備註",
              "建立時間",
              "創建人員",
              "更新時間",
              "更新人員",
              "操作",
            ];
            this.keyGroup = [
              "id",
              "name",
              "memo",
              "createTime",
              "createUser",
              "updateTime",
              "updateUser",
            ];
            this.getListFunction(getContractSamplesList);
            break;
          }
          case 1: {
            this.targetName = "contractItemTable";
            this.selectItem = [
              { text: "名稱", value: "name" },
              { text: "啟用", value: "isEnable" },
            ];
            this.thead = [
              "序號",
              "名稱",
              "分類",
              "單位",
              "備註",
              "建立時間",
              "創建人員",
              "更新時間",
              "更新人員",
              "操作",
            ];
            this.keyGroup = [
              "id",
              "name",
              "category",
              "unit",
              "memo",
              "createTime",
              "createUser",
              "updateTime",
              "updateUser",
            ];
            this.getListFunction(getContractItemsList);
            break;
          }
          default:
            this.thead = [];
            this.keyGroup = [];
            this.data = [];
            break;
        }
      } else if (this.selectedTabIndex === 2) {
        switch (this.selectedButtonIndex) {
          case 0: {
            this.targetName = "flowSampleTable";
            this.haveDetail = true;
            this.selectItem = [
              { text: "ID", value: "id" },
              { text: "名稱", value: "name" },
              { text: "啟用", value: "isEnable" },
            ];
            this.thead = [
              "序號",
              "名稱",
              "備註",
              "建立時間",
              "創建人員",
              "更新時間",
              "更新人員",
              "操作",
            ];
            this.keyGroup = [
              "id",
              "name",
              "memo",
              "createTime",
              "createUser",
              "updateTime",
              "updateUser",
            ];
            this.getListFunction(getFlowSampleList);
            break;
          }
          case 1: {
            this.targetName = "flowItemTable";
            this.selectItem = [
              { text: "ID", value: "id" },
              { text: "名稱", value: "name" },
              { text: "啟用", value: "isEnable" },
            ];
            this.thead = [
              "序號",
              "名稱",
              "備註",
              "建立時間",
              "創建人員",
              "更新時間",
              "更新人員",
              "操作",
            ];
            this.keyGroup = [
              "id",
              "name",
              "isEnable",
              "memo",
              "createTime",
              "createUser",
              "updateTime",
              "updateUser",
            ];
            this.getListFunction(getFlowItemsList);
            break;
          }
          case 2: {
            this.targetName = "flowMaterialTable";
            this.selectItem = [
              { text: "ID", value: "id" },
              { text: "名稱", value: "name" },
              { text: "分類", value: "category" },
              { text: "啟用", value: "isEnable" },
            ];
            this.thead = [
              "序號",
              "名稱",
              "分類",
              "備註",
              "建立時間",
              "創建人員",
              "更新時間",
              "更新人員",
              "操作",
            ];
            this.keyGroup = [
              "id",
              "name",
              "category",
              "memo",
              "createTime",
              "createUser",
              "updateTime",
              "updateUser",
            ];
            this.getListFunction(getFlowMaterialsList);
            break;
          }
          default:
            this.thead = [];
            this.keyGroup = [];
            this.data = [];
            break;
        }
      }
    },
    async getListFunction(doCallFunction) {
      this.$store.commit("setLoading", true);
      const res = await doCallFunction(this.params);
      if (res.status === 200) {
        this.data = res.data.result.results;
        this.totalPage = res.data.result.pageCount;
        this.page = res.data.result.currentPage;
      }
      this.$store.commit("setLoading", false);
    },
    async changeTab() {
      this.params = {};
      this.createBtn = this.tabList[this.selectedTabIndex];
      if (this.selectedTabIndex === 0) {
        this.targetName = "charaterTable";
        this.haveDetail = false;
        this.selectItem = [
          { text: "名稱", value: "name" },
          { text: "啟用", value: "isEnable" },
        ];
        this.thead = [
          "序號",
          "名稱",
          "備註",
          "建立時間",
          "創建人員",
          "更新時間",
          "更新人員",
          "操作",
        ];
        this.keyGroup = [
          "id",
          "name",
          "memo",
          "createTime",
          "createUser",
          "updateTime",
          "updateUser",
        ];
        this.getListFunction(getPersonnelList);
      }
      this.haveButtonGroup(false);
    },
  },
  created() {
    this.changeTab();
  },
  watch: {
    selectedTabIndex() {
      this.changeTab();
    },
  },
};
</script>
