<template>
  <div id="FlowSamplePDF">
    <v-card class="text-center">
      <v-card-text>
        <div ref="targetPDF" class="targetPDF">
          <div style="font-size: 36px; font-weight: 900" class="py-2">
            故 {{ selectedData.name }}{{ selectedData.title }}告 別 式 流 程
          </div>
          <div class="py-2"></div>
          <div>
            <div class="tableDiv">
              <div class="d-flex" style="border: 1px solid #323333">
                <div class="th" style="width: 15%">主程</div>
                <div class="th" style="width: 10%">日期</div>
                <div class="th" style="width: 10%">時間</div>
                <div class="th" style="width: 5%">星期</div>
                <div class="th" style="width: 20%">參加人員</div>
                <div class="th" style="width: 20%">應備事物</div>
                <div class="th" style="width: 20%">備注</div>
              </div>
              <div>
                <div
                  class="d-flex"
                  v-for="(item, index) in datas"
                  :key="index"
                  style="
                    border-left: 1px solid #323333;
                    border-right: 1px solid #323333;
                    border-bottom: 1px solid #323333;
                  "
                >
                  <div style="width: 15%" class="td">
                    <div>{{ item.dealerFlowItemName }}</div>
                  </div>
                  <div style="width: 10%" class="td">
                    <div>{{ item.date }}</div>
                  </div>
                  <div style="width: 10%" class="td">
                    <div>{{ item.time }}</div>
                  </div>
                  <div style="width: 5%" class="td">
                    <div>{{ item.day || "" }}</div>
                  </div>
                  <div style="width: 20%" class="td">
                    <div>{{ item.participants || "" }}</div>
                  </div>
                  <div style="width: 20%" class="td">
                    <div>{{ item.prepared || "" }}</div>
                  </div>
                  <div style="width: 20%" class="td">
                    <div>{{ item.memo || "" }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <div class="my-2" style="font-size: 32px">{{ dealerData.name }}</div>
            <div style="font-size: 18px">
              <!-- contactType -->
              聯絡資訊：
              <span
                style="font-weight: 900"
                v-for="(item, index) in dealerData.contactInfos"
                :key="index"
              >
                {{ item.type | contactType }}: {{ item.content }}
              </span>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions style="position: sticky; bottom: 0; background-color: #fff">
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="clickCloseBtn()"> 關閉 </v-btn>
        <v-btn color="green darken-1" text @click="imgDownload()"> 下載 </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import JsPDF from "jspdf";
import { getMeDealer } from "@/apis/Dealers";
export default {
  name: "FlowSamplePDF",
  props: ["data", "isOpen", "selectedData"],
  data: () => ({
    datas: [],
    chinaDate: "",
    dealerData: {},
    contactInfo: "",
  }),
  methods: {
    clickCloseBtn() {
      this.$emit("closeFlowSamplePdf");
    },
    imgDownload() {
      // 获取需转图片的范围元素
      const img = this.$refs.targetPDF;
      // 图片高度
      // const w = parseInt(window.getComputedStyle(img).width, 10);
      // 图片宽度
      // const h = parseInt(window.getComputedStyle(img).height, 10);
      const w = 595;
      const h = 842;
      // 滚轮置顶，避免留白
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      // 利用 html2canvas 下载 canvas
      html2canvas(img).then((canvas) => {
        // 转化为本地的图片地址
        this.imgURL = canvas.toDataURL();
        const filename = `${this.selectedData.name}.pdf`;
        // 一页pdf显示html页面生成的canvas高度;
        const pageHeight = (w / 595) * 842;
        // 未生成pdf的html页面高度
        let leftHeight = h;
        // 页面偏移
        let position = 0;
        // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        const proportion = (img.offsetWidth / img.offsetHeight).toFixed(2);
        const imgWidth = 550;
        const imgHeight =
          Math.floor(imgWidth / proportion) >= 800 ? 800 : Math.floor(imgWidth / proportion);
        // new jsPDF("l", "pt", "a4"); l 表示横向导出；true表示压缩pdf，否则文件会很大
        const pdf = new JsPDF("p", "pt", "a4");
        // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
        // 当内容未超过pdf一页显示的范围，无需分页
        // console.log('leftHeight: ' + leftHeight, 'pageHeight: ' + pageHeight);
        if (leftHeight <= pageHeight) {
          pdf.addImage(this.imgURL, "JPEG", 22.5, 21, imgWidth, imgHeight);
        } else {
          // 分页
          while (leftHeight > 0) {
            pdf.addImage(this.imgURL, "JPEG", 22.5, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 800;
            // 避免添加空白页
            if (leftHeight > 0) {
              pdf.addPage();
            }
          }
        }
        // 转pdf下载
        pdf.save(filename);
      });
    },
    dayFilter(day) {
      const dayArray = ["日", "一", "二", "三", "四", "五", "六"];
      if (day < 0 && day > dayArray.legnth) return;
      return dayArray[day];
    },
    reviseDataStruct() {
      this.datas = this.data;
      this.datas.forEach((item, index) => {
        // add date, time and day
        if (item.dateTime !== "" && item.dateTime !== 0) {
          const split = item.dateTime.toString().split("");
          const year = split.splice(0, 4).toString().replaceAll(",", "");
          const month = split.splice(0, 2).toString().replaceAll(",", "");
          const date = split.splice(0, 2).toString().replaceAll(",", "");

          const hour = split.splice(0, 2).toString().replaceAll(",", "");
          const minute = split.splice(0, 2).toString().replaceAll(",", "");
          this.datas[index].date = `${month}月${date}日`;
          this.datas[index].time = `${hour}:${minute}`;
          this.datas[index].day = this.dayFilter(new Date(year, Number(month) - 1, date).getDay());
        } else {
        }
      });
      this.$forceUpdate();
    },
    async getMeInformation() {
      const res = await getMeDealer();
      if (res.status === 200) {
        this.dealerData = res.data.result;
        this.dealerData.contactInfos.forEach((item) => {
          if (item.type === 0) {
            this.contactInfo = item.content;
          }
        });
      }
    },
  },
  created() {
    this.reviseDataStruct();
    this.getMeInformation();
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.reviseDataStruct();
        this.getMeInformation();
      }
    },
  },
};
</script>
<style scoped>
.targetPDF {
  color: black;
}
.th {
  padding: 5px;
  font-weight: 900;
  font-size: 22px;
  border-right: 1px solid #323333;
}
.td {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #323333;
  padding: 10px;
  font-weight: 600;
  vertical-align: middle;
}
.th:last-child,
.td:last-child {
  border: none;
}
</style>
